import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { companyReportData, languageInitializer } from 'src/environments/environment';

@Component({
  selector: 'app-employee-work-out-off-zone-report',
  templateUrl: './employee-work-out-off-zone-report.component.html',
  styleUrls: ['./employee-work-out-off-zone-report.component.scss'],
})
export class EmployeeWorkOutOffZoneReportComponent implements OnInit {
  Sample = companyReportData.reportData;

  directory = languageInitializer.directory
  constructor(
    private commonService: CommonService
  ) { }

  splitNameArabic(name) {
    return this.commonService.splitNameArabic(name, this.directory)
  }

  ngOnInit() {
    this.commonService.updateLanguageDir
      .subscribe(res => {
        this.directory = res == "" ? this.directory : res
      })
  }
  // [{ empName: "AAffaezfdA", empId: "Emp001", zoneName: "File", outOfZoneDuration: "546546", workInIt: "work112", supervisor: "comatecfleet" },
  // { empName: "BsdfgdsfgBB", empId: "Emp001", zoneName: "File", outOfZoneDuration: "54654645", workInIt: "work112", supervisor: "demo" },
  // { empName: "CsdfgsadfCC", empId: "Emp001", zoneName: "File", outOfZoneDuration: "8978683534", workInIt: "work112", supervisor: "tabuk" },
  // { empName: "DDsdfsfsdaD", empId: "Emp001", zoneName: "File", outOfZoneDuration: "23457764", workInIt: "work112", supervisor: "WFT" },
  // { empName: "FFsdfewrF", empId: "Emp001", zoneName: "File", outOfZoneDuration: "675345656", workInIt: "work112", supervisor: "demo-ca" },
  // { empName: "GGkjliuhvgG", empId: "Emp001", zoneName: "File", outOfZoneDuration: "22434576", workInIt: "work112", supervisor: "comatecfleet-ca" },
  // { empName: "HcxvvcbHHH", empId: "Emp001", zoneName: "File", outOfZoneDuration: "254678765", workInIt: "work112", supervisor: "rac" }
  // ]


}
