import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { companyReportData } from 'src/environments/environment';

@Component({
  selector: 'app-recurrence',
  templateUrl: './recurrence.component.html',
  styleUrls: ['./recurrence.component.scss'],
})
export class RecurrenceComponent implements OnInit {
  @Input() recurrencedata
  constructor(
    private commonServices: CommonService
  ) {
  }
  ngOnChanges(data){
    this.commonServices.dismissLoader()
    this.recurrencedata =companyReportData.recurrenceData
  }

  ngOnInit() {
   
  }
}
