import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { languageInitializer } from 'src/environments/environment';

@Component({
  selector: 'app-escalation',
  templateUrl: './escalation.component.html',
  styleUrls: ['./escalation.component.scss'],
})
export class EscalationComponent implements OnInit {

  directory=languageInitializer.directory;
  severity:any;
  isChecked="None"
  level1form:FormGroup;
  level2form:FormGroup;
  level3form:FormGroup;

  constructor(private formBuilder: FormBuilder,) { }

  ngOnInit() {
        this.severity=['None','Minor','Major','Critical'] 
        this.formInitialize();            
  }
  onShowSelected(event){
    console.log(event.detail.value)
  }
  formInitialize(){
    this.level1form=this.formBuilder.group({
      duration1:[''],
      email1:[''],
      contact1:['',Validators.maxLength(15)]
    })
    this.level2form=this.formBuilder.group({
      duration2:[''],
      email2:[''],
      contact2:['',Validators.maxLength(15)]
    })
    this.level3form=this.formBuilder.group({
      duration3:[''],
      email3:[''],
      contact3:['',Validators.maxLength(15)]
    })
  }
  submitForm(){
    console.log(
      this.level1form.controls['duration1'].value,this.level1form.controls['contact1'].value,this.level1form.controls['email1'].value
    )
    console.log(
      this.level2form.controls['duration2'].value,this.level2form.controls['contact2'].value,this.level2form.controls['email2'].value
    )
    console.log(
      this.level3form.controls['duration3'].value,this.level3form.controls['contact3'].value,this.level3form.controls['email3'].value
    )
  }
  clearForm(){
    this.level1form.reset();
    this.level2form.reset();
    this.level3form.reset();
  }
}
