import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-trip-summary',
  templateUrl: './new-trip-summary.component.html',
  styleUrls: ['./new-trip-summary.component.scss'],
})
export class NewTripSummaryComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
