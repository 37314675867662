import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { AjaxService } from 'src/app/services/ajax.service';
import { CommonService } from 'src/app/services/common.service';
import { serverUrl } from 'src/environments/environment';

@Component({
  selector: 'app-shift-details-model',
  templateUrl: './shift-details-model.component.html',
  styleUrls: ['./shift-details-model.component.scss'],
})
export class ShiftDetailsModelComponent implements OnInit {
  shiftDetails: FormGroup;
  @Input() mode;
  @Input() selectedRow;
  shiftTypes = ["Morning", "Evening", "Night"];

  constructor(
    private formBuilder: FormBuilder,
    private ajaxService: AjaxService,
    private commonService: CommonService,
    private modalController: ModalController
  ) { }

  AddShiftDetails() {
    let shiftData = this.shiftDetails.value;
    var data = {
      "shiftStartTime": shiftData.startTime,
      "shiftEndTime": shiftData.endTime,
      "shiftName": shiftData.shiftName,
      "shiftType": shiftData.shiftType.replaceAll(" ", ""),
      "companyID": localStorage.corpId,
      "branchID": localStorage.corpId,
      "userId": localStorage.userName,
    }
    if (this.mode == 'edit')
      data["shiftId"] = this.selectedRow.shiftId

    const url = serverUrl.web + '/employe/addShiftDetails';
    this.ajaxService.ajaxPostWithString(url, data)
      .subscribe(res => {
        this.modalController.dismiss()
        this.commonService.presentToast("Updated successfully")
      }, err => {
        this.modalController.dismiss()
        this.commonService.presentToast("failed")
      });


  }

  ngOnInit() {

    this.shiftDetails = this.formBuilder.group({
      shiftName: ['', Validators.required],
      shiftType: new FormControl(),
      startTime: ['00:00'],
      endTime: ['23:59']
    });

    if (this.mode == "edit") {
      this.shiftDetails.patchValue({
        shiftName: this.selectedRow['shiftName'],
        shiftType: this.selectedRow['shiftType'],
        startTime: this.selectedRow['shiftStartTime'],
        endTime: this.selectedRow['shiftEndTime']
      })


    }
  }
}
