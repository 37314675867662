import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AjaxService } from 'src/app/services/ajax.service';
import { AuthMapService } from 'src/app/services/auth-map.service';
import { CommonService } from 'src/app/services/common.service';
import { companyReportData } from 'src/environments/environment';

@Component({
  selector: 'app-no-transmission-report',
  templateUrl: './no-transmission-report.component.html',
  styleUrls: ['./no-transmission-report.component.scss'],
})
export class NoTransmissionReportComponent implements OnInit {
  map: any;
  @Input() commonData: any;
  @Input() report: any;
  @Input() displayCount: number = 30;
  displayData: Array<any>;
  currentPage: number = 1;
  sourceLocation: { "lat": number; "lng": number; };
  destinationLocation: { "lat": number; "lng": number; };
  mapView: boolean = true;
  reportData = [];
  reportName: any;
  trackLine: any;
  count: number = 10;
  constructor(
    private commonService: CommonService
  ) { }

  setDisplayData() {
    if (this.commonData.length > this.displayCount) {
      this.displayData = this.commonData.slice(0, this.displayCount);
    }
    else {
      this.displayData = this.commonData;
    }
  }

  doInfinite(event) {
    setTimeout(() => {
       this.displayData.push(...this.commonData.slice(this.currentPage * this.displayCount, (this.currentPage + 1) * this.displayCount));
      this.currentPage++;
      event.target.complete();
      if (this.displayData.length == this.commonData.length) {
        event.target.disabled = true;
      }
    }, 500);
  }
  ionViewWillEnter() {
  }

  ngOnInit() { 
     this.commonService.dismissLoader()
    this.commonData= companyReportData.reportData
    this.reportData = this.commonData;
    this.reportName = this.report;
    this.setDisplayData();
  }
  ngOnChanges() {

    this.setDisplayData();
  }
  getArabicLetters(letter) {

    return this.commonService.numberToArabic(letter)
  }
}
