import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { reduce } from 'highcharts';
import { CommonService } from 'src/app/services/common.service';
import { languageInitializer } from 'src/environments/environment';

@Component({
  selector: 'app-alert-card',
  templateUrl: './alert-card.component.html',
  styleUrls: ['./alert-card.component.scss'],
})
export class AlertCardComponent implements OnInit,OnChanges {
  @Input() data;
  @Input() vtsgrid;
  @Input() clickedIcon;
  directory = languageInitializer.directory;
  entryPoint;
  icon$
  constructor(
    private commonService: CommonService
  ) { }

  ngOnInit() {
    this.entryPoint = localStorage.getItem("entryPoint");
    this.commonService.updateLanguageDir
      .subscribe(res => {
        this.directory = res == "" ? this.directory : res
      })
  }
  ngOnChanges(){
    
    this.icon$ = this.commonService.vehicleModel[this.data.icon]
   

  }

  changeToArabic(letter) {
    return this.commonService.numberToArabic(letter.toString());
  }

  splitNameArabic(name) {
    return this.commonService.splitNameArabic(name, this.directory)
  }
  colorFn(data) {
    let color;
    let t = data.additionalInfo.split(" #")[0].split(':')[0] == "Config-Min" ? color = "#93ef93" : data.additionalInfo.split("#")[0].split(':')[0] == "Range" ? color = "white" : color = "#ffb6c1"
    return color;
  }



  getBgColor(type) {
    switch (type.alertTypes) {
      case "IDLE":
        return "idle";

      case "OVERSPEED":
        return "overspeed";

      case "ENGINESTATUS":
        if (type.additionalInfo == 'ENGINESTATUS ON') {
          return "engineon";
        } else {
          return "engineoff";
        }

      case "STOP":
        return "stop";
      case "MOVEMENT":
        return "engineon";

      case "LowBattery":
        return "lowbattery";
      case "BatteryDrain":
        return "batterydrain";
      case "POWERCUT":
        return "powercut";
      case "PowerCut":
        return "powercut";
      case "POWERON":
        return "poweron";
      case "PowerOn":
        return "poweron";

      case "WOKEUP":
        return "wokeup";
      case "TEMPERATURE1":
        return "Bttemprature";
      case "TEMPERATURE2":
        return "Bttemprature";
      case "TEMPERATURE3":
        return "Bttemprature";
      case "TEMPERATURE4":
        return "Bttemprature";
      case "BTTEMPERATURE1":
        return "temprature";
      case "BTTEMPERATURE2":
        return "temprature";
      case "BTTEMPERATURE3":
        return "temprature";
      case "BTTEMPERATURE4":
        return "temprature";


      default:
        return "idle";
    }
  }

}