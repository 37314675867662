import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.page.html',
  styleUrls: ['./modal.page.scss'],
})
export class ModalPage implements OnInit {

  @Input() showPopup: boolean = false;

  constructor(private modalController: ModalController, private router: Router) { }

  public data = { 'userId': localStorage.userName, 'companyId': localStorage.corpId }


  ngOnInit() {
  }

  profile() {
    this.modalController.dismiss();
    this.router.navigate(['/profile']);
  }
}
