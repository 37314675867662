import { Component, Input, OnInit } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AjaxService } from 'src/app/services/ajax.service';
import { CommonService } from 'src/app/services/common.service';
import { serverUrl } from 'src/environments/environment';

@Component({
  selector: 'app-bluetooth-sensor',
  templateUrl: './bluetooth-sensor.component.html',
  styleUrls: ['./bluetooth-sensor.component.scss'],
})
export class BluetoothSensorComponent implements OnInit {

  @Input() vin;
  @Input() paraVin;
  bluetooth = {
    blue1: { sensor: "BTTEMPERATURESENSOR1", name: "BT1", min: "-20", max: "40", editEnable: false, visible: true, io: 25, imei: 25, latLng: "0,0", unit: "C", imeiVisible: true, status: 1 },
    blue2: { sensor: "BTTEMPERATURESENSOR2", name: "BT2", min: "-20", max: "40", editEnable: false, visible: false, io: 26, imei: 26, latLng: "0,0", unit: "C", imeiVisible: true, status: 1 },
    blue3: { sensor: "BTTEMPERATURESENSOR3", name: "BT3", min: "-20", max: "40", editEnable: false, visible: false, io: 27, imei: 27, latLng: "0,0", unit: "C", imeiVisible: true, status: 1 },
    blue4: { sensor: "BTTEMPERATURESENSOR4", name: "BT4", min: "-20", max: "40", editEnable: false, visible: false, io: 28, imei: 28, latLng: "0,0", unit: "C", imeiVisible: true, status: 1 },
  }

  constructor(public commonService: CommonService, private ajaxService: AjaxService, private trans: TranslateService, private alertController: AlertController,) { }

  async onSubmit() {
    let text = this.trans.instant("This changes affect the whole vehicle belong to this company!")
    if (this.bluetooth.blue1.editEnable || this.bluetooth.blue2.editEnable || this.bluetooth.blue3.editEnable || this.bluetooth.blue4.editEnable) {
      const alert = await this.alertController.create({
        header: 'Warning',
        backdropDismiss: false,
        message: text,
        buttons: [{
          text: 'Cancel',
          role: 'cancel',
          handler: data => {

          }
        },
        {
          text: 'Confirm',
          handler: confirm => {
            let services = false;
            const data = {
              companyId: localStorage.getItem("corpId"),
              userId: localStorage.getItem("userName"),
              tempVal: []
            }
            data["vin"] = JSON.parse(localStorage.getItem("selectedVin")).vin;
            for (let i = 0; i < 4; i++) {
              if (Object.values(this.bluetooth)[i].editEnable == true) {
                data["tempVal"].push(Object.values(this.bluetooth)[i]);
              }
            }
            console.log(data, "data")
            const url = serverUrl.web + "/simcard/updatetemp";
            this.ajaxService.ajaxPostWithErrorBody(url, JSON.stringify(data))
              .subscribe(res => {
                if (res == "c") {
                  this.commonService.presentToast("Changes saved");
                } else if (res == "f") {
                  this.commonService.presentToast("Changes not saved");
                }
              })
          }

        }]
      });
      await alert.present();
    }
  }
  async remove(data, io) {
    const alert = await this.alertController.create({
      header: 'Warning',
      backdropDismiss: false,
      message: "Are you sure you want to remove this " + this.bluetooth[data].name + " device from " + JSON.parse(localStorage.getItem("selectedVin")).plateNo,
      buttons: [{
        text: 'Cancel',
        role: 'cancel',
        handler: data => {

        }
      },
      {
        text: 'Confirm',
        handler: confirm => {
          const body = {
            vin: JSON.parse(localStorage.getItem("selectedVin")).vin,
            io: io
          }
          const url = serverUrl.web + "/simcard/remove/io";
          this.ajaxService.ajaxPostWithBody(url, body)
            .subscribe(res => {
              if (JSON.parse(res).message == "Success") {
                this.commonService.presentToast("Device removed successfully");
                this.bluetooth[data].editEnable = false;
              } else if (JSON.parse(res).message == "No Data") {
                this.commonService.presentToast("Device not available")
              }
            })

        }
      }]
    });
    await alert.present();

  }


  addMore() {
    for (let i = 0; i <= Object.keys(this.bluetooth).length; i++) {
      if (Object.keys(this.bluetooth).length == i) {
        this.commonService.presentToast("Exceeded maximum number of BT-temperature")
      } else if (Object.values(this.bluetooth)[i].visible == false) {
        Object.values(this.bluetooth)[i].visible = true;
        break;
      }
    }
  }

  ngOnInit() {
    // this.getBluetoothData();
  }
  ngOnChanges() {
    this.getBluetoothData();
  }

  getBluetoothData() {
    let url = serverUrl.web + "/device/vehicletemp?vin=" + JSON.parse(localStorage.getItem("selectedVin")).vin;
    this.ajaxService.ajaxGetWithString(url)
      .subscribe(res => {
        if (res != "") {
          this.bluetooth = {
            blue1: { sensor: "BTTEMPERATURESENSOR1", name: "BT1", min: "-20", max: "40", editEnable: false, visible: true, io: 25, imei: 25, latLng: "0,0", unit: "C", imeiVisible: true, status: 1 },
            blue2: { sensor: "BTTEMPERATURESENSOR2", name: "BT2", min: "-20", max: "40", editEnable: false, visible: false, io: 26, imei: 26, latLng: "0,0", unit: "C", imeiVisible: true, status: 1 },
            blue3: { sensor: "BTTEMPERATURESENSOR3", name: "BT3", min: "-20", max: "40", editEnable: false, visible: false, io: 27, imei: 27, latLng: "0,0", unit: "C", imeiVisible: true, status: 1 },
            blue4: { sensor: "BTTEMPERATURESENSOR4", name: "BT4", min: "-20", max: "40", editEnable: false, visible: false, io: 28, imei: 28, latLng: "0,0", unit: "C", imeiVisible: true, status: 1 },
          }
          let data = res.split(',');
          data = data.slice(0, data.length - 1);
          let arrayFourthIndex = data.shift();
          data.splice(3, 0, arrayFourthIndex);
          for (let i = 0; i < data.length; i++) {
            let splitTemp;
            splitTemp = data[i].split("|");
            for (let j = 0; j < 4; j++) {
              if (splitTemp[1] == ("BTTEMPERATURESENSOR" + [j + 1])) {
                this.bluetooth['blue' + [j + 1]].name = splitTemp[6];
                this.bluetooth["blue" + [j + 1]].min = splitTemp[2];
                this.bluetooth["blue" + [j + 1]].max = splitTemp[3];
                this.bluetooth["blue" + [j + 1]].visible = true;
                this.bluetooth["blue" + [j + 1]].editEnable = true;
              }
            }
          }
        }
        else {
          this.bluetooth = {
            blue1: { sensor: "BTTEMPERATURESENSOR1", name: "BT1", min: "-20", max: "40", editEnable: false, visible: true, io: 25, imei: 25, latLng: "0,0", unit: "C", imeiVisible: true, status: 1 },
            blue2: { sensor: "BTTEMPERATURESENSOR2", name: "BT2", min: "-20", max: "40", editEnable: false, visible: false, io: 26, imei: 26, latLng: "0,0", unit: "C", imeiVisible: true, status: 1 },
            blue3: { sensor: "BTTEMPERATURESENSOR3", name: "BT3", min: "-20", max: "40", editEnable: false, visible: false, io: 27, imei: 27, latLng: "0,0", unit: "C", imeiVisible: true, status: 1 },
            blue4: { sensor: "BTTEMPERATURESENSOR4", name: "BT4", min: "-20", max: "40", editEnable: false, visible: false, io: 28, imei: 28, latLng: "0,0", unit: "C", imeiVisible: true, status: 1 },
          }

        }
      })
  }


}
