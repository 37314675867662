import { Component, OnInit, Input } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-grid-view',
  templateUrl: './grid-view.component.html',
  styleUrls: ['./grid-view.component.scss'],
})
export class GridViewComponent implements OnInit {
  @Input() gridData;
  @Input() reportSearch;
  constructor(
    private commonService: CommonService
  ) { }
 
  ngOnInit() {
    console.log("gridData",this.gridData);
    
  }
  // ngOnChanges(changes): void {
  //   // this.gridData = changes.gridData.currentValue;
  // }
}
