import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { MultitrackingComponent } from './multitracking.component';
import { ComponentsModule } from '../components/components.module';
import { SharedModModule } from '../shared-mod/shared-mod.module';


const routes: Routes = [
  {
    path: '',
    component: MultitrackingComponent
  }
];


@NgModule({
  declarations: [MultitrackingComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    RouterModule.forChild(routes),
    ComponentsModule,
    SharedModModule
  ]
})
export class MultitrackingModule { }
