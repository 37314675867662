import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { companyReportData, languageInitializer } from 'src/environments/environment';

@Component({
  selector: 'app-employee-no-transmission-report',
  templateUrl: './employee-no-transmission-report.component.html',
  styleUrls: ['./employee-no-transmission-report.component.scss'],
})
export class EmployeeNoTransmissionReportComponent implements OnInit {
  reportData = companyReportData.reportData;
  directory = languageInitializer.directory
  constructor(
    private commonService: CommonService
  ) { }

  splitNameArabic(name) {
    return this.commonService.splitNameArabic(name, this.directory)
  }

  ngOnInit() {
    this.commonService.updateLanguageDir
      .subscribe(res => {
        this.directory = res == "" ? this.directory : res
      })
  }
  // [{
  //   zoneName: "Test", empName: "Hytdfsgyd", imeiNo: "7845123698781", simNo: "2345891524",
  //   companyName: "comatecfleet", lastTransmission: "00:00:00", supervisor: "Test", empId: "Emp001"
  // },
  // {
  //   zoneName: "Test2", empName: "Hyteblkai", imeiNo: "4859152635152", simNo: "3265984512",
  //   companyName: "Tabuk", lastTransmission: "00:00:00", supervisor: "Comatecfleet", empId: "Emp002"
  // },
  // {
  //   zoneName: "Test3", empName: "psidakhs", imeiNo: "7848585659152", simNo: "1245895623",
  //   companyName: "Test", lastTransmission: "00:00:00", supervisor: "Tabuk", empId: "Emp003"
  // },
  // {
  //   zoneName: "Test4", empName: "iuywejsjaa", imeiNo: "7845154956185", simNo: "7845895612",
  //   companyName: "Demo", lastTransmission: "00:00:00", supervisor: "WorkForceTrack", empId: "Emp004"
  // },
  // {
  //   zoneName: "Test5", empName: "askjhisnsd", imeiNo: "8948591548157", simNo: "5689451526",
  //   companyName: "Rac", lastTransmission: "00:00:00", supervisor: "racAir", empId: "Emp005"
  // }]


}
