import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-track-distance',
  templateUrl: './track-distance.component.html',
  styleUrls: ['./track-distance.component.scss'],
})
export class TrackDistanceComponent implements OnInit {
  data:any;
  constructor(
    private modalController : ModalController
  ) { }
  getBack() {
    this.modalController.dismiss();
  }
  ngOnInit() {
    
  }

}
