import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-overspeed',
  templateUrl: './table-overspeed.component.html',
  styleUrls: ['./table-overspeed.component.scss'],
})
export class TableOverspeedComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
