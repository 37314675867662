import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'goefenceSearch'
})
export class GoefenceSearchPipe implements PipeTransform {

  transform(fenceList: any[], search: string, plateNo:boolean): any {
    if(plateNo == false ){
    if(search == "" || search == undefined){
      return fenceList
    }  else {
      let value = [];
      fenceList.filter((data)=>{
        if(data.zone.replace(/ /g,'').toLowerCase().includes(search.replace(/ /g,'')))
        value.push(data)
      })
      return value
    }
    return null;
  } else {
    if(search == "" || search == undefined){
      return fenceList
    }  else {
      let value = [];
      fenceList.filter((data)=>{
        if(data.plateNo){
          if(data.plateNo.replace(/ /g,'').toLowerCase().includes(search.replace(/ /g,'')))
            value.push(data)
        }else{
          if(data.plateNo.replace(/ /g,'').toLowerCase().includes(search.replace(/ /g,'')))
            value.push(data)
        }
      })
      return value
    }
  }
  }

}
