import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { companyReportData } from 'src/environments/environment';

@Component({
  selector: 'app-idle-violation-report',
  templateUrl: './idle-violation-report.component.html',
  styleUrls: ['./idle-violation-report.component.scss'],
})
export class IdleViolationReportComponent implements OnInit {
  
Sample;
constructor(
  private commonServices: CommonService
) { }

ngOnInit() {
  this.commonServices.dismissLoader()
  this.Sample = companyReportData.reportData
}
getArabicLetters(letter) {

  return this.commonServices.numberToArabic(letter)
}
}
