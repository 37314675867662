import { Component, OnInit, ViewChild } from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import { jqxGridComponent } from "jqwidgets-ng/jqxgrid";
import { jqxTreeComponent } from "jqwidgets-ng/jqxtree";
import { AjaxService } from "src/app/services/ajax.service";
import { CommonService } from "src/app/services/common.service";
import { OpenlayerMapService } from "src/app/services/openlayer-map.service";
import { app, languageInitializer, serverUrl, storageVariable } from "src/environments/environment";
import { GeofenceAssociationModelComponent } from "../geofence-association-model/geofence-association-model.component";
import { TranslateService } from "@ngx-translate/core";
import { TranslateConfigService } from "../../services/translate-config.service";

@Component({
  selector: "app-geofence-association",
  templateUrl: "./geofence-association.component.html",
  styleUrls: ["./geofence-association.component.scss"],
})
export class GeofenceAssociationComponent implements OnInit {
  geoAssociationList: any;
  dataAdapter: any;
  selectedRowIdx: any;
  selectedRow: any;
  multiRows = [];
  source: { localdata: any };
  @ViewChild("myGrid", { static: false }) myGrid: jqxGridComponent;
  columns: { text: string; datafield: string; cellsrenderer: any }[];
  renderer: (row: number, column: any, value: string) => string;
  geoFenceList: any;
  mapService: any;
  map: any;
  layerModal = false;
  @ViewChild("mapElement", { static: false }) mapElement;
  directory: string = languageInitializer.directory;
  localizationobj: {};
  pagesizeoption = ["5", "10", "13", "15", "20"];
  entryLogin: string = localStorage.entryPoint;
  constructor(
    private ajaxService: AjaxService,
    private modalController: ModalController,
    public olMapService: OpenlayerMapService,
    private commonService: CommonService,
    public translateConfigService: TranslateConfigService,
    public trans: TranslateService,
    private alertController: AlertController,
  ) {
    this.mapService = new OpenlayerMapService();
  }
  switchMap() {
    this.layerModal = !this.layerModal
  this.map.getLayers().item(3).setVisible(this.layerModal);
    this.map.getLayers().item(0).setVisible(!this.layerModal);
  }
  async openGeoFenceAssociation() {
    let modal = await this.modalController.create({
      component: GeofenceAssociationModelComponent,
      cssClass: this.directory != 'rtl' ? "geoAssociation" : "geoAssociation-rtl",
      componentProps: {
        mode: "add",
      },
    });
    modal.onDidDismiss().then(() => {
      this.ajaxService.groupService();
      this.getDatas();
    });
    return await modal.present();
  }

  createMap() {
    if (this.mapElement) {
      this.map = this.mapService.loadMap(
        this.mapElement.nativeElement,
        { lat: 24.440099, lng: 46.843498 },
        false,
        false
      );
      this.mapService.setCenter(this.map, { lat: 24.440099, lng: 46.843498 });
    } else {
      setTimeout((res) => {
        this.createMap();
      }, 2000);
    }
  }

  getDatas() {
    var geofenceCreatedList;
    var associatedGeofenceList;
    let url = serverUrl.web + "/Alert/GeozoneDatas?data=" + localStorage.corpId;
    this.ajaxService.ajaxGetJson(url).subscribe((res) => {
      geofenceCreatedList = res;
      if (JSON.stringify(res) != "{}") {
        url = serverUrl.web + "/alert/AssociateZone/" + localStorage.userName;
        this.ajaxService.ajaxGetJson(url).subscribe((res) => {
          console.log("res,", res);

          if (res.length == 0) {
            this.source = { localdata: [] };
            this.dataAdapter = new jqx.dataAdapter(this.source);
          }
          if (res.length > 0) {
            associatedGeofenceList = res;

            for (let i = 0; i < associatedGeofenceList.length; i++) {
              let zoneId = associatedGeofenceList[i]["id"].split("-");
              let asso =
                associatedGeofenceList[i]["id"].split("-")[1] == "Circle"
                  ? (associatedGeofenceList[i]["id"] =
                    associatedGeofenceList[i]["id"].split("-")[0] +
                    "-geoFence")
                  : associatedGeofenceList[i]["id"].split("-")[1] == "Freeform"
                    ? (associatedGeofenceList[i]["id"] =
                      associatedGeofenceList[i]["id"].split("-")[0] +
                      "-freeForm")
                    : associatedGeofenceList[i]["id"].split("-")[1] == "Landmark"
                      ? (associatedGeofenceList[i]["id"] =
                        associatedGeofenceList[i]["id"].split("-")[0] +
                        "-landMark")
                      : associatedGeofenceList[i]["id"].split("-")[1] == "Road"
                        ? (associatedGeofenceList[i]["id"] =
                          associatedGeofenceList[i]["id"].split("-")[0] + "-road")
                        : associatedGeofenceList[i]["id"];


              let dashboardData: any = storageVariable.groupAndVin;
              for (let j = 0; j < dashboardData.length; j++) {
                if (dashboardData[j].vin == associatedGeofenceList[i].vin && geofenceCreatedList[zoneId[1]][asso]) {
                  associatedGeofenceList[i]["plateNo"] = dashboardData[j].plateNo;
                  associatedGeofenceList[i]["zoneName"] = geofenceCreatedList[zoneId[1]][asso].name;
                  associatedGeofenceList[i]["zoneLatLng"] = JSON.stringify(geofenceCreatedList[zoneId[1]][asso].latlng);
                }
              }
            }

            this.renderer = (row: number, column: any, value: string) => {
              if (value == "" || null || undefined) {
                return "----";
              } else {
                if (column == 'plateNo' && app.entryPoint == "WFT")
                  value = value.split("@,@")[0] + ` (${value.split("@,@")[1]})`;
                return (
                  '<span  style="line-height:32px;font-size:11px;color:darkblue;margin:auto;padding:0px 5px">' +
                  value +
                  "</span>"
                );
              }
            };

            var detail = associatedGeofenceList;
            this.source = { localdata: detail };
            this.dataAdapter = new jqx.dataAdapter(this.source);
            this.columns = [
              {
                text: this.trans.instant("Plate No"),
                datafield: "plateNo",
                cellsrenderer: this.renderer,
              },
              {
                text: this.trans.instant("Zone Name"),
                datafield: "zoneName",
                cellsrenderer: this.renderer,
              },
              {
                text: this.trans.instant("Status"),
                datafield: "status",
                cellsrenderer: this.renderer,
              },
              {
                text: this.trans.instant("On Enter"),
                datafield: "onEnter",
                cellsrenderer: this.renderer,
              },
              {
                text: this.trans.instant("On Leave"),
                datafield: "onLeave",
                cellsrenderer: this.renderer,
              },
              {
                text: this.trans.instant("GeozoneType"),
                datafield: "type",
                cellsrenderer: this.renderer,
              },
            ];
            this.myGrid.pagesizeoptions(this.pagesizeoption)
            if (app.entryPoint == "WFT")
              this.columns[0] = {
                text: this.trans.instant("Employee name"),
                datafield: "plateNo",
                cellsrenderer: this.renderer,
              }
            if (app.entryPoint == "ATM")
              this.columns[0] = {
                text: this.trans.instant("ATM ID"),
                datafield: "plateNo",
                cellsrenderer: this.renderer,
              },
                this.myGrid.unselectrow;
          } else {
            if (this.entryLogin === "ATM") {
              this.commonService.presentToast("No associated ATM to show");
            }
            else
              this.commonService.presentToast("No associated vehicle to show");
          }
        });
      }
    });
  }

  myGridOnRowSelect($event) {
    // console.log($event)
    // this.selectedRow = $event.args.row;

    // if (this.selectedRow.type == "Circle") {
    //   this.createCircle(this.selectedRow);
    // } else if (this.selectedRow.type == "Road") {
    //   this.createRoadForm(this.selectedRow);
    // } else if (this.selectedRow.type == "Freeform") {
    //   this.createFreeForm(this.selectedRow)
    // } else if (this.selectedRow.type == "Landmark") {
    //   this.createLandMark(this.selectedRow)
    // }
    this.mapService.clearLayers(this.map, [1, 2]);
    let allRows = this.myGrid.attrSource["originaldata"];
    let selectedRowIndex: any = this.myGrid.getselectedrowindexes();
    if (selectedRowIndex.length > 0) {
      for (let i = 0; i < selectedRowIndex.length; i++) {
        let selectedRow = allRows[selectedRowIndex[i]];
        if (selectedRow.type == "Circle") {
          this.createCircle(selectedRow);
        } else if (selectedRow.type == "Road") {
          this.createRoadForm(selectedRow);
        } else if (selectedRow.type == "Freeform") {
          this.createFreeForm(selectedRow);
        } else if (selectedRow.type == "Landmark") {
          this.createLandMark(selectedRow);
        }
      }
      this.mapService.fitBounds(this.map, 2);
    }

    // if (this.geofenceTree.getCheckedItems().length > 0) {
    //   this.mapService.clearLayers(this.map, [1, 2])
    //   var id = this.geofenceTree.getCheckedItems();
    //   for (let i = 0; i < id.length; i++) {
    //     var selectedRow = this.treeGeofenceObject[id[i].value].json
    //     if (selectedRow) {
    //       if (selectedRow.type == "Circle") {
    //         this.createCircle(selectedRow);
    //       } else if (selectedRow.type == "Road") {
    //         this.createRoadForm(selectedRow);
    //       } else if (selectedRow.type == "Freeform") {
    //         this.createFreeForm(selectedRow)
    //       } else if (selectedRow.type == "Landmark") {
    //         this.createLandMark(selectedRow)
    //       }
    //     }
    //   }
    //   this.mapService.fitBounds(this.map, 2)
    // } else {
    //   if (this.map)
    //     this.mapService.clearLayers(this.map, [1, 2])
    // }
  }
  async editGeoFence() {
    var detailsString = this.myGrid.getselectedrowindexes();
    if (detailsString.length > 0) {
      this.multiRows = [];
      for (var s in detailsString) {
        var row = this.myGrid.getrowdata(detailsString[s]);
        this.multiRows.push(row);
      }


      let modal = await this.modalController.create({
        component: GeofenceAssociationModelComponent,
        cssClass: "geoAssociation",
        componentProps: {
          mode: this.multiRows.length == 1 ? "edit" : "multi",
          selectedRow: this.selectedRow,
          multiRow: this.multiRows,
        },
      });
      modal.onDidDismiss().then(() => {
        this.ajaxService.groupService()
        this.myGrid.clearselection();
        this.myGrid.refresh();
        this.getDatas()
      });
      return await modal.present();
    } else {
      this.commonService.presentToast("Please select the row to edit")
    }
  }

  createLandMark(selectedRow) {
    let data = JSON.parse(selectedRow.zoneLatLng);
    let latLng = {
      lat: parseFloat(data.split(",")[0]),
      lng: parseFloat(data.split(",")[1]),
    };
    this.mapService.addMarker(
      this.map,
      latLng,
      "geoFence",
      "assets/vtstrackhisIcon/Idle.png"
    );
  }
  createRoadForm(selectedRow) {
    let data = JSON.parse(selectedRow.zoneLatLng);
    let latLngData = [];
    for (let i in data) {
      var latlng = data[i];
      latLngData.push({
        latitude: latlng.split(",")[0],
        longitude: latlng.split(",")[1],
      });
    }
    this.mapService.setRoadLineString(this.map, latLngData);
  }

  createFreeForm(selectedRow) {
    let data = JSON.parse(selectedRow.zoneLatLng);
    let latLngData = [];
    for (let i in data) {
      var latlng = data[i];
      latLngData.push({
        latitude: latlng.split(",")[0],
        longitude: latlng.split(",")[1],
      });
    }
    latLngData.push({
      latitude: data[0].split(",")[0],
      longitude: data[0].split(",")[1],
    });
    this.mapService.setRoadLineString(this.map, latLngData);
  }

  createCircle(selectedRow) {
    let selectValue = "Prefered";
    let latLng;
    let range;
    if (selectedRow.Area === "Restricted") {
      selectValue = "Restricted";
    }
    const detailsString = JSON.parse(selectedRow.zoneLatLng);
    const getLatLng = detailsString.split("|");
    const southwest = getLatLng[0].split(",");
    const northeast = getLatLng[1].split(",");
    const south = parseFloat(southwest[0].split(",")[0]);
    const west = parseFloat(southwest[1].split(",")[0]);
    const north = parseFloat(northeast[0].split(",")[0]);
    const east = parseFloat(northeast[1].split(",")[0]);
    latLng = { lat: south, lng: west };
    range = this.calculateRadius(north, east, south, west);
    this.mapService.addMarker(
      this.map,
      latLng,
      "geoFence" + JSON.stringify(latLng),
      "assets/vtstrackhisIcon/Idle.png"
    );
    this.mapService.createCircle(this.map, latLng, range, selectValue);
  }

  calculateRadius = function (south, west, north, east) {
    const R = 6378.137; // Radius of earth in KM
    const dLat = ((north - south) * Math.PI) / 180;
    const dLon = ((east - west) * Math.PI) / 180;
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos((south * Math.PI) / 180) *
      Math.cos((north * Math.PI) / 180) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const d = R * c;
    return Math.round(d * 1000);
  };

  async deleteGeoFence() {
    const checkRow = this.myGrid.getselectedrowindexes();
    if (checkRow.length == 0) {
      this.commonService.presentToast("Please select the row to delete");
      return null;
    }
    const alert = await this.alertController.create({
      header: this.trans.instant('Are you sure?'),
      backdropDismiss: false,
      message: this.trans.instant("Do you want to delete your Record?. If you delete it, your changes will be lost."),
      buttons: [{
        text: this.trans.instant('Cancel'),
        role: 'cancel',
        handler: data => {
        }
      },
      {
        text: this.trans.instant('Ok'),
        handler: datas => {
          var detailsString = this.myGrid.getselectedrowindexes();
          if (detailsString.length > 0) {
            this.multiRows = [];
            for (var s in detailsString) {
              var row = this.myGrid.getrowdata(detailsString[s]);
              this.multiRows.push(row);
            }
          }
          let multirows = this.multiRows;
          var zoneForDelete = { Road: [], Freeform: [], Circle: [], Landmark: [] };

          for (var i = 0; i < multirows.length; i++) {
            let zonetype = multirows[i].id.split("-")[1];
            if (zonetype.toLowerCase() == "road") {
              let emptyObj = {};
              emptyObj["vin"] = multirows[i].vin;
              emptyObj["zoneId"] = multirows[i].id.split("-")[0];
              emptyObj["zoneName"] = multirows[i].zoneName;
              emptyObj["plateNo"] = multirows[i].plateNo;
              zoneForDelete.Road.push(emptyObj);
            } else if (zonetype.toLowerCase() == "freeform") {
              let emptyObj = {};
              emptyObj["vin"] = multirows[i].vin;
              emptyObj["zoneId"] = multirows[i].id.split("-")[0];
              emptyObj["zoneName"] = multirows[i].zoneName;
              emptyObj["plateNo"] = multirows[i].plateNo;
              zoneForDelete.Freeform.push(emptyObj);
            } else if (zonetype.toLowerCase() == "geofence") {
              let emptyObj = {};
              emptyObj["vin"] = multirows[i].vin;
              emptyObj["zoneId"] = multirows[i].id.split("-")[0];
              emptyObj["zoneName"] = multirows[i].zoneName;
              emptyObj["plateNo"] = multirows[i].plateNo;
              zoneForDelete.Circle.push(emptyObj);
            } else {
              let emptyObj = {};
              emptyObj["vin"] = multirows[i].vin;
              emptyObj["zoneId"] = multirows[i].id.split("-")[0];
              emptyObj["zoneName"] = multirows[i].zoneName;
              emptyObj["plateNo"] = multirows[i].plateNo;
              zoneForDelete.Landmark.push(emptyObj);
            }
          }

          if (zoneForDelete.Road.length == 0) delete zoneForDelete.Road;
          if (zoneForDelete.Freeform.length == 0) delete zoneForDelete.Freeform;
          if (zoneForDelete.Circle.length == 0) delete zoneForDelete.Circle;
          if (zoneForDelete.Landmark.length == 0) delete zoneForDelete.Landmark;
          // console.log(zoneForDelete);
          const url = serverUrl.web + '/alert/deleteZoneSericeData?companyId=' + localStorage.getItem('corpId') + '&branchId=' + localStorage.corpId + '&userId=' + localStorage.userName + '&macIp=""';
          const data = zoneForDelete;
          this.ajaxService.ajaxDeleteWithBody(url, data).subscribe((res) => {
            if (res.status == "success") {
              this.commonService.presentToast("Deleted Succesfully")
              this.getDatas();
              this.myGrid.clearselection();
            } else this.commonService.presentToast("Operation not performed, please try again later")
          });
        }
      }]
    });
    await alert.present();



  }
  ngOnInit() {
    this.getDatas();
    this.createMap();
    this.commonService.updateLanguageDir
      .subscribe(res => {

        this.directory = res == "" ? this.directory : res
      })

    this.localizationobj = {
      groupsheaderstring: this.trans.instant("Drag a column and drop it here to group by that column"),
    };
  }
}
