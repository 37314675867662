import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { jqxGaugeComponent } from 'jqwidgets-ng/jqxgauge';

@Component({
  selector: 'app-gauge',
  templateUrl: './gauge.component.html',
  styleUrls: ['./gauge.component.scss'],
})
export class GaugeComponent implements OnInit {
  @ViewChild('myGauge', { static: false }) myGauge: jqxGaugeComponent;
  @Input() count: number;
  @Input() width: number;
  @Input() height: number;
  @Input() min: number;
  @Input() max: number;
  @Input() liveDataGauge;
  @Input() value;
  ranges;


  ngAfterViewInit(): void {
    // this.myGauge.value(this.liveDataGauge.count);
  }

  ngOnChanges() {
    this.ranges = [
      { startValue: 0, endValue: this.liveDataGauge.end[0], style: { fill: '#4bb648', stroke: '#4bb648' }, endWidth: 5, startWidth: 1 },
      { startValue: this.liveDataGauge.end[0], endValue: this.liveDataGauge.end[1], style: { fill: '#fbd109', stroke: '#fbd109' }, endWidth: 8, startWidth: 5 },
      { startValue: this.liveDataGauge.end[1], endValue: this.liveDataGauge.end[2], style: { fill: '#ff8000', stroke: '#ff8000' }, endWidth: 10, startWidth: 8 }
    ]
    // this.myGauge.value(this.liveDataGauge.count);

  }

  // labels: any = {
  //     interval: 20,
  //     formatValue: (value: number, position: string): string => {
  //         if (position === 'far') {
  //             value = (9 / 5) * value + 32;
  //             if (value === -76) {
  //                 return '°F';
  //             }
  //             return value + '°';
  //         }
  //         if (value === -60) {
  //             return '°C';
  //         }
  //         return value + '°';
  //     }
  // };

  constructor() { }

  // ngOnChanges() {
  //   console.log(this.ticksMajor);
  // }

  ngOnInit() { }

}
