import { Component, OnInit, ViewChild, Input, ChangeDetectorRef } from '@angular/core';
import { jqxTreeComponent } from 'jqwidgets-ng/jqxtree';
import { ModalController, AlertController } from '@ionic/angular';
import { AjaxService } from 'src/app/services/ajax.service';
import { languageInitializer, serverUrl, storageVariable } from 'src/environments/environment';
import { IonicSelectableValueTemplateDirective } from 'ionic-selectable';
import { CommonService } from 'src/app/services/common.service';
import { analyzeAndValidateNgModules } from '@angular/compiler';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alert-configuration-model',
  templateUrl: './alert-configuration-model.component.html',
  styleUrls: ['./alert-configuration-model.component.scss'],
})
export class AlertConfigurationModelComponent implements OnInit {
  @Input() selectedRow;
  @Input() tabledata;
  @ViewChild('myTree', { static: false }) myTree: jqxTreeComponent;
  checkevent: any = "MOVEMENT";
  selectvalues: any = "2";
  statuscheck = true;
  carbrand: any;
  carbrandtemp: any;
  sources: any; columns: any;
  treeStructureObject: any = {};
  checkevents: any; checkentryval: any; checkentryval1: any;
  secIning: any = ""; limit: any; selectedEffectill: any;
  fuelLevel: any = ""; heartBeat: any = "";
  maxfuelLevel: any = "";
  dividedFuel: any = "1600"
  mode: any;
  interval: any = 10;
  entryLogin: string = localStorage.entryPoint;
  selectedDays: any = "Sun#Mon#Tue#Wed#Thu#Fri#Sat"
  selectedDays1: any;
  startTime: any = "00:00";
  endTime: any = "23:59";
  Runninginterval: any;
  Stopinterval: any;
  check = false;
  status: any;
  num: number = 0;
  mobilenumber: any = "";
  Time1: any = "00:00";
  Time2: any = "23:59";
  Search: any;
  intervalvalues: any = "Minutes";
  mailaddress: any = "";
  notificationType = [
    { val: 'Push', isChecked: true },
    { val: 'Sms', isChecked: false },
    { val: 'Mail', isChecked: false },
  ];
  isBzChecked = false;
  subscribtion = { val: 'Active', isChecked: false }
  dataAdapter: any;
  records: any;
  dayvalues = [""];
  alertsArray
  directory = languageInitializer.directory
  dashboardJson: any[] = storageVariable.groupAndVin;
  tempAlertTypes: any = [{ label: "Select All", value: "All" }, 'Running', 'Stop', 'Idle', 'Towed'];
  tempAlertValue = ['Running', 'Stop', 'Idle', 'Towed'];
  selectedTempAlert: any = [];
  ngRange: any = "outofrange";
  alerttabstyle: any;
 geofenceList = {}
  filterData: any = {};
  treeStructureJson = [];
  source = {
    datatype: 'json',
    datafields: [{ name: 'id' }, { name: 'parentid' }, { name: 'text' }, { name: 'value' }],
    id: 'id',
    localdata: this.treeStructureJson
  };
  selectedWeekDays: any = [];
  toolTip: boolean = false;
  altFreqHrs: any;
  fatigDueMns: any;
  hoursSelect: any;
  minutesSelect: any;
  fuelCalcType: any = "Percentage";
  litreCheck: boolean;
  percentageCheck: boolean = false;
  secIningtemp: string;
  tempAlerts = ["TEMPERATURE1","TEMPERATURE2","TEMPERATURE3","TEMPERATURE4","BTTEMPERATURE1","BTTEMPERATURE2","BTTEMPERATURE3","BTTEMPERATURE4","DOOROPEN","DOOROPEN BACK"]
  buzzerValue: string;
  vehicleCache: any;
  intervalChange = 'Minutes'; 
  constructor(
    private ajaxService: AjaxService,
    private commonService: CommonService,
    private modalController: ModalController,
    private alertController: AlertController,
    private cdRef: ChangeDetectorRef,
    private trans: TranslateService) {
  }
  buzzerCheck(ev){
    console.log("ev",ev.detail.checked); 
     this.isBzChecked = (ev.detail.checked) ? true : false
     console.log("this.isBzChecked",this.isBzChecked);
     
  }
  BuzzerValChange(ev){
    this.buzzerValue = ev.detail.value
    console.log("this.buzzerValue",this.buzzerValue);
  }
  disableTreeDrag() {
    if (this.myTree) {
      this.myTree.allowDrag(false);
    }
  }
  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
    this.myTree.expandAll();
    if (this.mode == "edit") {
      this.mode = "edit";
      let selectRow = document.getElementById(this.treeStructureObject[this.selectedRow['Plate Number']].id)
      this.myTree.checkItem(selectRow, true);
      this.myTree.disabled(true);
      this.checkevent = this.selectedRow.alertType;
      this.selectvalues = this.selectedRow.Validity;
      this.interval = this.selectedRow['Interval (Minutes)'];
      if (this.selectedRow.Status == "Subscribed")
        this.statuscheck = true;
      else
        this.statuscheck = false;
      if (this.selectedRow.Time != "0:00_23:59" && this.selectedRow.Time != "") {
        let time = this.selectedRow.Time.split("_");
        let stTime = time[0];
        let edTime = time[1];
        this.startTime = stTime;
        this.endTime = edTime;
      }
     if (this.selectedRow.hasOwnProperty("subAlertType") && this.selectedRow["subAlertType"] != ""){ 
      this.isBzChecked = true;
      this.buzzerValue = this.selectedRow["subAlertType"].split("TEMPERATURESENSOR_BUZZER#")[1]
    }else this.buzzerValue = "5"

    
      let smscheck = this.selectedRow['Mobile Number'] == "" ? false : true;
      let emailcheck = this.selectedRow['Email Address'] == "" ? false : true;
      if (smscheck) {
        this.mobilenumber = this.selectedRow['Mobile Number'].replaceAll("#", ",");
        this.checkentryval = "Sms";
      }
      if (emailcheck) {
        this.mailaddress = this.selectedRow['Email Address'].replaceAll("#", ",");
        this.checkentryval1 = "Mail";
      }
      this.notificationType = [
        { val: 'Push', isChecked: true },
        { val: 'Sms', isChecked: smscheck },
        { val: 'Mail', isChecked: emailcheck },
      ];

      let days = parseInt(this.selectedRow.Day);
      if (days / 64 >= 1) {
        days = days % 64;
        this.dayvalues.push("Sun");
      }
      if (days / 32 >= 1) {
        days = days % 32;
        this.dayvalues.push("Mon");
      }
      if (days / 16 >= 1) {
        days = days % 16;
        this.dayvalues.push("Tue");
      }
      if (days / 8 >= 1) {
        days = days % 8;
        this.dayvalues.push("Wed");
      }
      if (days / 4 >= 1) {
        days = days % 4;
        this.dayvalues.push("Thu");
      }
      if (days / 2 >= 1) {
        days = days % 2;
        this.dayvalues.push("Fri");
      }
      if (days == 1)
        this.dayvalues.push("Sat");

      this.selectedWeekDays = this.dayvalues;
      if (this.selectedRow.alertType == "OVERSPEED") {
        this.secIning = this.selectedRow.SecondsInIgnore.toString();
        this.carbrand = this.selectedRow.SecondsInIgnore.toString();
      }
      if (this.selectedRow.alertType == "DOOROPEN" || this.selectedRow.alertType == "DOOROPEN BACK") {
        this.secIningtemp = this.selectedRow.SecondsInIgnore.toString();
        this.carbrandtemp = this.selectedRow.SecondsInIgnore.toString();
      }
      if (this.selectedRow.alertType == 'TEMPERATURE4' || this.selectedRow.alertType == 'TEMPERATURE3' || this.selectedRow.alertType == 'TEMPERATURE2' || this.selectedRow.alertType == 'TEMPERATURE1' || this.selectedRow.alertType == 'BTTEMPERATURE1' || this.selectedRow.alertType == 'BTTEMPERATURE2' || this.selectedRow.alertType == 'BTTEMPERATURE3' || this.selectedRow.alertType == 'BTTEMPERATURE4') {
        let a = this.selectedRow.Range.split("#");
        this.ngRange = a[1] == 1 ? "outofrange" : "betweenrange";
        a[0].split(",");
        this.selectedTempAlert = a[0].split(",")
        this.secIningtemp = this.selectedRow.SecondsInIgnore.toString();
        this.carbrandtemp = this.selectedRow.SecondsInIgnore.toString();

      }
      if (this.selectedRow.alertType == 'STOP'
        || this.selectedRow.alertType == 'IDLE' || this.selectedRow.alertType == 'TOWED')
        this.limit = (this.selectedRow.Range / 60);
      else if (this.selectedRow.alertType == "FATIGUE") {
        this.hoursSelect = this.selectedRow.Range.split("#")[0]
        this.minutesSelect = this.selectedRow.Range.split("#")[1]
        this.fatigDueMns = this.minutesSelect;
        this.altFreqHrs = this.hoursSelect
      } else
        this.limit = this.selectedRow.Range;

      if (this.selectedRow.alertType == "OPTIME")
        if (this.selectedRow.Range != "0:00_23:59" && this.selectedRow.Range != "") {
          let time = this.selectedRow.Range.split("_");
          let stTime = time[0];
          let edTime = time[1];
          this.Time1 = stTime;
          this.Time2 = edTime;
        }
      this.selectedDays = "";

      if (this.selectedRow.alertType == "FUEL MAX THRESHOLD" || this.selectedRow.alertType == "FUEL MIN THRESHOLD") {
        this.fuelLevel = this.selectedRow.Range;

        for (let i = 0; i < storageVariable.groupAndVin.length; i++) {
          if (this.selectedRow['Plate Number'] == storageVariable.groupAndVin[i].plateNo) {
            this.maxfuelLevel = storageVariable.groupAndVin[i].fuelTankCapacity;
            break;
          }
        }
        this.heartBeat = this.selectedRow["Interval (Minutes)"]
      }
    } else if (this.mode == "multi") {
      this.myTree.checkAll();
      this.selectedTempAlert = [];
      this.ngRange = ""
      this.mode = "multi";
      this.selectedDays = "";
      this.selectvalues = "";
      this.interval = "";
    } else {
      this.mode = "new"
      this.statuscheck = true;
      this.dayvalues = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      this.selectedTempAlert = ['Running', 'Stop', 'Idle', 'Towed'];
      this.ngRange = "outofrange"
      this.selectedWeekDays = this.dayvalues;
      this.altFreqHrs = "3";
      this.fatigDueMns = "10";
      this.carbrand = "20"
      this.carbrandtemp = "0"
      this.secIning = "20";
      this.secIningtemp = "0";
      this.buzzerValue = "5"

    }
    this.num++;
    setTimeout(() => {
      this.disableTreeDrag();
    }, 1000);
  }

  renderPlateNoForMultiSel = () => {
    this.treeStructureObject = {};
    this.filterData = {};
    let grpJsonId = 1;
    let grp = "";
    let tabledatas = [];
    var multiVinVar = []
    for (let t in this.tabledata) {
      if (this.checkevent == this.tabledata[t].alertType) {
        tabledatas.push(this.tabledata[t]['Plate Number']);
        multiVinVar.push(this.tabledata[t]['Vehicle Details'])
      }
    }
    if (multiVinVar.length > 0) {
      let filteredDashboardData = multiVinVar.length == 0 ? this.dashboardJson : []
      for (let i = 0; i < multiVinVar.length; i++) {
        this.dashboardJson.forEach(el => {
          if (multiVinVar[i] == el.vin)
            filteredDashboardData.push(el)
        })

      }
      for (let i = 0; i < filteredDashboardData.length; i++) {
        let groupArray: any = [];
        groupArray = filteredDashboardData[i].Group == null || filteredDashboardData[i].Group == undefined ? ["None group"] : filteredDashboardData[i].Group.split(",");
        let filterTextFormat = ""
        for (let j = 0; j < groupArray.length; j++) {
          filterTextFormat += groupArray[j]
          if (this.filterData[filterTextFormat] == undefined) {
            this.filterData[filterTextFormat] = grpJsonId;
            grpJsonId++;
          }
          if (groupArray.length - 1 != j)
            filterTextFormat += ","
        }
        this.treeStructureObject[filteredDashboardData[i].plateNo] = {
          id: grpJsonId.toString(),
          parentid: this.filterData[groupArray].toString(),
          text: filteredDashboardData[i].plateNo.split("@,@").length >= 2 ? filteredDashboardData[i].plateNo.split("@,@")[0] + ` (${filteredDashboardData[i].plateNo.split("@,@")[1]})` : filteredDashboardData[i].plateNo,
          value: filteredDashboardData[i].vin
        }

        this.treeStructureJson.push({
          id: grpJsonId.toString(),
          parentid: this.filterData[groupArray].toString(),
          text: filteredDashboardData[i].plateNo.split("@,@").length >= 2 ? filteredDashboardData[i].plateNo.split("@,@")[0] + ` (${filteredDashboardData[i].plateNo.split("@,@")[1]})` : filteredDashboardData[i].plateNo,
          value: filteredDashboardData[i].vin
        })
        grpJsonId++;
      }

      for (let i = 0; i < Object.keys(this.filterData).length; i++) {
        let loopedArry: any = Object.keys(this.filterData)[i].split(",").length - 1;
        loopedArry = Object.keys(this.filterData)[i].split(",")[loopedArry];
        var popData = Object.keys(this.filterData)[i].split(",")
        popData.pop()
       this.treeStructureObject[loopedArry] = {
          id: Object.values(this.filterData)[i].toString(),
          parentid: Object.keys(this.filterData)[i].split(",").length > 1 ? this.filterData[popData.toString()] : "-1",
          text: loopedArry,
          value: loopedArry
        }
        this.treeStructureJson.push({
          id: Object.values(this.filterData)[i].toString(),
          parentid: Object.keys(this.filterData)[i].split(",").length > 1 ? this.filterData[popData.toString()] : "-1",
          text: loopedArry,
          value: loopedArry
        }
        )
      }
    } else {
      this.treeStructureJson = [{}]
      this.commonService.presentAlert("Warning", "Sorry, you haven't configured the selected alert.")
    }
    this.source = {
      datatype: 'json',
      datafields: [{ name: 'id' }, { name: 'parentid' }, { name: 'text' }, { name: 'value' }],
      id: 'id',
      localdata: this.treeStructureJson
    }
    this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
    this.records = this.dataAdapter.getRecordsHierarchy('id', 'parentid', 'items', [{ name: 'text', map: 'label' }]);

    if (tabledatas.length != 0) {
      if (this.myTree) {
        this.myTree.refresh();
        setTimeout(res => {
          this.myTree.expandAll();
          this.myTree.checkAll();
        }, 250)
      }
    } else {
      // this.myTree.refresh();
      // this.myTree.expandAll();
    }
  }

  renderPlateNo() {
    if (this.mode == "multi") {
      this.treeStructureJson = [];
      this.renderPlateNoForMultiSel();

    } else {
      let grpJsonId = 1;
      for (let i = 0; i < this.dashboardJson.length; i++) {
        let groupArray: any = [];
        groupArray = this.dashboardJson[i].Group == null || this.dashboardJson[i].Group == undefined ? ["None group"] : this.dashboardJson[i].Group.split(",")
        let filterTextFormat = ""
        for (let j = 0; j < groupArray.length; j++) {
          filterTextFormat += groupArray[j]
          if (this.filterData[filterTextFormat] == undefined) {
            this.filterData[filterTextFormat] = grpJsonId;
            grpJsonId++;
          }
          if (groupArray.length - 1 != j)
            filterTextFormat += ","
        }
        groupArray = this.dashboardJson[i].Group == null ? "None group" : this.dashboardJson[i].Group
        this.treeStructureObject[this.dashboardJson[i].plateNo] = {
          id: grpJsonId.toString(),
          parentid: this.filterData[groupArray].toString(),
          text: this.dashboardJson[i].plateNo.split("@,@").length >= 2 ? this.dashboardJson[i].plateNo.split("@,@")[0] + ` (${this.dashboardJson[i].plateNo.split("@,@")[1]})` : this.dashboardJson[i].plateNo,
          value: this.dashboardJson[i].vin
        }

        this.treeStructureJson.push({
          id: grpJsonId.toString(),
          parentid: this.filterData[groupArray].toString(),
          text: this.dashboardJson[i].plateNo.split("@,@").length >= 2 ? this.dashboardJson[i].plateNo.split("@,@")[0] + ` (${this.dashboardJson[i].plateNo.split("@,@")[1]})` : this.dashboardJson[i].plateNo,
          value: this.dashboardJson[i].vin
        })

        grpJsonId++;
      }
      for (let i = 0; i < Object.keys(this.filterData).length; i++) {
        let loopedArry: any = Object.keys(this.filterData)[i].split(",").length - 1;
        loopedArry = Object.keys(this.filterData)[i].split(",")[loopedArry]
        var popData = Object.keys(this.filterData)[i].split(",")
        popData.pop()
        this.treeStructureObject[loopedArry] = {
          id: Object.values(this.filterData)[i].toString(),
          parentid: Object.keys(this.filterData)[i].split(",").length > 1 ? this.filterData[popData.toString()] : "-1",
          text: loopedArry,
          value: loopedArry
        }

        this.treeStructureJson.push({
          id: Object.values(this.filterData)[i].toString(),
          parentid: Object.keys(this.filterData)[i].split(",").length > 1 ? this.filterData[popData.toString()] : "-1",
          text: loopedArry,
          value: loopedArry
        })
      }
      this.dataAdapter = new jqx.dataAdapter(this.source, { autoBind: true });
      this.records = this.dataAdapter.getRecordsHierarchy('id', 'parentid', 'items', [{ name: 'text', map: 'label' }]);

      setTimeout(() => {
        this.myTree.expandAll();
      }, 500);

    }
  }
  selectPlateNo(event) {
  }

  renderAlerts() {
    let url = serverUrl.web + "/logins/preference/{'key':'Alerts','companyID':" + localStorage.corpId + "}";
    this.ajaxService.ajaxGetPerference(url)
      .subscribe(res => {
        this.alertsArray = res
      })
  }

  selectcard(event) {
    this.notificationType.forEach((keys)=>{
      if(keys.val == "Push"){
        keys.isChecked = true;
      }
      else{
        keys.isChecked = false;
      }
    })
    this.isBzChecked=false;
    this.selectedTempAlert=['Running', 'Stop', 'Idle', 'Towed'];
    this.ngRange = "outofrange";
    this.intervalChange = 'Minutes'; 
    this.interval="10";
    this.selectedWeekDays=this.dayvalues;
    this.startTime="00:00";
    this.endTime="23:59";
    this.selectvalues = "2";
    this.Time1 = "00:00";
    this.Time2 = "23:59";
    this.statuscheck = true;
    this.carbrand = "20";
    this.carbrandtemp = "0"
    this.mobilenumber = "";
    this.mailaddress = "";
    this.buzzerValue = "5";
    if (this.mode == "new") {
      this.checkevent = event.AlertType
      this.checkevents = event;
      if(this.checkevent == "FUEL MAX THRESHOLD" || this.checkevent == "FUEL MIN THRESHOLD"){   
        if(this.myTree.getCheckedItems().length > 1){ 
          this.commonService.presentToast("You can select 1 vehicle for Fuel alert configure")
          this.maxfuelLevel = "";
          this.myTree.uncheckAll();
          return
        }
        if(this.vehicleCache.length == 0) this.maxfuelLevel = "";
      if(this.vehicleCache.length == 1)
            var foundItem = storageVariable.groupAndVin.find(item => item.plateNo === this.vehicleCache[0].label);
            if (foundItem) {
              this.maxfuelLevel = foundItem.fuelTankCapacity;
              if (!foundItem.fuelTankCapacity) {
                this.commonService.presentAlert("Sorry, you haven't configured the Tank capacity", "Please fill the tank capacity in particular vehicle in vehicle tab");
                this.myTree.uncheckItem(this.vehicleCache[0]);
              }
            }
      }

      if (this.checkevent == 'OVERSPEED') {
        this.limit = JSON.parse(localStorage.getItem('appSettings')).OverSpeed;
       } else {
        this.limit = "";
      }

    }
    if (this.mode == "multi") {
      this.checkevent = event.AlertType
      this.checkevents = event;
      this.Search = ""
      if(this.checkevent == "FUEL MAX THRESHOLD" || this.checkevent == "FUEL MIN THRESHOLD"){
        this.myTree.uncheckAll();
        this.myTree.disabled()
        this.commonService.presentAlert("Warning", "Sorry, you haven't configured the selected alert.")

      }        else this.renderPlateNo();
      
    }
  }



  onChangeSecInIg($event) {
    this.secIning = $event.target.value;
    this.secIningtemp = $event.target.value;
  }

  onChangedays($event) {
    this.selectedDays = "";
    let days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let selectedDay = $event.target.value;
    for (let cnt in days) {
      let day = days[cnt];
      let flag = false;
      for (let cnt2 in selectedDay) {
        if (day == selectedDay[cnt2])
          flag = true;
      }
      if (day != 'Sun')
        this.selectedDays += "#";

      if (flag)
        this.selectedDays += day;
      else
        this.selectedDays += "_";

    }
  }

  onChangeEffectivetill($event) {
    this.selectedEffectill = $event.target.value;
  }

  checkCheckBoxvalue($event) {
    if (this.num != 0)
      if ($event.target.checked)
        this.status = "1";
      else
        this.status = "0";
    this.num++;
  }

  chnageinterval($event) {
    if ($event != "" && $event != undefined)
      this.intervalvalues = $event.target.value == undefined ? "Minutes" : $event.target.value;
  }
  changenotificationmode(event) {
    if (event.val == "Sms" && event.isChecked)
      this.checkentryval = event.val;

    if (event.val == "Mail" && event.isChecked)
      this.checkentryval1 = event.val

    if (event.val == "Sms" && event.isChecked == false)
      this.checkentryval = '';

    if (event.val == "Mail" && event.isChecked == false)
      this.checkentryval1 = '';
  }

  validatAlertsCong(alertName) {
    if (this.checkentryval && !this.checkentryval1) {
      if (this.checkentryval && this.mobilenumber && this.mobilenumber.match(/^\d{10}$/)) {
        return true
      } else {
        return false
      }
    }
    if (this.checkentryval && this.checkentryval1) {
      if (this.checkentryval && this.mobilenumber && this.checkentryval1 && this.mailaddress) {
        return true
      } else {
        return false
      }
    }
    if (!this.checkentryval && this.checkentryval1) {
      if (this.checkentryval1 && this.mailaddress) {
        return true
      } else {
        return false
      }
    }
    if (alertName == "IDLE" || alertName == "STOP" || alertName == "SEATBELT" || alertName == "TOWED") {
      if (this.limit && this.interval) {
        return true
      }
    }
    if (alertName == "LowBattery" || alertName == "MOVEMENT" || alertName == "POWERCUT" || alertName == "OPTIME" ) {
      if (this.interval) {
        return true
      }
    }
    if (alertName == "OVERSPEED") {
      if (this.limit && this.interval && this.secIning) {
        return true
      }
    }
    if (alertName == "DOOROPEN" || alertName == "DOOROPEN BACK") {
      if (this.limit && this.interval && this.secIningtemp) {
        return true
      }
    }
    if (alertName == "FATIGUE") {
      if (this.altFreqHrs && this.fatigDueMns) {
        return true
      }
    }
    if (alertName == "FUEL MAX THRESHOLD" || alertName == "FUEL MIN THRESHOLD") {
      if (this.fuelLevel && this.heartBeat) {
        return true
      }
    }
    if (alertName == "PANIC" || alertName == "HARSHBRAKING" || alertName == "HARSHACCELERATION" || alertName == "DRIFT" || alertName == "PanelAlarms" || alertName == "PreventiveMaintenance" || alertName == "ScheduledMaintenance" || alertName == "ANTITHEFT" || alertName == "WOKEUP" || alertName == "SOS" || alertName == "ENGINESTATUS" || alertName == "FUEL FILLING" || alertName == "FUEL DRAIN" || alertName == "NOTRANSMISSION") {
      return true
    }

    if (alertName == "TEMPERATURE1" || alertName == "TEMPERATURE2" || alertName == "TEMPERATURE3" || alertName == "TEMPERATURE4" || alertName == "BTTEMPERATURE1" || alertName == "BTTEMPERATURE2" || alertName == "BTTEMPERATURE3" || alertName == "BTTEMPERATURE4") {
      return true
    }
  }
  fuelTypeChecking(event) {
    if (event.detail.value == "Litre") {
      if (event.detail.checked) {
        this.litreCheck = true;
        this.percentageCheck = false;
      }
    } else if (event.detail.value == "Percentage") {
      if (event.detail.checked) {
        this.litreCheck = false;
        this.percentageCheck = true;
      }
    }
    if (this.mode == "edit") {
      if (this.litreCheck) {
        this.fuelLevel = this.selectedRow.Range;
        this.heartBeat = this.selectedRow["Interval (Minutes)"]
      }
      if (this.percentageCheck) {
        this.fuelLevel = Math.ceil(this.selectedRow.Range / this.maxfuelLevel * 100);
        this.heartBeat = Math.ceil(this.selectedRow["Interval (Minutes)"] / this.maxfuelLevel * 100);
      }
    }
    if (this.mode == "new") {
      if (this.litreCheck || this.percentageCheck) {
        this.fuelLevel = "";
        this.heartBeat = "";
      }
    }
  }
  configalert(geoBounds) {
    let tempAlert; let range;
    geoBounds = geoBounds != undefined ? geoBounds : { AlertType: "MOVEMENT", AlertColor: "#0f5601", description: "You will get alert when vehicle moves", icon: "" };
    let alt = (geoBounds.AlertType != undefined) ? geoBounds.AlertType : geoBounds.alertType;
    if (alt == "TEMPERATURE1" || alt == "TEMPERATURE2" || alt == "TEMPERATURE3" || alt == "TEMPERATURE4" || alt == "BTTEMPERATURE1" || alt == "BTTEMPERATURE2" || alt == "BTTEMPERATURE3" || alt == "BTTEMPERATURE4") {
      if (this.selectedTempAlert.length == 0) {
        this.commonService.presentToast('Please fill the required status');
        return null;
      }
    }

    range = this.ngRange === "outofrange" ? 1 : 0;


    let access = this.validatAlertsCong(alt);
    if (access) {
      var jsonMails = {};
      let alertdata = {};
      alertdata["companyId"] = localStorage.getItem('corpId');
      alertdata["branchId"] = localStorage.getItem('corpId');
      alertdata["userId"] = this.mode == "edit" || this.mode == "multiEdit" ? this.selectedRow["User"] : localStorage.getItem('userName');
      if (localStorage.getItem('companyRole') == "CompanyAdmin")
        alertdata["CompanyAdmin"] = "CompanyAdmin";
      else
        alertdata["CompanyAdmin"] = "NormalUser";

      alertdata["push"] = "1";
      this.check = true;
      if (this.startTime == "00:00" && this.endTime == "23:59")
        alertdata["time"] = "0:00_23:59";
      else
        alertdata["time"] = this.startTime + "_" + this.endTime;

      let platenumbers = this.myTree.getCheckedItems();
      alertdata["vehicleDatas"] = [];
      if (platenumbers.length != 0) {
        for (let i = 0; i < platenumbers.length; i++) {
          for (let j = 0; j < storageVariable.groupAndVin.length; j++) {
            if (storageVariable.groupAndVin[j].vin == platenumbers[i].value) {
              alertdata["vehicleDatas"].push({
                vin: platenumbers[i].value,
                plateNo: platenumbers[i].label
              });
            }
          }

        }
      } else {
        if (this.entryLogin === "ATM") {
          this.commonService.presentAlert("Error", "Please Select ATM ID");
          this.check = false;
          return "";
        }
        else {
          this.commonService.presentAlert("Error", "Please Select Plate Numbers");
          this.check = false;
          return "";
        }
      }

      if (this.mode == 'new') {
        if (this.selectedDays != "_#_#_#_#_#_#_")
          alertdata["days"] = this.selectedDays;
        else {
          this.commonService.presentAlert("Error", "Please Select Days");
          this.check = false;
          return "";
        }
        alertdata["alerttype"] = geoBounds == undefined ? "MOVEMENT" : geoBounds.AlertType;
        alertdata["validity"] = this.selectedEffectill == undefined ? "2" : this.selectedEffectill;
        alertdata["status"] = this.status == undefined ? "1" : this.status;

        if (this.checkentryval && this.mobilenumber) {
          var number = this.mobilenumber;
          if (number != "" && number != undefined) {
            if (number.includes(',')) {
              let phnums = number.split(",");
              if (phnums.length < 3) {
                let num = 0;
                if (phnums[0].length < 11 && phnums[1].replace(" ", "").length < 11) {
                  alertdata["mobile"] = phnums[0];
                  alertdata["mobile1"] = phnums[1];
                  alertdata["throughSMS"] = "1";
                  alertdata["throughSMS1"] = "1";
                } else {
                  this.commonService.presentAlert("Error", "Enter Max 10 Numbers");
                  this.check = false;
                  return "";
                }
              } else {
                this.commonService.presentAlert("Error", "Enter Only 2 Numbers");
                this.check = false;
                return "";
              }
            } else {
              if (number.length < 11) {
                alertdata["mobile"] = number;
                alertdata["throughSMS"] = "1";
                alertdata["throughSMS1"] = "0";
              } else {
                this.commonService.presentAlert("Error", "Enter Max 10 Numbers");
                this.check = false;
                return "";
              }
            }
          } else {
            alertdata["throughSMS"] = "0";
            alertdata["throughSMS1"] = "0";
          }
        } else {
          alertdata["mobile"] = "";
          alertdata["throughSMS"] = "0";
          alertdata["throughSMS1"] = "0";
        }


        if (this.checkentryval1 && this.mailaddress) {
          var mail = this.mailaddress;
          if (mail != "" && mail != undefined) {
            if (mail.includes(',')) {
              let mailids = mail.split(",");
              if (mailids.length < 3) {
                if (this.commonService.mailValidation.test(mailids[0])) {
                  jsonMails["email1"] = mailids[0];
                  alertdata["email"] = mailids[0];
                }
                else {
                  this.commonService.presentToast(this.commonService.mailerror)
                  this.check = false;
                }

                if (this.commonService.mailValidation.test(mailids[1])) {
                  jsonMails["email1"] = jsonMails["email1"] + "," + mailids[1]
                  alertdata["email1"] = mailids[1]
                }
                else {
                  this.commonService.presentToast(this.commonService.mailerror)
                  this.check = false;
                }

                alertdata["throughEmail"] = "1";
                alertdata["throughEmail1"] = "1";
              } else {
                this.commonService.presentAlert("Error", "Enter Only 2 Mail Id");
                this.check = false;
                return "";
              }
            } else {
              if (this.commonService.mailValidation.test(mail)) {
                jsonMails["email1"] = mail;
                alertdata["email"] = mail;
              }
              else {
                this.commonService.presentToast(this.commonService.mailerror)
                this.check = false;
                return "";
              }
              alertdata["throughEmail"] = "1";
              alertdata["throughEmail1"] = "0";
            }
          } else {
            alertdata["throughEmail"] = "0";
            alertdata["throughEmail1"] = "0";
          }

        } else {
          alertdata["email"] = "";
          alertdata["throughEmail"] = "0";
          alertdata["throughEmail1"] = "0";
        }


        if (geoBounds.AlertType == "WOKEUP" || geoBounds.AlertType == "SOS"
          || geoBounds.AlertType == "ENGINESTATUS" || geoBounds.AlertType == "HARSHACCELERATION"
          || geoBounds.AlertType == "PANIC" || geoBounds.AlertType == "HARSHBRAKING"
          || geoBounds.AlertType == "DRIFT" || geoBounds.AlertType == "FATIGUE"
          || geoBounds.AlertType == "PanelAlarms" || geoBounds.AlertType == "PreventiveMaintenance"
          || geoBounds.AlertType == "ScheduledMaintenance" || geoBounds.AlertType == "ANTITHEFT"
          || geoBounds.AlertType == "FUEL FILLING" || geoBounds.AlertType == "FUEL DRAIN" || geoBounds.AlertType == "NOTRANSMISSION") {
          alertdata["interval"] = "1#Minutes";
          alertdata["range"] = geoBounds.AlertType == "FATIGUE" ? this.altFreqHrs + "#" + this.fatigDueMns : "1";
        } else if (geoBounds.AlertType == "STOP" || geoBounds.AlertType == "IDLE" || geoBounds.AlertType == "TOWED") {
          alertdata["interval"] = this.interval + "#" + this.intervalvalues;
          alertdata["range"] = (this.limit * 60).toString();
        } else if (geoBounds.AlertType == "SEATBELT" || geoBounds.AlertType == "OVERSPEED" || geoBounds.AlertType == "POWERCUT" || geoBounds.AlertType == "LowBattery") {
          alertdata["interval"] = this.interval + "#" + this.intervalvalues;
          alertdata["range"] = this.limit;
          if (geoBounds.AlertType == "OVERSPEED")
            alertdata["SecondsInIgnore"] = this.secIning == "" ? "0" : this.secIning;
        } else if (geoBounds.AlertType == "DOOROPEN" || geoBounds.AlertType == "DOOROPEN BACK") {
          alertdata["interval"] = this.interval + "#" + this.intervalvalues;
          alertdata["range"] = this.limit;
          alertdata["SecondsInIgnore"] = this.secIningtemp == "" ? "0" : this.secIningtemp;
          if(this.isBzChecked){
            alertdata["subAlertType"] = "TEMPERATURESENSOR_BUZZER#"+ this.buzzerValue;
          }
        }
        else if (geoBounds.AlertType == "MOVEMENT" ) {
          alertdata["interval"] = this.interval + "#" + this.intervalvalues;
          alertdata["range"] = "30";
        } else if (geoBounds.AlertType == "OPTIME") {
          alertdata["interval"] = this.interval + "#" + this.intervalvalues;
          if (this.Time1 == "00:00" && this.Time2 == "23:59")
            alertdata["range"] = "0:00_23:59";
          else
            alertdata["range"] = this.Time1 + "_" + this.Time2;
        } else if (geoBounds.AlertType == "FUEL MAX THRESHOLD" || geoBounds.AlertType == "FUEL MIN THRESHOLD") {
          if (this.litreCheck) {
            alertdata["range"] = this.fuelLevel;
            alertdata["interval"] = this.heartBeat + "#Minutes";
          } else if (this.percentageCheck) {
            alertdata["range"] = this.maxfuelLevel * this.fuelLevel / 100;
            alertdata["interval"] = this.maxfuelLevel * this.heartBeat / 100 + "#Minutes";
          }

        }
        else if (alt == "TEMPERATURE1" || alt == "TEMPERATURE2" || alt == "TEMPERATURE3" || alt == "TEMPERATURE4" || alt == "BTTEMPERATURE1" || alt == "BTTEMPERATURE2" || alt == "BTTEMPERATURE3" || alt == "BTTEMPERATURE4") {

          alertdata["interval"] = this.interval + "#" + this.intervalvalues;
          alertdata["range"] = this.selectedTempAlert + "#" + range;
          alertdata["SecondsInIgnore"] = this.secIningtemp == "" ? "0" : this.secIningtemp;

          if(this.isBzChecked){
            alertdata["subAlertType"] = "TEMPERATURESENSOR_BUZZER#"+ this.buzzerValue;
          }
        }
      }
      if (this.mode == 'edit') {
        let alertType = this.selectedRow.alertType;
        if (alt == "TEMPERATURE1" || alt == "TEMPERATURE2" || alt == "TEMPERATURE3" || alt == "TEMPERATURE4" || alt == "BTTEMPERATURE1" || alt == "BTTEMPERATURE2" || alt == "BTTEMPERATURE3" || alt == "BTTEMPERATURE4") {
          if (this.selectedTempAlert.length == 0) {
            this.commonService.presentToast("Please fill the required form");
            return null;
          }
        }
        alertdata["alerttype"] = alertType;
        if (this.selectedDays == "") {
          let days = parseInt(this.selectedRow.Day);
          if (days / 64 >= 1) {
            days = days % 64;
            this.dayvalues.push("Sun");
          }
          if (days / 32 >= 1) {
            days = days % 32;
            this.dayvalues.push("Mon");
          }
          if (days / 16 >= 1) {
            days = days % 16;
            this.dayvalues.push("Tue");
          }
          if (days / 8 >= 1) {
            days = days % 8;
            this.dayvalues.push("Wed");
          }
          if (days / 4 >= 1) {
            days = days % 4;
            this.dayvalues.push("Thu");
          }
          if (days / 2 >= 1) {
            days = days % 2;
            this.dayvalues.push("Fri");
          }
          if (days == 1)
            this.dayvalues.push("Sat");


          this.selectedDays = "";
          let days1 = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
          let selectedDay = this.dayvalues;
          for (let cnt in days1) {
            let day = days1[cnt];
            let flag = false;
            for (let cnt2 in selectedDay) {
              if (day == selectedDay[cnt2])
                flag = true;
            }
            if (day != 'Sun')
              this.selectedDays += "#";

            if (flag)
              this.selectedDays += day;
            else
              this.selectedDays += "_";

          }
        }

        if (this.selectedDays != "_#_#_#_#_#_#_")
          alertdata["days"] = this.selectedDays;
        else {
          this.commonService.presentAlert("Error", "Please Select Days");
          this.check = false;
          return "";
        }
        alertdata["validity"] = this.selectedEffectill == undefined || this.selectedEffectill == "" ? this.selectedRow.Validity : this.selectedEffectill;
        alertdata["status"] = this.status == undefined || this.status == "" ? this.selectedRow.status == undefined ? "1" : "1" : this.status;

        if (this.startTime == "00:00" && this.endTime == "23:59")
          alertdata["time"] = "0:00_23:59";
        else
          alertdata["time"] = this.startTime + "_" + this.endTime;

        if (this.checkentryval && this.mobilenumber) {
          var number = this.mobilenumber;
          if (number != "" && number != undefined) {
            if (number.includes(',')) {
              let phnums = number.split(",");
              let num = 0;
              if (phnums.length < 3) {
                if (phnums[0].length < 11 && phnums[1].replace(" ", "").length < 11) {
                  alertdata["mobile"] = phnums[0];
                  alertdata["mobile1"] = phnums[1];
                  alertdata["throughSMS"] = "1";
                  alertdata["throughSMS1"] = "1";
                } else {
                  this.commonService.presentAlert("Error", "Enter Max 10 Numbers");
                  this.check = false;
                  return "";
                }
              } else {
                this.commonService.presentAlert("Error", "Enter Max 2 Numbers");
                this.check = false;
                return "";
              }
            } else {
              if (number.length < 11) {
                alertdata["mobile"] = number;
                alertdata["throughSMS"] = "1";
                alertdata["throughSMS1"] = "0";
              } else {
                this.commonService.presentAlert("Error", "Enter Max 10 Numbers");
                this.check = false;
                return "";
              }
            }
          } else {
            alertdata["throughSMS"] = "0";
            alertdata["throughSMS1"] = "0";
          }
        } else {
          alertdata["mobile"] = "";
          alertdata["throughSMS"] = "0";
          alertdata["throughSMS1"] = "0";
        }


        if (this.checkentryval1 && this.mailaddress) {
          var mail = this.mailaddress;
          if (mail != "" && mail != undefined) {
            if (mail.includes(',')) {
              let mailids = mail.split(",");
              if (mailids.length < 3) {
                if (this.commonService.mailValidation.test(mailids[0])) {
                  jsonMails["email1"] = mailids[0]
                  alertdata["email"] = mailids[0];
                }
                else {
                  this.commonService.presentToast(this.commonService.mailerror)
                  this.check = false;
                  return "";
                }
                if (this.commonService.mailValidation.test(mailids[1])) {
                  jsonMails["email1"] = jsonMails["email1"] + "," + mailids[1]
                  alertdata["email1"] = mailids[1]
                }
                else {
                  this.commonService.presentToast(this.commonService.mailerror)
                  this.check = false;
                  return "";
                }
                alertdata["throughEmail"] = "1";
                alertdata["throughEmail1"] = "1";
              } else {
                this.commonService.presentAlert("Error", "Enter Max 2 Mail Id");
                this.check = false;
                return "";
              }
            } else {
              if (this.commonService.mailValidation.test(mail)) {
                alertdata["email"] = mail;
                jsonMails["email1"] = mail;
              }
              else {
                this.commonService.presentToast(this.commonService.mailerror)
                this.check = false;
                return "";
              }
              alertdata["throughEmail"] = "1";
              alertdata["throughEmail1"] = "0";
            }
          } else {
            alertdata["throughEmail"] = "0";
            alertdata["throughEmail1"] = "0";
          }
        } else {
          alertdata["email"] = "";
          alertdata["throughEmail"] = "0";
          alertdata["throughEmail1"] = "0";
        }


        if (alertType == "WOKEUP" || alertType == "SOS"
          || alertType == "ENGINESTATUS" || alertType == "HARSHACCELERATION"
          || alertType == "PANIC" || alertType == "HARSHBRAKING"
          || alertType == "DRIFT" || alertType == "FATIGUE"
          || alertType == "PanelAlarms" || alertType == "PreventiveMaintenance"
          || alertType == "ScheduledMaintenance" || alertType == "ANTITHEFT"
          || alertType == "FUEL FILLING" || alertType == "FUEL DRAIN" || alertType == "NOTRANSMISSION") {
          alertdata["interval"] = "1#Minutes";
          if (alertType == "FATIGUE")
            alertdata["range"] = this.altFreqHrs + "#" + this.fatigDueMns;
          else
            alertdata["range"] = "1";
        } else if (alertType == "STOP"
          || alertType == "IDLE" || alertType == "TOWED") {
          alertdata["interval"] = this.interval + "#" + this.intervalvalues;
          alertdata["range"] = (this.limit * 60).toString();
        } else if (alertType == "SEATBELT" || alertType == "OVERSPEED" || alertType == "POWERCUT" || alertType == "LowBattery") {
          alertdata["interval"] = this.interval + "#" + this.intervalvalues;
          alertdata["range"] = this.limit;
          if (alertType == "OVERSPEED")
            alertdata["SecondsInIgnore"] = this.secIning == "" ? (this.selectedRow.SecondsInIgnore != "" ? this.selectedRow.SecondsInIgnore.toString() : "0") : this.secIning;
        } else if (alertType == "DOOROPEN" || alertType == "DOOROPEN BACK") {
          alertdata["interval"] = this.interval + "#" + this.intervalvalues;
          alertdata["range"] = this.limit;
          alertdata["SecondsInIgnore"] = this.secIningtemp == "" ? (this.selectedRow.SecondsInIgnore != "" ? this.selectedRow.SecondsInIgnore.toString() : "0") : this.secIningtemp;
          if(this.isBzChecked){
            alertdata["subAlertType"] = "TEMPERATURESENSOR_BUZZER#"+ this.buzzerValue;
          }
        }
        else if (alertType == "MOVEMENT" ) {
          alertdata["interval"] = this.interval + "#" + this.intervalvalues;
          alertdata["range"] = "30";
        } else if (alertType == "OPTIME") {
          alertdata["interval"] = this.interval + "#" + this.intervalvalues;
          if (this.Time1 == "00:00" && this.Time2 == "23:59")
            alertdata["range"] = "0:00_23:59";
          else
            alertdata["range"] = this.Time1 + "_" + this.Time2;
        }
        else if (alertType == "FUEL MAX THRESHOLD" || alertType == "FUEL MIN THRESHOLD") {
          if (this.litreCheck) {
            alertdata["range"] = this.fuelLevel;
            alertdata["interval"] = this.heartBeat + "#Minutes";
          } else if (this.percentageCheck) {
            alertdata["range"] = this.maxfuelLevel * this.fuelLevel / 100;
            alertdata["interval"] = this.maxfuelLevel * this.heartBeat / 100 + "#Minutes";
          }

        }
        else if (alertType == "TEMPERATURE1" || alertType == "TEMPERATURE2" || alertType == "TEMPERATURE3" || alertType == "TEMPERATURE4" || alt == "BTTEMPERATURE1" || alt == "BTTEMPERATURE2" || alt == "BTTEMPERATURE3" || alt == "BTTEMPERATURE4") {

          alertdata["interval"] = this.interval + "#" + this.intervalvalues;
          alertdata["range"] = this.selectedTempAlert + "#" + range;
          alertdata["SecondsInIgnore"] = this.secIningtemp == "" ? (this.selectedRow.SecondsInIgnore != "" ? this.selectedRow.SecondsInIgnore.toString() : "0") : this.secIningtemp;
          if(this.isBzChecked){
            alertdata["subAlertType"] = "TEMPERATURESENSOR_BUZZER#"+this.buzzerValue;
          }
        }
       this.selectedRow.Status = this.selectedRow.Status == "Subscribed" ? "1" : "0";

        var oldValue = {}

        oldValue["days"] = this.formDayData(this.selectedRow.Day) == alertdata["days"] ? undefined : this.formDayData(this.selectedRow.Day);
        oldValue["SecondsInIgnore"] = undefined;
        oldValue["mobile"] = undefined;
        oldValue["email"] = undefined;
        oldValue["email1"] = undefined;

        if (this.selectedRow['Mobile Number'].includes("#")) {
          if (this.selectedRow['Mobile Number'].split("#")[0] != alertdata["mobile"])
            oldValue["mobile"] = this.selectedRow['Mobile Number'].split("#")[0];
        } else
          if (this.selectedRow['Mobile Number'] != alertdata["mobile"] && this.selectedRow['Mobile Number'] != "")
            oldValue["mobile"] = this.selectedRow['Mobile Number']

        if (this.selectedRow['Mobile Number'].includes("#"))
          if (this.selectedRow['Mobile Number'].split("#")[1] != alertdata["mobile1"])
            oldValue["mobile1"] = this.selectedRow['Mobile Number'].split("#")[1];

        if (this.selectedRow['Email Address'].includes("#")) {
          if (this.selectedRow['Email Address'].split("#")[0] != alertdata["email"])
            oldValue["email"] = this.selectedRow['Email Address'].split("#")[0];
        } else
          if (this.selectedRow['Email Address'] != alertdata["email"] && this.selectedRow['Email Address'] != "")
            oldValue["email"] = this.selectedRow['Email Address']

        if (this.selectedRow['Email Address'].includes("#"))
          if (this.selectedRow['Email Address'].split("#")[1] != alertdata["email1"])
            oldValue["email1"] = this.selectedRow['Email Address'].split("#")[1];

        if (this.selectedRow.alertType == "OVERSPEED" || this.selectedRow.alertType == "TEMPERATURE1" || this.selectedRow.alertType == "TEMPERATURE2" || this.selectedRow.alertType == "TEMPERATURE3" || this.selectedRow.alertType == "TEMPERATURE4" || this.selectedRow.alertType == "BTTEMPERATURE1" || this.selectedRow.alertType == "BTTEMPERATURE2" || this.selectedRow.alertType == "BTTEMPERATURE3" || this.selectedRow.alertType == "BTTEMPERATURE4" || this.selectedRow.alertType == "DOOROPEN" ||  this.selectedRow.alertType == "DOOROPEN BACK")
          if (this.selectedRow.SecondsInIgnore != alertdata["SecondsInIgnore"])
            oldValue["SecondsInIgnore"] = this.selectedRow.SecondsInIgnore;



        oldValue["validity"] = this.selectedRow.Validity == alertdata["validity"] ? undefined : this.selectedRow.Validity;
        oldValue["status"] = this.selectedRow.Status == alertdata["status"] ? undefined : this.selectedRow.Status;
        oldValue["time"] = this.selectedRow.Time == alertdata["time"] ? undefined : this.selectedRow.Time;
        oldValue["interval"] = this.selectedRow["Interval (Minutes)"] == (alertdata["interval"].split("#")[0]) ? alertdata["interval"].split("#")[0] : this.selectedRow["Interval (Minutes)"];
        oldValue["range"] = this.selectedRow.Range == alertdata["range"] ? undefined : this.selectedRow.Range;
        oldValue["macIp"] = "";

        if(this.selectedRow["subAlertType"]){
          if(this.buzzerValue != this.selectedRow["subAlertType"] || !this.isBzChecked)
              oldValue["subAlertType"] = this.selectedRow["subAlertType"]
        }
        alertdata["loginUser"] = localStorage.userName;
        alertdata["oldValue"] = JSON.stringify(oldValue);
      }
      jsonMails["data"] = localStorage.corpId
      if (Object.keys(jsonMails).includes("email1")) {
        let serviceData = JSON.stringify(jsonMails);
        let url: string = serverUrl.web + "/alert/emailvalidation/" + serviceData;
        this.commonService.presentLoader();
        this.ajaxService.ajaxGetWithString(url)
          .subscribe(res => {
            var json = JSON.parse(res);
            this.commonService.dismissLoader();
            if (!Object.values(json).includes(false)) {
              if (this.check == true) {
                this.savealert(alertdata, this.mode);
              } else {
                this.commonService.presentToast("failed");
              }
            }

            else
              this.commonService.presentToast(this.commonService.mailerror)

          })
      } else {
        this.savealert(alertdata, this.mode);
      }


    } else {
      this.commonService.presentToast("Please fill the required form")
    }

  }

  multiconfigalt(geoBounds) {
    geoBounds = geoBounds != undefined ? geoBounds : { AlertType: "MOVEMENT", AlertColor: "#0f5601", description: "You will get alert when vehicle moves", icon: "" };
    let tempAlert; let rangeAlert;
    let platenum = this.myTree.getCheckedItems();
    var platenumber = [];
    for (let pno in platenum) {
      if (platenum[pno]['parentId'] != 0)
        platenumber.push(platenum[pno].value)
    }
    let boardData = this.tabledata;
    var alerbatchdata = [];
    for (let alt in platenumber) {
      for (let alt2 in boardData) {
        if (platenumber[alt] == boardData[alt2]['Vehicle Details'] && geoBounds.AlertType == boardData[alt2].alertType) {
          let alertdata = {};
          alertdata["companyId"] = localStorage.getItem('corpId');
          alertdata["branchId"] = localStorage.getItem('corpId');
          alertdata["userId"] = boardData[alt2]["User"];
          if (localStorage.getItem('companyRole') == "CompanyAdmin")
            alertdata["CompanyAdmin"] = "CompanyAdmin";
          else
            alertdata["CompanyAdmin"] = "NormalUser";

          alertdata["push"] = "1";
          this.check = true;
          alertdata["vehicleDatas"] = [];
          alertdata["vehicleDatas"].push({
            vin: boardData[alt2]['Vehicle Details'],
            plateNo: boardData[alt2]['Plate Number']
          });
         let alertType = boardData[alt2].alertType;
          alertdata["alerttype"] = alertType;

          let a;
          if (this.selectedWeekDays.length == 0) {
            this.selectedDays = ""
            a = boardData[alt2].Day;
            this.formDayData(a)
          }
          else {
            this.onChangedays({ target: { value: this.selectedWeekDays } })
          }

          if (boardData[alt2].Day == "127" && this.selectedDays == "Sun#Mon#Tue#Wed#Thu#Fri#Sat")
            alertdata["days"] = "Sun#Mon#Tue#Wed#Thu#Fri#Sat";
          else {
            if (this.selectedDays == "") {
              this.dayvalues = [];
              let days = parseInt(boardData[alt2].Day == true ? "1" : boardData[alt2].Day);
              if (days / 64 >= 1) {
                days = days % 64;
                this.dayvalues.push("Sun");
              }
              if (days / 32 >= 1) {
                days = days % 32;
                this.dayvalues.push("Mon");
              }
              if (days / 16 >= 1) {
                days = days % 16;
                this.dayvalues.push("Tue");
              }
              if (days / 8 >= 1) {
                days = days % 8;
                this.dayvalues.push("Wed");
              }
              if (days / 4 >= 1) {
                days = days % 4;
                this.dayvalues.push("Thu");
              }
              if (days / 2 >= 1) {
                days = days % 2;
                this.dayvalues.push("Fri");
              }
              if (days == 1)
                this.dayvalues.push("Sat");

              this.selectedDays1 = "";
              let days1 = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
              let selectedDay = this.dayvalues;
              for (let cnt in days1) {
                let day = days1[cnt];
                let flag = false;
                for (let cnt2 in selectedDay) {
                  if (day == selectedDay[cnt2])
                    flag = true;
                }
                if (day != 'Sun')
                  this.selectedDays1 += "#";

                if (flag)
                  this.selectedDays1 += day;
                else
                  this.selectedDays1 += "_";
              }
              alertdata["days"] = this.selectedDays1;
            } else {
              alertdata["days"] = this.selectedDays;
            }
          }
          alertdata["validity"] = this.selectedEffectill == undefined || this.selectedEffectill == "" ? boardData[alt2].Validity : this.selectedEffectill;
          alertdata["status"] = this.status == undefined || this.status == "" ? boardData[alt2].Status == "Unsubscribed" ? "0" : "1" : this.status;
          let time = boardData[alt2].Time.split("_");
          if (boardData[alt2].Time == "0:00_23:59" && this.startTime == "00:00" && this.endTime == "23:59") {
            alertdata["time"] = "0:00_23:59";
          } else {
            if (this.startTime != "00:00" || this.endTime != "23:59") {
              if (this.startTime != "00:00" && this.endTime == "23:59")
                alertdata["time"] = this.startTime + "_" + time[1];
              else if (this.startTime == "00:00" && this.endTime != "23:59")
                alertdata["time"] = time[0] + "_" + this.endTime;
              else
                alertdata["time"] = this.startTime + "_" + this.endTime;
            } else
              alertdata["time"] = boardData[alt2].Time;
          }


          var number = this.mobilenumber == "" ? boardData[alt2]['Mobile Number'] : this.mobilenumber;
          if (number != "" && number != undefined) {
            if (number.includes(',')) {
              let phnums = number.split(",");
              let num = 0;
              if (phnums.length < 3) {
                if (phnums[0].length < 11 && phnums[1].replace(" ", "").length < 11) {
                  alertdata["mobile"] = phnums[0];
                  alertdata["mobile1"] = phnums[1];
                  alertdata["throughSMS"] = "1";
                  alertdata["throughSMS1"] = "1";
                } else {
                  this.commonService.presentAlert("Error", "Enter Max 10 Numbers");
                  this.check = false;
                  return "";
                }
              } else {
                this.commonService.presentAlert("Error", "Enter Max 2 Numbers");
                this.check = false;
                return "";
              }
            } else {
              if (number.length < 11) {
                alertdata["mobile"] = number;
                alertdata["throughSMS"] = "1";
                alertdata["throughSMS1"] = "0";
              } else {
                this.commonService.presentAlert("Error", "Enter Max 10 Numbers");
                this.check = false;
                return "";
              }
            }
          } else {
            alertdata["throughSMS"] = "0";
            alertdata["throughSMS1"] = "0";
          }

          var mail = this.mailaddress == "" ? boardData[alt2]['Email Address'] : this.mailaddress;
          if (mail != "" && mail != undefined) {
            if (mail.includes(',')) {
              let mailids = mail.split(",");
              if (mailids.length < 3) {
                if (this.commonService.mailValidation.test(mailids[0]))
                  alertdata["email"] = mailids[0];
                else
                  this.commonService.presentToast(this.commonService.mailerror)
                if (this.commonService.mailValidation.test(mailids[1]))
                  alertdata["email1"] = mailids[1]
                else
                  this.commonService.presentToast(this.commonService.mailerror)

                alertdata["throughEmail"] = "1";
                alertdata["throughEmail1"] = "1";
              } else {
                this.commonService.presentAlert("Error", "Enter Max 2 Mail Id");
                this.check = false;
                return "";
              }
            } else {
              if (this.commonService.mailValidation.test(mail))
                alertdata["email"] = mail;
              else
                this.commonService.presentToast(this.commonService.mailerror)
              alertdata["throughEmail"] = "1";
              alertdata["throughEmail1"] = "0";
            }
          } else {
            alertdata["throughEmail"] = "0";
            alertdata["throughEmail1"] = "0";
          }
          if (alertType == "WOKEUP" || alertType == "SOS"
            || alertType == "ENGINESTATUS"
            || alertType == "PANIC" || alertType == "HARSHBRAKING"
            || alertType == "DRIFT" || alertType == "FATIGUE"
            || alertType == "PanelAlarms" || alertType == "PreventiveMaintenance" || alertType == "NOTRANSMISSION"
            || alertType == "ScheduledMaintenance" || alertType == "ANTITHEFT" || alertType == "FUEL FILLING" || alertType == "FUEL DRAIN") {
            alertdata["interval"] = "1#Minutes";
            if (alertType == "FATIGUE") {
              let fatirange = boardData[alt2].Range.split("#");
              let hrs = this.altFreqHrs ? this.altFreqHrs : fatirange[0];
              let mns = this.fatigDueMns ? this.fatigDueMns : fatirange[1];
              alertdata["range"] = hrs + "#" + mns;
            }
            else
              alertdata["range"] = "1";
          } else if (alertType == "STOP" || alertType == "IDLE" || alertType == "TOWED") {
            alertdata["interval"] = (this.interval ? this.interval : boardData[alt2]['Interval (Minutes)']) + "#" + this.intervalvalues;
            alertdata["range"] = this.limit ? (this.limit * 60).toString() : boardData[alt2].Range;
          }

          else if (alertType == "SEATBELT") {
            alertdata["interval"] = (this.interval == "" || this.interval == null || this.interval == undefined) ? (boardData[alt2]['Interval (Minutes)'] + "#" + this.intervalvalues) : (this.interval + "#" + this.intervalvalues);
            alertdata["range"] = (this.limit == undefined || this.limit == null || this.limit == "") ? boardData[alt2]['Range'] : this.limit;
          }
          else if (alertType == "DOOROPEN" || alertType == "DOOROPEN BACK") {
            alertdata["interval"] = (this.interval == "" || this.interval == null || this.interval == undefined) ? (boardData[alt2]['Interval (Minutes)'] + "#" + this.intervalvalues) : (this.interval + "#" + this.intervalvalues);
            alertdata["range"] = (this.limit == undefined || this.limit == null || this.limit == "") ? boardData[alt2]['Range'] : this.limit;
            alertdata["SecondsInIgnore"] = (this.secIningtemp == "" || this.secIningtemp == null || this.secIningtemp == undefined) ? boardData[alt2].SecondsInIgnore.toString() : this.secIningtemp;
            if(this.isBzChecked){
              alertdata["subAlertType"] = (this.buzzerValue == "" || this.buzzerValue == undefined ) ? "5" :this.buzzerValue
            }
          }
          else if (alertType == "POWERCUT" || alertType == "MOVEMENT" || alertType == "LowBattery" ) {
            alertdata["interval"] = (this.interval == "" || this.interval == null || this.interval == undefined) ? (boardData[alt2]['Interval (Minutes)'] + "#" + this.intervalvalues) : (this.interval + "#" + this.intervalvalues);
            alertdata["range"] = boardData[alt2]['Range'];
          }
          else if (alertType == "OVERSPEED" || alertType == "TEMPERATURE1" || alertType == "TEMPERATURE2" || alertType == "TEMPERATURE3" || alertType == "TEMPERATURE4" || alertType == "BTTEMPERATURE1" || alertType == "BTTEMPERATURE2" || alertType == "BTTEMPERATURE3" || alertType == "BTTEMPERATURE4") {
            alertdata["interval"] = (this.interval == "" || this.interval == null || this.interval == undefined) ? (boardData[alt2]['Interval (Minutes)'] + "#" + this.intervalvalues) : (this.interval + "#" + this.intervalvalues);
            alertdata["range"] = (this.limit == undefined || this.limit == null || this.limit == "") ? boardData[alt2]['Range'] : this.limit;
            alertdata["SecondsInIgnore"] = (this.secIningtemp == "" || this.secIningtemp == null || this.secIningtemp == undefined) ? boardData[alt2].SecondsInIgnore.toString() : this.secIningtemp;
         
            if(this.isBzChecked){
              alertdata["subAlertType"] = (this.buzzerValue == "" || this.buzzerValue == undefined ) ? "5" :this.buzzerValue
            }
         
          }

          // may 3 old code alert multiedit start

          // else if (alertType == "SEATBELT" || alertType == "OVERSPEED" || alertType == "POWERCUT" || alertType == "LowBattery") {
          //   if (this.interval != undefined)
          //     alertdata["interval"] = this.interval + "#" + this.intervalvalues;
          //   else
          //     alertdata["interval"] = boardData[alt2]['Interval (Minutes)'] + "#" + this.intervalvalues;
          //   alertdata["range"] = this.limit ? this.limit : boardData[alt2].Range;
          //   if (alertType == "OVERSPEED")
          //     alertdata["SecondsInIgnore"] = this.secIning ? this.secIning : boardData[alt2].SecondsInIgnore.toString();
          // } 
          // else if (alertType == "MOVEMENT") {
          //   alertdata["interval"] = this.interval + "#" + this.intervalvalues;
          //   alertdata["range"] = "30";
          // } 

          // may 3 old code alert multiedit start

          else if (alertType == "OPTIME") {
            if (this.interval != undefined)
              alertdata["interval"] = this.interval + "#" + this.intervalvalues;
            else
              alertdata["interval"] = boardData[alt2]['Interval (Minutes)'] + "#" + this.intervalvalues;

            if (boardData[alt2].Range == "0:00_23:59" && this.Time1 == "00:00" && this.Time2 == "23:59")
              alertdata["range"] = "0:00_23:59";
            else {
              let rg = boardData[alt2].Range.split("_");
              if (this.Time1 != "00:00" || this.Time2 != "23:59") {
                if (this.Time1 != "00:00" && this.Time2 == "23:59")
                  alertdata["range"] = this.Time1 + "_" + rg[1];
                else if (this.Time1 == "00:00" && this.Time2 != "23:59")
                  alertdata["range"] = rg[0] + "_" + this.Time2;
                else
                  alertdata["range"] = this.Time1 + "_" + this.Time2;
              } else
                alertdata["range"] = boardData[alt2].Range;
            }
          } else if (alertType == "NonWorkingHours") {
            if (this.interval != undefined)
              alertdata["interval"] = this.interval + "#" + this.intervalvalues;
            else
              alertdata["interval"] = boardData[alt2]['Interval (Minutes)'] + "#" + this.intervalvalues;
            if (this.limit != undefined)
              alertdata["range"] = this.limit;
            else
              alertdata["range"] = this.limit ? this.limit : boardData[alt2].Range;
          }
          else if (alertType == "TEMPERATURE1" || alertType == "TEMPERATURE2" || alertType == "TEMPERATURE3" || alertType == "TEMPERATURE4" || alertType == "BTTEMPERATURE1" || alertType == "BTTEMPERATURE2" || alertType == "BTTEMPERATURE3" || alertType == "BTTEMPERATURE4") {
            if (this.interval == "" || this.interval == undefined) {
              alertdata["interval"] = boardData[alt2]['Interval (Minutes)'] + "#" + this.intervalvalues;
            }
            else {
              alertdata["interval"] = this.interval + "#" + this.intervalvalues;
            }

            
            let temprange = boardData[alt2].Range.split("#");
            let temp0 = [];
            if (this.selectedTempAlert.length == 0) {
              temp0 = temprange[0].split(",");
            }
            else {
              temp0 = this.selectedTempAlert;
            }
            let temp1;
            if (this.ngRange == "" || this.ngRange == undefined) {
              temp1 = temprange[1];
            }
            else {
              temp1 = this.ngRange == "outofrange" ? 1 : 0;
            }
            alertdata["range"] = temp0 + '#' + temp1;
          }






          var oldValue = {};
          oldValue["macIp"] = "";


          if (this.selectedDays)
            oldValue["Days"] = this.formDayData(boardData[alt2].Day);

          if (this.mailaddress)
            oldValue["email"] = boardData[alt2]['Email Address'];

          if (this.mobilenumber)
            oldValue["mobile"] = boardData[alt2]['Mobile Number'];

          if (this.selectedEffectill)
            oldValue["validity"] = boardData[alt2].Validity;

          if (this.status)
            oldValue["status"] = boardData[alt2].Status;

          if (this.startTime != "00:00" || this.endTime != "23:59")
            oldValue["time"] = boardData[alt2].Time

          if (alertdata["range"] != boardData[alt2].Range)
            oldValue["range"] = boardData[alt2].Range

          if (alertdata["interval"] != boardData[alt2]['Interval (Minutes)'] + "#" + this.intervalvalues)
            oldValue["interval"] = boardData[alt2]['Interval (Minutes)']

          if (alertType == "OVERSPEED" || alertType == "TEMPERATURE1" || alertType == "TEMPERATURE2" || alertType == "TEMPERATURE3" || alertType == "TEMPERATURE4" || alertType == "BTTEMPERATURE1" || alertType == "BTTEMPERATURE2" || alertType == "BTTEMPERATURE3" || alertType == "BTTEMPERATURE4")
            if (alertdata["SecondsInIgnore"] != boardData[alt2].SecondsInIgnore.toString())
              oldValue["SecondsInIgnore"] = boardData[alt2].SecondsInIgnore.toString();

              oldValue["subAlertType"] = boardData[alt2]["subAlertType"] == undefined ? "" : boardData[alt2]["subAlertType"]
          alertdata["oldValue"] = JSON.stringify(oldValue);
          alertdata["loginUser"] = localStorage.userName;

          alerbatchdata.push(JSON.stringify(alertdata));
        }
      }
    }
    // this.modalController.dismiss();
    this.savealert(alerbatchdata, "multi");
  }



  formDayData(DayValue) {
    this.dayvalues = [""];
    let days = parseInt(DayValue);
    if (days / 64 >= 1) {
      days = days % 64;
      this.dayvalues.push("Sun");
    }
    if (days / 32 >= 1) {
      days = days % 32;
      this.dayvalues.push("Mon");
    }
    if (days / 16 >= 1) {
      days = days % 16;
      this.dayvalues.push("Tue");
    }
    if (days / 8 >= 1) {
      days = days % 8;
      this.dayvalues.push("Wed");
    }
    if (days / 4 >= 1) {
      days = days % 4;
      this.dayvalues.push("Thu");
    }
    if (days / 2 >= 1) {
      days = days % 2;
      this.dayvalues.push("Fri");
    }
    if (days == 1)
      this.dayvalues.push("Sat");


    this.selectedDays = "";
    let days1 = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    let selectedDay = this.dayvalues;
    for (let cnt in days1) {
      let day = days1[cnt];
      let flag = false;
      for (let cnt2 in selectedDay) {
        if (day == selectedDay[cnt2])
          flag = true;
      }
      if (day != 'Sun')
        this.selectedDays += "#";

      if (flag)
        this.selectedDays += day;
      else
        this.selectedDays += "_";

    }

    return this.selectedDays;
  }



  configalerts(vindata, method) {
    console.log("vindata", vindata);
    setTimeout(() => {
      var json = {};
      json["method"] = method;
      json["companyId"] = localStorage.corpId;
      json["multivin"] = true;
      json["userId"] = localStorage.userName
      if (vindata.length > 1) {
        let vin = ''
        for (let i = 0; i < vindata.length; i++) {
          if (vindata.length - 1 == i)
            vin += "\'" + vindata[i].vin + "\'"
          else
            vin += "\'" + vindata[i].vin + "\',"
        }
        json["vin"] = vin;
      } else {
        json["vin"] = "\'" + vindata[0].vin + "\'";
      }
      const url = serverUrl.Admin + '/api/alerts/config';
      this.ajaxService.ajaxPostWithString(url, json)
        .subscribe(res => {
        }, err => {
          this.commonService.presentToast("failed")
        });
    }, 3000);

  }

  savealert(alertdata, mode) {
    console.log("alertdata", alertdata);

    var jsonvalue = {};
    if (mode != "multi") {
      var json = JSON.stringify(alertdata);
      jsonvalue["mode"] = mode;
      jsonvalue["addalertJson"] = json;
    } else {
      if (alertdata.length == 0) {
        return this.commonService.presentToast("please select valid data")
      }
      jsonvalue["multi"] = alertdata;
    }

    const url = serverUrl.web + '/Alert/add';
    this.ajaxService.ajaxPostWithString(url, jsonvalue)
      .subscribe(res => {
        console.log("res",res);
        if (res == "Alerts Already Exits,Not persisted") {
          this.commonService.presentToast("this Alert is Already Exits");
          return null;
        } else {
          this.modalController.dismiss();
          if(mode == "multi")
          this.commonService.presentToast("successfully Updated")
          else if(mode == "edit")
          this.commonService.presentToast("successfully Updated")
          else if(mode == "new")
          this.commonService.presentToast("successfully added")
          let method = "alertconfigCacheByMultiVins";
          if (Array.isArray(alertdata)) {
            let b = []
            alertdata.forEach((el: any) => {
              b.push(
                { "vin": JSON.parse(el)['vehicleDatas'][0].vin }
              )
            })
           this.configalerts(b, method);
          } else {
            this.configalerts(alertdata["vehicleDatas"], method);
          }

        }
      }, err => {
        this.modalController.dismiss()
        this.commonService.presentToast("failed")
      });
  }

  async deletemulticonfigalt(geoBounds) {
    geoBounds = geoBounds != undefined ? geoBounds : { AlertType: "MOVEMENT", AlertColor: "#0f5601", description: "You will get alert when vehicle moves", icon: "" };
    let platenum = this.myTree.getCheckedItems();
    var platenumber = "";
    let alerttype = geoBounds.AlertType;
    for (let pno in platenum) {
      if (platenum[pno]['parentId'] != 0) {
        if (platenumber == "")
          platenumber = platenum[pno].value;
        else
          platenumber = platenumber + "," + platenum[pno].value
      }
    }
    let platearay: any = platenumber.split(",")
    
    let finalPlate = "";
    let finalVin = "";
    let dataConfig: any = []
    for (let i = 0; i < platearay.length; i++) {
      for (let j = 0; j < storageVariable.groupAndVin.length; j++) {
        if (platearay[i] == storageVariable.groupAndVin[j].vin) {
          //   finalPlate.push(storageVariable.groupAndVin[j].plateNo);
          finalPlate += storageVariable.groupAndVin[j].plateNo + ",";
          // finalVin.push(storageVariable.groupAndVin[j].vin);
          finalVin += storageVariable.groupAndVin[j].vin + ",";

          dataConfig.push({ vin: storageVariable.groupAndVin[j].vin, plateNo: storageVariable.groupAndVin[j].plateNo })

        }
      }
    }
    if (platenumber) {
      const alert = await this.alertController.create({
        header: this.trans.instant('Are you sure?'),
        backdropDismiss: false,
        message: this.trans.instant("Do you want to delete your Record?. If you delete it, your changes will be lost."),
        buttons: [{
          text: this.trans.instant('Cancel'),
          role: 'cancel',
          handler: data => {
          }
        },
        {
          text: this.trans.instant('Ok'),
          handler: datas => {
            let data = {};
            data["alertType"] = alerttype;
            data["alertVin"] = finalVin;
            data["companyId"] = localStorage.getItem('corpId');
            data["branchId"] = localStorage.getItem('corpId');
            data["userId"] = localStorage.getItem('userName');
            data["companyRole"] = localStorage.getItem('companyRole');
            data["loginUser"] = localStorage.getItem('userName');
            data["plateNo"] = finalPlate;
            data["macIp"] = "";



            const url = serverUrl.web + '/Alert/delete';
            this.ajaxService.ajaxPostWithString(url, data)
              .subscribe(res => {
                this.modalController.dismiss()
                let method = "alertconfigCacheByMultiVins";
                this.configalerts(dataConfig, method);
                this.commonService.presentToast("successfully deleted")
              }, err => {
                this.modalController.dismiss()
                this.commonService.presentToast("Failed")
              });
          }
        }]
      });
      await alert.present();
    }
    else {
      this.commonService.presentToast('Please Select a Row to Delete');
      return "";
    }
  }

  searchgroup() {
    const searchedValue = this.Search.trim().toLowerCase();

    let data;
    if (this.myTree != undefined) {
        data = this.myTree.getItems();
    } else {
        return;
    }

    let foundItem = [];
    let expandedParents = new Set(); 

    data.forEach(res => {
        const labelText = res.label.toLowerCase();
        const textDiv = res.titleElement[0]; 

        textDiv.querySelectorAll('.highlightedText').forEach(span => {
            span.outerHTML = span.innerText;
        });

        if (searchedValue !== '' && labelText.includes(searchedValue)) {
            foundItem.push(res.element);
            this.myTree.expandItem(res.parentElement);
            expandedParents.add(res.parentElement); 

            textDiv.innerHTML = textDiv.innerHTML.replace(
                new RegExp(searchedValue, 'gi'),
                match => `<span class="highlightedText" style="font-weight: bold;">${match}</span>`
            );
        }
    });

  
      // data.forEach(res => {
      //     if (!expandedParents.has(res.parentElement) && !foundItem.includes(res.parentElement) && res.label != "Select all") {
      //             this.myTree.collapseItem(res.parentElement);
      //     }
      // });
    

    

  if (foundItem.length > 0) {
    if(foundItem[0].id != -1){
      this.myTree.selectItem(foundItem[0]);
      this.myTree.ensureVisible(foundItem[0]);
    }
  }

  if (searchedValue === '') {
    for (const res of data) {
        foundItem = res; 
        this.myTree.expandAll();
        this.myTree.selectItem(null);
        this.myTree.ensureVisible(res.element);
        break;
    }
}
}


  alertFreqHours(hr) {
    this.altFreqHrs = hr.detail.value
  }
  fatigDurationMinutes(mns) {
    this.fatigDueMns = mns.detail.value
  }
  forGettingCapacity(event) {
   if(event.length == 0) this.maxfuelLevel = "";
   if(event.length == 1) this.vehicleCache = event;
   console.log("this.vehicleCache",this.vehicleCache);
  if ((this.checkevent == "FUEL MAX THRESHOLD" || this.checkevent == "FUEL MIN THRESHOLD")){
    this.selectcard(this.checkevents)
   }
  }
  typingEvent(event) {

    if (!this.maxfuelLevel) {
      this.commonService.presentToast("Please select the Plate No");
      this.fuelLevel = ""
      return "";
    }
    if (this.litreCheck) {
      if (Number(event.target.value) > Number(this.maxfuelLevel)) {
        this.commonService.presentToast(`Please type value less than ${this.maxfuelLevel}`);
        this.fuelLevel = "";
      }
    }

  }
  ngOnInit() {
    this.litreCheck = true
    console.log("this.selectedRow", this.selectedRow);
    this.renderPlateNo();
    this.renderAlerts();
    this.alerttabstyle = JSON.parse(localStorage.getItem('AlertsData'));
    // this.alerttabstyle = [
    //   {"AlertColor":   "#00aed5",
    //   "AlertType":  "FUEL FILLING",
    //   "description" : "This alert is triggered while filling the fuel",
    //   "icon":  ""},{"AlertColor":   "#00aed5",
    //   "AlertType":  "FUEL DRAIN",
    //   "description" : "This alert is triggered while  the fuel IS reducing",
    //   "icon":  ""} ,{"AlertColor":   "#00aed5",
    //   "AlertType":  "FUEL MAX THRESHOLD",
    //   "description" : "This alert is triggered while  the fuel level is crossing the max level",
    //   "icon":  ""},{"AlertColor":   "#00aed5",
    //   "AlertType":  "FUEL MIN THRESHOLD",
    //   "description" : "This alert is triggered while  the fuel level is crossing the min level",
    //   "icon":  ""},...this.alerttabstyle]
    if (this.mode == "edit")
      this.checkevents = this.selectedRow;
  }


  closemodel() {
    this.modalController.dismiss();
  }


}
