import { Component, Input, OnInit } from '@angular/core';
import { AjaxService } from 'src/app/services/ajax.service';
import { serverUrl } from 'src/environments/environment';

@Component({
  selector: 'app-fuel-level-configuration',
  templateUrl: './fuel-level-configuration.component.html',
  styleUrls: ['./fuel-level-configuration.component.scss'],
})
export class FuelLevelConfigurationComponent implements OnInit {
  @Input() vin;
  data = {
    "fuelStartLevel": "",
    "height": "",
    "diameter": "",
    "fuelType": "v-tank"
  }
  constructor(
    private ajaxService: AjaxService
  ) { }

  submit() {
    const req = {
      "Vin":JSON.parse(localStorage.getItem('selectedVin')).vin,
      "AssetData":JSON.stringify(this.data)
    }
    const url = serverUrl.web+"/sensor/updateAssetValue";
    this.ajaxService.ajaxPostWithBody(url, req)
    .subscribe(res=>{
     })
  }

  ngOnChanges(){
    this.vin = this.vin
  }

  ngOnInit() { }

}
