import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AjaxService } from 'src/app/services/ajax.service';
import { CommonService } from 'src/app/services/common.service';
import { serverUrl } from 'src/environments/environment';

@Component({
  selector: 'app-ticket-modal',
  templateUrl: './ticket-modal.component.html',
  styleUrls: ['./ticket-modal.component.scss'],
})
export class TicketModalComponent implements OnInit {

  public dataArrayTotal = [];
  public dataArray = []
  count: number = 10;
  initiate: boolean = true;
  constructor(private modalController: ModalController, public ajaxService: AjaxService, private commonServices: CommonService) { }

  ngOnInit() {
    this.getData();
  }

  closePopupTicket() {
    this.modalController.dismiss();
  }

  spliceData(res) {
    this.dataArray.splice(res.index, 1);
    if (this.dataArrayTotal.length == 0) {
      this.closePopupTicket()
    }
  }

  loadData(event: any) {
    setTimeout(() => {
      this.count += 10;
      this.setDisplayData();
      event.target.complete();
      if (this.count >= this.dataArrayTotal.length) {
        event.target.disabled = true;
      }
    }, 500);
  }

  setDisplayData() {
    if (this.dataArrayTotal.length > this.count) {
      this.dataArray = this.dataArrayTotal.slice(0, this.count);
    }
    else {
      this.dataArray = this.dataArrayTotal;
    }
  }

  getData() {
    this.initiate = true;
    this.dataArrayTotal = [];
    this.dataArray = [];
    let date = new Date();
    let todaydate = date.toJSON().split("T")[0];
    const url = serverUrl.web + '/tickets/TicketManagement?status=' + 'new' + '&fromDate=' + todaydate + "&toDate=" + todaydate + '&companyId=' + localStorage.corpId + '&userId=' + localStorage.userName;
    this.ajaxService.ajaxGet(url).subscribe(res => {
      this.bindTickets(res)
    })    
  }


  bindTickets(res) {
    this.dataArrayTotal = res.newTickets;
    if (this.dataArrayTotal.length == 0 || this.dataArrayTotal.length == null) {
      setTimeout(() => {
        this.closePopupTicket();
        this.initiate = false;
      }, 800);
      this.commonServices.presentToast('No Tickets Available!');
    }
    else {
      this.initiate = false;
      this.setDisplayData();
    }
  }


}
