import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { companyReportData, languageInitializer } from 'src/environments/environment';

@Component({
  selector: 'app-employee-day-off-report',
  templateUrl: './employee-day-off-report.component.html',
  styleUrls: ['./employee-day-off-report.component.scss'],
})
export class EmployeeDayOffReportComponent implements OnInit {
  reportData = companyReportData.reportData
  directory = languageInitializer.directory
  constructor(
    private commonService: CommonService
  ) { }

  splitNameArabic(name) {
    return this.commonService.splitNameArabic(name, this.directory)
  }

  ngOnInit() {
    this.commonService.updateLanguageDir
      .subscribe(res => {
        this.directory = res == "" ? this.directory : res
      })
  }

}
