import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { companyReportData, languageInitializer } from 'src/environments/environment';

@Component({
  selector: 'app-fleet-summary',
  templateUrl: './fleet-summary.component.html',
  styleUrls: ['./fleet-summary.component.scss'],
})
export class FleetSummaryComponent implements OnInit {
  data: any;
  location: any;
  directory = languageInitializer.directory

  constructor(private commomService: CommonService) { }

  ngOnInit() {
    this.commomService.updateLanguageDir
      .subscribe(res => {
        this.directory = res == "" ? this.directory : res
      })
    this.data = companyReportData.reportData;
   }

  getArabicLetters(letter) {

    return this.commomService.numberToArabic(letter)
  }
}
