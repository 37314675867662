import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-table-speed',
  templateUrl: './table-speed.component.html',
  styleUrls: ['./table-speed.component.scss'],
})
export class TableSpeedComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
