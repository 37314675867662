import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalController, AlertController } from '@ionic/angular';
import { AjaxService } from 'src/app/services/ajax.service';

import { CommonService } from 'src/app/services/common.service';
import { serverUrl, storageVariable } from 'src/environments/environment';
@Component({
  selector: 'app-maintenance-modal',
  templateUrl: './maintenance-modal.component.html',
  styleUrls: ['./maintenance-modal.component.scss'],
})
export class MaintenanceModalComponent implements OnInit, AfterViewInit {

  maintanenceForm: FormGroup;
 notification;

  @Input() myGrid;
  @Input() value;
  @Input() mode;
  data: any;
  email = false;
  nationalPermit = false;
  commonAlerts = true;
  provider = true;
  commonCheckBox = false;
  tyreChange = false;
  odometer = false;
  airFilter = false;
  brakeFluid = false;
  fuelFilter = false;
  generalService = false;
  month = true;
  afterKms = true;
  edit = false;
  vinPlateNo = [];
  vin = [];
  number: any;
  mail = false;
  notificationTypes: any;
  isDeleteShow: any = false;
  alertType: any;
  tuv: Boolean = false;
  serviceDisable: Boolean = false
  companyDetail = {
    branchID: localStorage.getItem('corpId'),
    companyID: localStorage.getItem('corpId'),
    userId: localStorage.getItem('userName')
  }
  selectType: any;
  @ViewChild("plateNoComobox", { static: false }) plateNoComobox;
  plateNo: any;

  constructor(
    private modalController: ModalController,
    private formBuilder: FormBuilder,
    private ajaxService: AjaxService,
    private commonService: CommonService,
    private alertController: AlertController,
    private cdRef: ChangeDetectorRef
  ) { }
  ngAfterViewInit() {
    this.cdRef.detectChanges();

    if (this.mode == "Edit") {
      console.log("edit mode");
      if (this.value.plateNo !== null) {
        let time = setTimeout(() => {
          this.plateNoComobox.val(this.value.plateNo);
          clearTimeout(time);
        }, 200);

      }
    }
  }
  closeModel() {
    this.modalController.dismiss();
  }
  getAlertType() {
    let url = serverUrl.web + `/login/getPreferences?key=Maintanence&companyId=${this.companyDetail.companyID}`;
    this.ajaxService.ajaxGet(url).subscribe(res => {
      this.alertType = res;

    });
  }
  getNotification() {
    let url = serverUrl.web + `/login/getPreferences?key=Notification1&companyId=${this.companyDetail.companyID}`;
    this.ajaxService.ajaxGet(url).subscribe(res => {
      this.notification = res;
    });
  }
  createForm() {
    this.maintanenceForm = this.formBuilder.group({
      plateNo: [''],
      alertType: ['', Validators.required],
      idNumber: [''],
      provider: [''],
      validFrom: ['',],
      validTill: ['',],
      dueAfterMonths :[''],
      dueAfterKms :[''],
      description: [''],
      push : [{ value: '', disabled: true }],
      email1: [''],
      notifiyBefore: ['',],
      email2: [''],
       // nationalPermitdate: [''],
      //  nationalPermitDueKm: ['', Validators.required ],
      //  nationalPermitDueMonth: ['',],
      //  tyreChangeDate: [''],
      //  tyreChangeDueKm: ['', Validators.required ],
      //  tyreChangeDueMonth: [''],
      //  odometerdate: [''],
      //  odometerDueKm: ['', Validators.required ],
      //  odometerDueMonth: [''],
      //  airFilterdate: [''],
      //  airFilterDueKm: ['', Validators.required ],
      //  airFilterDueMonth: [''],
      //  brakeFluidDate: [''],
      //  brakeFluidDueKm: ['', Validators.required ],
      //  brakeFluidDueMonth: [''],
      //  fuelFilterDate: ['' ],
      //  fuelFilterDueKm: ['', Validators.required ],
      //  fuelFilterDueMonth: [''],
      //  generalServiceDate: [''],
      //  generalServiceDueKm: ['', Validators.required ],
      //  generalServiceDueMonth: [''],


    });
  }
  cancelBtn() {
    this.modalController.dismiss();
  }
  changeAlertType(ev) {
    if (ev.target.value === 'Insurance' || ev.target.value === 'Pollution' || ev.target.value === "RC" || ev.target.value === "Permit"
   || ev.target.value === "Fitness Certificate" || ev.target.value === "Green Tax/Road Tax" || ev.target.value === "Speed governor") {
      this.provider = true;
      this.nationalPermit = false;
      this.commonAlerts = true;
      this.commonCheckBox = false;
      this.odometer = false;
      this.tyreChange = false;
      this.airFilter = false;
      this.fuelFilter = false;
      this.generalService = false;
      this.brakeFluid = false;
    }
    else if (ev.target.value === 'FC' || ev.target.value === 'Tax') {
      this.commonAlerts = true;
      this.nationalPermit = false;
      this.provider = false;
      this.commonCheckBox = false;
      this.odometer = false;
      this.tyreChange = false;
      this.airFilter = false;
      this.fuelFilter = false;
      this.generalService = false;
      this.brakeFluid = false;
    }
    else if (ev.target.value === 'National Permit') {
      this.nationalPermit = true;
      this.commonCheckBox = true;
      this.provider = false;
      this.commonAlerts = false;
      this.tyreChange = false;
      this.odometer = false;
      this.airFilter = false;
      this.fuelFilter = false;
      this.generalService = false;
      this.brakeFluid = false;
    }
    else if (ev.target.value == "Tyre Change") {
      this.tyreChange = true;
      this.commonCheckBox = true;
      this.nationalPermit = false;
      this.provider = false;
      this.commonAlerts = false;
      this.tyreChange = true;
      this.odometer = false;
      this.airFilter = false;
      this.fuelFilter = false;
      this.generalService = false;
      this.brakeFluid = false;
    }

    else if (ev.target.value == "Odometer") {
      this.odometer = true;
      this.commonCheckBox = true;
      this.provider = false;
      this.commonAlerts = false;
      this.tyreChange = false;
      this.nationalPermit = false;
      this.airFilter = false;
      this.fuelFilter = false;
      this.generalService = false;
      this.brakeFluid = false;
    }
    else if (ev.target.value == "Brake Fluid") {
      this.brakeFluid = true;
      this.commonCheckBox = true;
      this.nationalPermit = false;
      this.provider = false;
      this.commonAlerts = false;
      this.tyreChange = false;
      this.odometer = false;
      this.airFilter = false;
      this.fuelFilter = false;
      this.generalService = false;

    }
    else if (ev.target.value == "Air Filter") {
      this.airFilter = true;
      this.commonCheckBox = true;
      this.provider = false;
      this.commonAlerts = false;
      this.tyreChange = false;
      this.odometer = false;
      this.nationalPermit = false;
      this.fuelFilter = false;
      this.generalService = false;
      this.brakeFluid = false;
    }
    else if (ev.target.value == "Fuel Filter") {
      this.fuelFilter = true;
      this.commonCheckBox = true;
      this.provider = false;
      this.commonAlerts = false;
      this.tyreChange = false;
      this.odometer = false;
      this.airFilter = false;
      this.nationalPermit = false;
      this.generalService = false;
      this.brakeFluid = false;
    }
    else if (ev.target.value == "General Service") {
      this.generalService = true;
      this.commonCheckBox = true;
      this.nationalPermit = false;
      this.provider = false;
      this.commonAlerts = false;
      this.tyreChange = false;
      this.odometer = false;
      this.airFilter = false;
      this.fuelFilter = false;
      this.brakeFluid = false;
    }
    else if (ev.target.value == "TUV") {
      this.tuv = true
    }
    if (ev.target.value != "TUV") {
      this.tuv = false;
    }

  }
  getEmail(ev) {
    if (ev.target.checked) {
      this.email = false;
      console.log(ev.target.checked);
    } else {
      this.email = true;
    }
  }
  alertKms(ev) {
    if (ev.target.checked) {
      this.afterKms = false;
    } else {
      this.afterKms = true;

    }
  }
  alertMonth(ev) {
    if (ev.target.checked) {
      this.month = false;
    } else {
      this.month = true;
    }
  }
  submit() {
    var mailId = "";
    this.plateNo = this.plateNoComobox.getSelectedItem().value;
    this.maintanenceForm.value.plateNo = this.plateNo;

    console.log(this.maintanenceForm.value.plateNo, "SELCTED VALUE")

    if (this.maintanenceForm.value.plateNo == null || this.maintanenceForm.value.plateNo == "" || this.maintanenceForm.value.plateNo == undefined) {
      this.commonService.presentToast("Please Select a Plate Number!");
      console.log(this.maintanenceForm.value.plateNo, "submit value")
      return null;
    }
    const companyDetail = {
      branchID: localStorage.getItem('corpId'),
      companyID: localStorage.getItem('corpId'),
      userId: localStorage.getItem('userName')
    }
    if (this.email == true) {
      this.notificationTypes = "push" + "#email"
    } else {
      this.notificationTypes = "push"
    }
    if (this.maintanenceForm.value.email1 && this.maintanenceForm.value.email2) {
      mailId = this.maintanenceForm.value.email1 + '#' + this.maintanenceForm.value.email2
    } else if (this.maintanenceForm.value.email1) {
      mailId = this.maintanenceForm.value.email1;
    } else if (this.maintanenceForm.value.email2) {
      mailId = this.maintanenceForm.value.email2;
    }
    var vin;
    var formData = this.maintanenceForm.value.plateNo;
    for (var i = 0; i < this.vinPlateNo.length; i++) {
      if (formData.toString() == (Object.keys(this.vinPlateNo[i])[0]).toString()) {
        vin = this.vinPlateNo[i][this.maintanenceForm.value.plateNo]
        break;
      }
    }
    var method;
    var eventID;
    if (this.edit == true) {
      eventID = this.value.eventId + '';
      method = "edit";
    } else {
      eventID = "";
      method = "new";
    }
    var datas = {
      "eventId": eventID, "methodOf": method, "companyID": companyDetail.companyID,
      "branchID": companyDetail.userId, "vin": vin, "type": this.maintanenceForm.value.alertType, "subject": this.maintanenceForm.value.idNumber.trim(),
      "content": this.maintanenceForm.value.provider.trim(), "fromDate": this.maintanenceForm.value.validFrom,

      "description": this.maintanenceForm.value.description.trim(), "emailId": mailId,
      "noficationBy": this.notificationTypes
    }
    let notify;
    if (this.maintanenceForm.value.notifiyBefore == "1 Day") {
      notify = "1#Days";
    } else if (this.maintanenceForm.value.notifiyBefore == "2 Days") {
      notify = "2#Days";
    } else if (this.maintanenceForm.value.notifiyBefore == "3 Days") {
      notify = "3#Days";
    } else if (this.maintanenceForm.value.notifiyBefore == "4 Days") {
      notify = "4#Days";
    } else if (this.maintanenceForm.value.notifiyBefore == "5 Days") {
      notify = "5#Days";
    } else if (this.maintanenceForm.value.notifiyBefore == "6 Days") {
      notify = "6#Days";
    } else if (this.maintanenceForm.value.notifiyBefore == "1 Week") {
      notify = "1#Week";
    } else if (this.maintanenceForm.value.notifiyBefore == "2 Week") {
      notify = "2#Week";
    } else if (this.maintanenceForm.value.notifiyBefore == "3 Week") {
      notify = "3#Week";
    } else if (this.maintanenceForm.value.notifiyBefore == "1 Month") {
      notify = "1#Month";
    } else if (this.maintanenceForm.value.notifiyBefore == "2 Month") {
      notify = "2#Month";
    }
    datas["alertBy"] = notify;
    if (this.tuv) {
      datas["toDate"] = "";
      (method == "new")?  datas["month"] = this.maintanenceForm.value.dueAfterMonths:datas["month"] = this.maintanenceForm.value.validTill
      datas["kiloMeter"] = this.maintanenceForm.value.dueAfterKms;
    } else {
      datas["toDate"] = this.maintanenceForm.value.validTill;
    }
    const url1 = serverUrl.web + '/device/maintenance?type=renewal';
    const url2 = serverUrl.web + '/device/maintenance?type=service';
    var urlName;

    if (this.maintanenceForm.value.alertType == "Insurance" || this.maintanenceForm.value.alertType == "Pollution" || this.maintanenceForm.value.alertType == "FC"
      || this.maintanenceForm.value.alertType == "Tax" || this.maintanenceForm.value.alertType == "RC" || this.maintanenceForm.value.alertType == "Permit"
      || this.maintanenceForm.value.alertType == "Fitness Certificate" || this.maintanenceForm.value.alertType == "Green Tax/Road Tax" || this.maintanenceForm.value.alertType == "Speed governor"
      || this.maintanenceForm.value.alertType == "Licence" || this.maintanenceForm.value.alertType == "AVP"
    ) {
      urlName = url1;
    } else {
      urlName = url2;
    }


    this.ajaxService.ajaxPostWithString(urlName, datas).subscribe(res => {
      console.log(res);
      if (res == "success") {
        this.modalController.dismiss();
        this.commonService.presentToast("Added successfully")
      } else if (res == "update" || res == "updated") {
        this.modalController.dismiss();
        this.commonService.presentToast("Updated successfully")
      } else {
        this.commonService.presentToast("Check the values")
      }
    })

  }
  async delete() {
    this.value.eventId
    const url = '';
    this.ajaxService.ajaxDeleteWithBody(url, this.value.eventId)

  }
  monthDiff(dateFrom, dateTo) {
    return dateTo.getMonth() - dateFrom.getMonth() +
      (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
  }
  dueAfterDays(ev){
    console.log("ev",ev.detail.value)
 }
  searchPlateNo() {
    if (this.plateNoComobox.getSelectedItem() != null) {
      let vin = this.plateNoComobox.getSelectedItem().value
      this.maintanenceForm.value.plateNo = vin;
      console.log(this.maintanenceForm.value.plateNo, "plate number value")

    }
  }

  ngOnInit() {
    this.createForm();
    this.getAlertType();
    this.getNotification();
    this.data = this.value;
    let localMainMenu = JSON.parse(localStorage.mainMenu)
    this.isDeleteShow = localMainMenu.includes("Delete")
    if (this.value) {
      if (this.value.emailId != "") {
        this.mail = true;
        this.email = true;
      }
      var emailOne = this.value.emailId.split('#')
      //  var notifiy = this.value.notificationType.split('#')
      // if (notifiy[1] == "email") {
      //   this.mail = true;
      // } else {
      //   this.mail = false;
      // }
      this.edit = true
      this.maintanenceForm.patchValue({
        plateNo: this.value.plateNo,
        // alertType: this.value.type,
        // idNumber: this.value.subject,
        // provider: this.value.content,
        validFrom: this.value.fromDate,
        validTill: this.value.toDate,

        description: this.value.description,
        email1: emailOne[0],
        email2: emailOne[1],
      })


      let d: any = (new Date(this.value.toDate.split("T")[0]).getTime() - new Date(this.value.alertBy.split("T")[0]).getTime()) / (1000 * 3600 * 24)
      let selectDays: any;
      console.log("d ", d);

      if (d == "1") {
        selectDays = "1 Day"
      } else if (d == "2") {
        selectDays = "2 Days"
      } else if (d == "3") {
        selectDays = "3 Days"
      } else if (d == "4") {
        selectDays = "4 Days"
      } else if (d == "5") {
        selectDays = "5 Days"
      } else if (d == "6") {
        selectDays = "6 Days"
      } else if (d == "7") {
        selectDays = "1 Week"
      } else if (d == "14") {
        selectDays = "2 Week"
      } else if (d == "21") {
        selectDays = "3 Week"
      } else if (d == "30" || d == 28 || d == 31) {
        selectDays = "1 Month"
      } else if (d == "60" || d == "58" || d == "61" || d == "62") {
        selectDays = "2 Month"
      }
      setTimeout(() => {
        // this.selectType = this.value.type;
        this.maintanenceForm.patchValue({
          alertType: this.value.type,
          notifiyBefore: selectDays

        })
      }, 1200);
      if (this.value.type == "TUV") {
        this.serviceDisable = true;


        let diffMonth = this.monthDiff(new Date(this.value.fromDate.split("-")[0], this.value.fromDate.split("-")[1]), new Date(this.value.toDate.split("-")[0], this.value.toDate.split("-")[1]))
        console.log("diffMonth", typeof diffMonth);
        // if(diffMonth == 28 || diffMonth == 31){
        //   diffMonth = 30
        // }

        this.maintanenceForm.patchValue({
          idNumber: this.value.subject.split("@")[0],
          provider: this.value.subject.split("@")[1],
          dueAfterKms: this.value.content.split("K")[0],
          dueAfterMonths: diffMonth.toString()

        })
      } else {
        this.maintanenceForm.patchValue({
          idNumber: this.value.subject,
          provider: this.value.content,
        })
      }

    } else {
      this.mail = false;
      this.email = false;
    }

    var datas = '';
    var data = storageVariable.dashboardData;
    for (var i = 0; i < Object.keys(data.liveDatas).length; i++) {
      const vin = Object.keys(data.liveDatas)[i];
      var obj = {};
      obj[data.liveDatas[vin]["plateNo"]] = data.liveDatas[vin]["vin"]
      this.vinPlateNo.push(obj);

      this.vin.push(data.liveDatas[vin]["plateNo"],);
    }
  }
}
