import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { companyReportData, languageInitializer } from 'src/environments/environment';

@Component({
  selector: 'app-employee-idlestatus',
  templateUrl: './employee-idlestatus.component.html',
  styleUrls: ['./employee-idlestatus.component.scss'],
})
export class EmployeeIdlestatusComponent implements OnInit {
  reportData = companyReportData.reportData;
  directory = languageInitializer.directory
  constructor(
    private commonService: CommonService
  ) { }

  splitNameArabic(name) {
    return this.commonService.splitNameArabic(name, this.directory)
  }

  ngOnInit() {
    this.commonService.updateLanguageDir
      .subscribe(res => {
        this.directory = res == "" ? this.directory : res
      })
  }

}
