import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { inputs } from '@syncfusion/ej2-angular-navigations/src/accordion/accordion.component';

@Component({
  selector: 'app-nodata-image',
  templateUrl: './nodata-image.component.html',
  styleUrls: ['./nodata-image.component.scss'],
})
export class NodataImageComponent implements OnInit {
  @Input() imageName = undefined;
  @Input() language = undefined;
  @Input() color = undefined;
  constructor(public router: Router) { }
  colorNodata: string = "#6c2a84";
  ngOnInit() {
    const rootStyles = getComputedStyle(document.documentElement);
    const primaryColor = rootStyles.getPropertyValue('--themeGlobal');
    this.colorNodata = primaryColor;
  }



  // reloadComponent(self: boolean, urlToNavigateTo?: string) {
  //   console.log("Current route I am on:", this.router.url);
  //   const url = self ? this.router.url : urlToNavigateTo;
  //   this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
  //     this.router.navigate([`/${url}`]).then(() => {
  //       console.log(`After navigation I am on:${this.router.url}`)
  //     })
  //   })
  // }


}

