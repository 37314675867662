import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { serverUrl, storageVariable } from 'src/environments/environment';
import { AjaxService } from 'src/app/services/ajax.service';
import { ActionModePage } from 'src/app/gridview-tab/action-mode/action-mode.page';

@Component({
  selector: 'app-preventive-model',
  templateUrl: './preventive-model.component.html',
  styleUrls: ['./preventive-model.component.scss'],
})
export class PreventiveModelComponent implements OnInit {
  traveVinData: any;
  inputVal: any = {
    "Current_hour_meter_Value": 0,
    "maintenanceValue": 0,
  };
  fleetLogin: any;
  preventivecheck: boolean = false;


  constructor(
    public modalController: ModalController,
    private commonService: CommonService,
    public formBuilder: FormBuilder,
    private ajaxService: AjaxService,
  ) { }

  async closeModal() {
    await this.modalController.dismiss();
  }


  whenCanceled() {
    this.closeModal();
  }

  resetPreventive(formValue) {
    let passwordcheck = this.fleetLogin.value.pass == localStorage.password ? true : false;
    if (passwordcheck) {
      let value = this.fleetLogin.value.currenthourvalue;
      if (!value.includes(":"))
        value += ":00";
      let liveDatas: any = storageVariable.upDatedJsonData.liveDatas;
      let resetvalue = liveDatas[this.traveVinData.vin].preventivedue[0];
      const assignImeiTocom = {
        "vin": this.traveVinData.vin,
        "type": "PreventiveMaintenance",
        "currenttime": ((parseInt(value.split(":")[0]) * 60) + parseInt(value.split(":")[1])).toString(),
        "reset": resetvalue
      }
      const url = serverUrl.web + '/device/resetpreventiveMaintenance'
      this.ajaxService.ajaxPutMethod(url, assignImeiTocom)
        .subscribe(res => {
          console.log(res);
          res = JSON.parse(res);
          if (res.devicestatus === "persisted")
            this.commonService.presentToast('Reset successfully');
          else
            this.commonService.presentToast('Reset Failed')
        });
    } else
      this.commonService.presentToast("Wrong Password")
  }

  checkboxClick(e) {
    this.preventivecheck = e.target.checked;

  }





  formInitializer() {
    this.fleetLogin = this.formBuilder.group({
      pass: ['', Validators.required],
      currenthourvalue: [this.inputVal.Current_hour_meter_Value],
      chghourvalue: [false]
    });

  }

  ngOnInit() {
    this.traveVinData = JSON.parse(localStorage.selectedVin)
    this.inputVal.Current_hour_meter_Value = (this.traveVinData.engineRunTime != undefined ? (((parseInt(this.traveVinData.engineRunTime
    ) / 60) / 60).toFixed(0)
      + ':' + Number(("0" + ((parseInt(this.traveVinData.engineRunTime) / 60) % 60)).slice(-2))) : 0);

    this.inputVal.maintenanceValue = ((parseInt(this.traveVinData.preventiveMaintenance) / 60).toFixed(0)
      + ':' + Number(("0" + (parseInt(this.traveVinData.preventiveMaintenance) % 60)).slice(-2)));
    this.formInitializer();
  }


}
