import { Component, OnInit } from '@angular/core';
import { companyReportData } from 'src/environments/environment';

@Component({
  selector: 'app-no-transmission-report-wft',
  templateUrl: './no-transmission-report-wft.component.html',
  styleUrls: ['./no-transmission-report-wft.component.scss'],
})
export class NoTransmissionReportWFTComponent implements OnInit {
  wftReports;

  displayData: Array<any>;
  currentPage: number = 1;
   commonData: any;
  displayCount: number = 30;
  constructor() { }

  setDisplayData() {
    if (this.commonData.length > this.displayCount) {
      this.displayData = this.commonData.slice(0, this.displayCount);
    }
    else {
      this.displayData = this.commonData;
    }
  }


  doInfinite(event) {
    setTimeout(() => {
       this.displayData.push(...this.commonData.slice(this.currentPage * this.displayCount, (this.currentPage + 1) * this.displayCount));
      this.currentPage++;
      event.target.complete();
      if (this.displayData.length == this.commonData.length) {
        event.target.disabled = true;
      }
     }, 500);
  }

 

   
  ngOnInit() {
    
    this.wftReports = companyReportData.reportData;
    this.commonData = companyReportData.reportData;
      this.setDisplayData();
  }

}
