import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AjaxService } from 'src/app/services/ajax.service';
import { CommonService } from 'src/app/services/common.service';
import { serverUrl } from 'src/environments/environment';

@Component({
  selector: 'app-ticket-pending',
  templateUrl: './ticket-pending.component.html',
  styleUrls: ['./ticket-pending.component.scss'],
  providers: [DatePipe],
})
export class TicketPendingComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() page;
  @Input() status;
  @Input() data;
  @Input() index;
  @Output() splicedataFromArray = new EventEmitter();
   
  comments: string = "";
  resolution: string = "";
  entryPoint = localStorage.getItem("entryPoint");
  constructor(public ajaxService: AjaxService,
    private trans: TranslateService,
    private commonServices: CommonService,
    private datePipe: DatePipe,
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    // throw new Error('Method not implemented.');
    // console.log(this.index, "index")
  }
  ngAfterViewInit(): void {
    //console.log(this.page)
  }

  ngOnInit() {

  }
  statusChange(status, data, index) {
    let date = new Date();
    let milliseconds = date.getMilliseconds().toString().padStart(3, '0'); // Get milliseconds and ensure it's 3 digits
    let todaydate = date.toJSON().split("T")[0] + " " + this.datePipe.transform(date, 'HH:mm:ss') + '.' + milliseconds;
    const url = serverUrl.web + "/tickets/saveTickets";
    const body = {
      "vin": data.vin,
      "alertType": data.alertType,
      "createdDate": data.createdDate,
      "openDate": todaydate,
      "userId": localStorage.userName,
      "description": this.comments,
      "status": status,
    }
    this.ajaxService.ajaxPostMethod(url, body)
      .subscribe(res => {
        // console.log(res, "success")
      })
      let SuccessfullyClosed=this.trans.instant("'s status is OPENED");
    this.commonServices.presentToast(`${this.entryPoint=="ATM" ? this.trans.instant('Id') : this.trans.instant('Plate no')}  ${data.plateNo}${SuccessfullyClosed}`);
    this.splicedataFromArray.emit({ data, index });

  }
  statusClosed(data, index) {
    if (this.resolution == '') {
      this.commonServices.presentToast("Kindly fill the resolution!");
      return null;
    }
    if (localStorage.userName.toUpperCase() != data.userId.toUpperCase()) {
      this.commonServices.presentToast("this was opened by another user, you are not allowed to close this ticket");
      return null;
    }

    let date = new Date();
    let todaydate = date.toJSON().split("T")[0] + " " + this.datePipe.transform(date, 'HH:mm:ss');
    const url = serverUrl.web + "/tickets/saveTickets";
    const body = {
      "vin": data.vin,
      "alertType": data.alertType,
      "createdDate": data.createdDate,
      "closeDate": todaydate,
      "userId": localStorage.userName,
      "resolution": this.resolution,
      "status": "closed"
    }
    this.ajaxService.ajaxPostMethod(url, body)
      .subscribe(res => {
        //   console.log(res, "success")
      })
      let SuccessfullyClosed=this.trans.instant('Successfully closed the');
    this.resolution = "";
    this.commonServices.presentToast(`${SuccessfullyClosed} ${this.entryPoint=="ATM" ? this.trans.instant('Id') : this.trans.instant('Plate no')} - ${data.plateNo}`);
    this.splicedataFromArray.emit({ data, index });
  }
}
