import { Component, OnInit, Input } from '@angular/core';
import { ModalController, AlertController, Platform } from '@ionic/angular';
import { CommonService } from '../../services/common.service';
import { AjaxService } from '../../services/ajax.service';
import { serverUrl } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { WebAppInterface } from 'src/app/interfaces/AndroidNative';

declare var Android: WebAppInterface;

@Component({
  selector: 'app-action-mode',
  templateUrl: './action-mode.page.html',
  styleUrls: ['./action-mode.page.scss'],
})



export class ActionModePage implements OnInit {
  @Input() mode: string;
  @Input() vin: string;
  immobilizeStatus: boolean;
  watchStatus: boolean;
  immobResponse;
  pass: string = "";
  data: any;
  plateNo: any;
  constructor(private modalController: ModalController,
    private alertController: AlertController,
    private commonService: CommonService,
    public trans: TranslateService,
    public ajaxService: AjaxService,
    public platform: Platform
  ) { }

  toggleWatchmode(event) {
    let statusBitWatchmode: number = 0;
    if (event.currentTarget.checked == true) {
      statusBitWatchmode = 1;
      this.watchStatus = true;
    } else {
      this.watchStatus = false;
    }
    let url: string = serverUrl.web + "/device/updatewatchmode?vin=" + this.data.vin + "&value=" + statusBitWatchmode.toString();
    let body = {
      "vin": this.data.vin,
      "value": statusBitWatchmode.toString()
    };
    this.commonService.presentLoader();
    this.ajaxService.ajaxPostMethodWithoutData(url)
      .subscribe(res => {
        console.log(res);
        if (res.length > 1) {
          this.commonService.dismissLoader();
          statusBitWatchmode = 0;
          if (this.watchStatus == true) {
            statusBitWatchmode = 1;
          }
          this.data["watchmode"] = statusBitWatchmode;
          localStorage.setItem("selectedVin", JSON.stringify(this.data));
        } else {
          this.watchStatus = !this.watchStatus;
          this.commonService.dismissLoader();
          this.commonService.presentToast('Invalid credential');
        }
      });
  }

  async presentAlertPrompt() {
    const alert = await this.alertController.create({
      header: this.trans.instant('Enter Password!'),
      inputs: [
        {
          name: 'Password',
          type: 'password',
          placeholder: this.trans.instant('Password')
        }
      ],
      buttons: [
        {
          text: this.trans.instant('Cancel'),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            this.immobilizeStatus = !this.immobilizeStatus;
            console.log('Confirm Cancel');
          }
        }, {
          text: this.trans.instant('Ok'),
          handler: (data) => {
            if (document.getElementById("alert-input-6-0")["value"] === localStorage.password) {
              this.pass = document.getElementById("alert-input-6-0")["value"]
              this.conformPass();
            }
            else {
              this.immobilizeStatus = !this.immobilizeStatus;
              this.commonService.presentToast('Invalid password');
            }
            console.log('Confirm Ok');
          }
        }
      ]
    });
    await alert.present();

    let data: any = document.getElementsByClassName("alert-input-wrapper")[0]
    data.innerHTML = '<div class="alert-input-wrapper sc-ion-alert-md">' +
      '<input placeholder="Password" type="password" id="alert-input-6-0" tabindex="0" class="alert-input sc-ion-alert-md" autocomplete="off">' + '</div>'
  }
  conformPass() {
    let returnValue: string;
    if (this.immobilizeStatus == true) {
      returnValue = "immobilizeOn";
    } else {
      returnValue = "immobilizeOff";
    }
    console.log("returnValue",returnValue);
    
     const data = JSON.parse(localStorage.getItem('commandsData'));
    let command: any = this.commonService.commandsBasedOnModel(this.data.model, returnValue);
    // let command: any;
    // const data = JSON.parse(JSON.parse(localStorage.getItem('commandsData')));
    // for (let i = 0; i < data.length; i++) {
    //   if (data[i].hasOwnProperty(this.data.model)) {
    //     command = data[i][this.data.model][returnValue];
    //   }
    // }
    if (command != undefined) {
      const body = {
        "imeiNo": this.data.imeiNo,
        "manufacturer": this.data.make,
        "model": this.data.model,
        "command": command,
        "pass": this.pass
      }
      if (this.data.make == "deepseaNew")
        body["commandType"] = "slaveId";

      let url: string = serverUrl.Admin + "/api/device/command";

      this.commonService.presentLoader();
      this.ajaxService.ajaxPostWithString(url, body)
        .subscribe(resdata => {
          clearTimeout(this.immobResponse);
          console.log('----------Recieved Command From Device ==>' + resdata + '<==----------');
          if (this.data.model == "TK003" || this.data.model == "FM2200" || this.data.model == "FMB120" || this.data.model == "FMM130" || this.data.model == "FMB920" || this.data.model == "FMC130" || this.data.model == "FMC920" || this.data.model == "GT06N") {
            if (resdata === undefined || resdata === 'null' || resdata === null || resdata === '' || resdata === 'noresponse' || Object.keys(resdata).length == 0) {
              this.commonService.presentAlert('Alert', 'Empty response from device. Please try after sometime',"immobilizeWarning");
              this.immobilizeStatus = !this.immobilizeStatus;
            } else {
              statusBitImmobilize = 0;
              if (this.immobilizeStatus == true) {
                statusBitImmobilize = 1;
              }
              this.data["lockstatus"] = statusBitImmobilize;
              localStorage.setItem("selectedVin", JSON.stringify(this.data));
              let tempDashboardData = localStorage.dashboardData;
              (returnValue == "immobilizeOn") ?   this.commonService.presentToast("Immobilizer setting activated successfully","success") : this.commonService.presentToast("Restore setting activated successfully","danger")
              
              this.savedetails();
            }
          }
          else {
            var statusBitImmobilize;
            const immobilizeSuccess = this.commonService.commandsBasedOnModel(this.data.model, 'immobilizeSuccess');
            if (resdata === undefined || resdata === 'null' || resdata === null || resdata === '' || resdata === 'noresponse' || Object.keys(resdata).length == 0) {
              this.commonService.presentAlert('Alert', 'Empty response from device. Please try after sometime',"immobilizeWarning");
              this.immobilizeStatus = !this.immobilizeStatus;
            } else {
              let checkData = '';
              for (let i = 0; i < immobilizeSuccess.length; i++) {
                checkData = resdata.indexOf(immobilizeSuccess[i]);
                if ((checkData.toString()) !== '-1') {
                  break;
                }
              }
              if ((checkData.toString()) === '-1') {
                this.commonService.presentAlert('Error', 'Invalid response. Please try after sometime.');
                this.immobilizeStatus = !this.immobilizeStatus;
              } else {
                statusBitImmobilize = 0;
                if (this.immobilizeStatus == true) {
                  statusBitImmobilize = 1;
                }
                this.data["lockstatus"] = statusBitImmobilize;
                localStorage.setItem("selectedVin", JSON.stringify(this.data));
                let tempDashboardData = localStorage.dashboardData;
                (returnValue == "immobilizeOn") ?   this.commonService.presentToast("Immobilizer setting activated successfully","success") : this.commonService.presentToast("Restore setting activated successfully","danger")
                this.savedetails();
              }
            }
          }
          this.commonService.dismissLoader();
          this.getBack();
        });
      this.immobResponse = setTimeout(() => {
        this.commonService.dismissLoader();
        this.commonService.presentAlert('No Reply', 'Please try after sometime.');
        this.immobilizeStatus = !this.immobilizeStatus;
      }, 60000);
    } else {
      this.immobilizeStatus = !this.immobilizeStatus;
      this.commonService.presentAlert('Alert', this.data.model + " doesn't have immobilize option");
    }
  }
  getBack() {
    this.modalController.dismiss();
  }
  savedetails() {
    let url: string = serverUrl.web + "/api/saveDetails"
    let mode:string=this.immobilizeStatus == true ? "OFF":"ON"

    let body = {
      "companyID":localStorage.corpId,
      "userID": localStorage.userName,
      "type": "edit",
      "oldValue": "{\"oldValue\":{\"Status\":\" " + mode + "\"},\"primaryData\":{\"Vin\":\" " + this.data.vin + " \",\"PlateNo\":\" " + this.data.plateNo + "\"}}",
      "name": "Immobilize"
    };
    this.ajaxService.ajaxPostWithString(url, body)
      .subscribe(res => {
        console.log(res);
        
      });

  }
  ngOnInit() {
    this.data = JSON.parse(localStorage.selectedVin);

    if (this.mode == "Immobilize") {
      if (this.data.lockstatus == 1) {
        this.immobilizeStatus = true;
      }
      else {
        this.immobilizeStatus = false;
      }
    } else {
      if (this.data.hasOwnProperty("watchmode") && this.data.watchmode == 1) {
        this.watchStatus = true;
      }
      else {
        this.watchStatus = false;
      }
    }
  }

}
