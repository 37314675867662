import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { companyReportData, languageInitializer } from 'src/environments/environment';

@Component({
  selector: 'app-employee-monthly-discount-report',
  templateUrl: './employee-monthly-discount-report.component.html',
  styleUrls: ['./employee-monthly-discount-report.component.scss'],
})
export class EmployeeMonthlyDiscountReportComponent implements OnInit {
  reportData = companyReportData.reportData;
  directory = languageInitializer.directory
  constructor(
    private commonService: CommonService
  ) { }

  splitNameArabic(name) {
    return this.commonService.splitNameArabic(name, this.directory)
  }

  ngOnInit() {
    this.commonService.updateLanguageDir
      .subscribe(res => {
        this.directory = res == "" ? this.directory : res
      })
  }
  //  [{
  //   empName: "Gusyhgbdj", empId: "file",
  //   shiftTime: "00:00:00", totalWotkTime: "00:00:00", totalIdleTime: "00:00:00",
  //   geoZoneOut: "00:00:00", geoZoneDuration: "00:00:00", avgSalary: "650020",
  //   supervisor: "rtgerergere", workZone: "retretret"
  // },
  // {
  //   empName: "sddfedfer", empId: "file",
  //   shiftTime: "00:00:00", totalWotkTime: "00:00:00", totalIdleTime: "00:00:00",
  //   geoZoneOut: "00:00:00", geoZoneDuration: "00:00:00", avgSalary: "910008",
  //   supervisor: "regregregers", workZone: "ertertgfgver"
  // },
  // {
  //   empName: "fererfrefe", empId: "file",
  //   shiftTime: "00:00:00", totalWotkTime: "00:00:00", totalIdleTime: "00:00:00",
  //   geoZoneOut: "00:00:00", geoZoneDuration: "00:00:00", avgSalary: "20000",
  //   supervisor: "ttyjtyjtyjyr", workZone: "fdgfdgfdg"
  // }, {
  //   empName: "regterger", empId: "file",
  //   shiftTime: "00:00:00", totalWotkTime: "00:00:00", totalIdleTime: "00:00:00",
  //   geoZoneOut: "00:00:00", geoZoneDuration: "00:00:00", avgSalary: "300051",
  //   supervisor: "uikyjyjrtyt", workZone: "etgergertger"
  // },
  // {
  //   empName: "egererger", empId: "file",
  //   shiftTime: "00:00:00", totalWotkTime: "00:00:00", totalIdleTime: "00:00:00",
  //   geoZoneOut: "00:00:00", geoZoneDuration: "00:00:00", avgSalary: "5000",
  //   supervisor: "trhrtytu", workZone: "gfhrtfgds"
  // }]
}
