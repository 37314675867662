import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';
import { companyReportData } from 'src/environments/environment';


@Component({
  selector: 'app-maintenance-report',
  templateUrl: './maintenance-report.component.html',
  styleUrls: ['./maintenance-report.component.scss'],
})
export class MaintenanceReportComponent implements OnInit {
  Sample;
  constructor(
    private commonServices: CommonService
  ) { }



  getArabicLetters(letter) {
    return this.commonServices.numberToArabic(letter)
  }

  ngOnInit() {
    this.commonServices.dismissLoader()
    this.Sample = companyReportData.reportData
  }

}
