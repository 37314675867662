import { WebAppInterface } from 'src/app/interfaces/AndroidNative';
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  production: false
};
let urlValue: string;
let cacheURL: string;
let webSocketUrl: string;
let adminSocketUrl: string;
export let companyReportData = {
  reportData: undefined,
  recurrenceData: undefined
}
export let languageInitializer = { selectedLang: "en", directory: "ltr" };
export let storageVariable = {
  upDatedJsonData: undefined,
  dashboardData: undefined,
  authentication: "",
  groupAndVin: undefined,
  comboReports: undefined,

}
let ticketUrl: string;
const applicationPackNames = {
  //"GE":"com.eit.gelement",
  "GE": "com.ge.goldenelement.avl",
  "APM": "com.eit.apmkingstrack",
  "tracalogic": "com.eittracalogic.tracalogic",
  "Move and track": "com.mvt.apmkt",
  "Move and track ios": "com.apm.mvtkt",
  "Upcot-mvt": "com.upcotmvt.upcot",
  "Remoncloud": "com.elint.remon",
  "RAC": "com.rac.riyadairport"
};

// let packageName = applicationPackNames.moveandtrack;
let loginImgUrl: string;
let appName = 'GE QA(new)';
//  GE QA(new) GE Live Arabian Live   RAC Live   Peax Live 
let appVersion = '2.3.2';
const host = {
  staticIp: '122.165.187.106',
  localIp: '192.168.0.109',
  localhost: "localhost",
  GeLive: "mvt.thegoldenelement.com",
  newQA: "qa-one.thegoldenelement.com",
  arabiandynamics: "arabiantracking.net",
  CloudRemon: "cloud.remon.in",
  GpsRemon: "gps.remon.in",
  peax: "peaxmvt.thegoldenelement.com",
  RACLive: "mrac.thegoldenelement.com",
  PresionerTracker: 'ptrack.thegoldenelement.com',
  windowServer: 'arm.apmkingstrack.com',
  apmKT: 'apmkingstrack.com',
  Armoron: "armoron.apmkingstrack.com",
  GE: 'mobs.thegoldenelement.com',
  GE_QA: 'mvtqa.thegoldenelement.com',
  // tabukWFT: 'tabukwftqa.thegoldenelement.com',
  tabukWFT: 'tabukwfts.thegoldenelement.com',
  QA: '34.232.238.80:8090',
  SKT: 'armoronapi.apmkingstrack.com:8080',
  BeanStalk: 'us-east-1.elasticbeanstalk.com',
  lnt: 'lnt.tracalogic.co',
  Mark: "gps.msoftech.com",
  albilad:"albilad.thegoldenelement.com"
};

let entryPoint;
console.log("hi")
let backgroundImage = 'https://kingstrackimages.s3.amazonaws.com/loginimages/track_apmkingstrack_com_background.jpg';
switch (appName) {

  case 'GE Live':
    urlValue = "https://" + host.GeLive + "/fleettracking";
    cacheURL = "https://" + host.GeLive + "/Admin";
    webSocketUrl = "wss://" + host.GeLive;
    adminSocketUrl = "wss://" + "spring.thegoldenelement.com";
    entryPoint = 'VTS';
    appName = "GE"
    loginImgUrl = 'assets/loginLogo/ge.png';
    backgroundImage = 'https://kingstrackimages.s3.amazonaws.com/loginimages/track_thegoldenelement_com_background.jpg';
    if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
      // applicationPackNames[appName] = "com.apm.mvtkt";
      ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;

    case 'ATM Live':
      urlValue = "https://" + host.albilad + "/fleettracking";
      cacheURL = "https://" + host.albilad + "/Admin";
      webSocketUrl = "wss://" + host.albilad;
      adminSocketUrl = "wss://" + "spring.thegoldenelement.com";
      entryPoint = 'ATM';
      appName = "GE"
      loginImgUrl = 'assets/loginLogo/ge.png';
      backgroundImage = 'https://kingstrackimages.s3.ap-southeast-1.amazonaws.com/loginimages/albilad_bg_image.jpg';
      if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
        // applicationPackNames[appName] = "com.apm.mvtkt";
        ticketUrl = 'https://apmkingstrack.freshdesk.com';
      break;
      case 'ATM_postgres_test':
      urlValue = "http://" + "95.177.179.99:8085" + "/fleettracking";
      cacheURL = "http://" + "95.177.179.99:8081" + "/Admin";
      webSocketUrl = "ws://" + "95.177.179.99:8085";
      adminSocketUrl = "ws://" + "95.177.179.99:8081";
      entryPoint = 'ATM';
      appName = "GE"
      loginImgUrl = 'assets/loginLogo/ge.png';
      backgroundImage = 'https://kingstrackimages.s3.ap-southeast-1.amazonaws.com/loginimages/albilad_bg_image.jpg';
      if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
        // applicationPackNames[appName] = "com.apm.mvtkt";
        ticketUrl = 'https://apmkingstrack.freshdesk.com';
      break;

  case 'Arabian Live':
    urlValue = "https://" + host.GeLive + "/fleettracking";
    cacheURL = "https://" + host.GeLive + "/Admin";
    webSocketUrl = "wss://" + host.GeLive;
    adminSocketUrl = "wss://" + "spring.thegoldenelement.com";
    entryPoint = 'VTS';
    appName = "GE"
    loginImgUrl = 'assets/loginLogo/arabiandynamics.png';
    backgroundImage = 'https://kingstrackimages.s3.amazonaws.com/loginimages/track_thegoldenelement_com_background.jpg';
    if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
      // applicationPackNames[appName] = "com.apm.mvtkt";
      ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;
  // remon.in
  case 'Cloud_Remon':
    urlValue = "https://" + host.CloudRemon + "/fleettracking";
    cacheURL = "https://" + host.CloudRemon + "/Admin";
    webSocketUrl = "wss://" + host.CloudRemon;
    adminSocketUrl = "wss://" + "spring.thegoldenelement.com";
    entryPoint = 'VTS';
    appName = "GE"
    loginImgUrl = 'assets/loginLogo/elint-cloud-logo.png';
    backgroundImage = 'https://kingstrackimages.s3.amazonaws.com/loginimages/remon_cloud_background.png';
    if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
      // applicationPackNames[appName] = "com.apm.mvtkt";
      ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;
  case 'Gps_Remon':
    urlValue = "https://" + host.GpsRemon + "/fleettracking";
    cacheURL = "https://" + host.GpsRemon + "/Admin";
    webSocketUrl = "wss://" + host.GpsRemon;
    adminSocketUrl = "wss://" + "spring.thegoldenelement.com";
    entryPoint = 'VTS';
    appName = "GE"
    loginImgUrl = 'assets/loginLogo/elint-cloud-logo.png';
    backgroundImage = 'https://kingstrackimages.s3.amazonaws.com/loginimages/remon_cloud_background.png';
    if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
      // applicationPackNames[appName] = "com.apm.mvtkt";
      ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;
  // remon.in
  case 'Mark Soft':
    urlValue = "https://" + host.Mark + "/fleettracking";
    cacheURL = "https://" + host.Mark + "/Admin";
    webSocketUrl = "wss://" + host.Mark;
    adminSocketUrl = "wss://" + "spring.thegoldenelement.com";
    entryPoint = 'VTS';
    appName = "GE"
    loginImgUrl = 'assets/loginLogo/MarkSoftTech.png';
    backgroundImage = 'https://kingstrackimages.s3.amazonaws.com/loginimages/remon_cloud_background.png';
    if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
      // applicationPackNames[appName] = "com.apm.mvtkt";
      ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;
  case 'GE QA(new)':
    urlValue = "https://" + host.newQA + "/fleettracking";
    cacheURL = "https://" + host.newQA + "/Admin";
    webSocketUrl = "wss://" + "qa-spring.thegoldenelement.com";
    adminSocketUrl = "wss://" + "qa-spring.thegoldenelement.com";
    entryPoint = 'VTS';
    appName = "GE"
    loginImgUrl = 'assets/loginLogo/arabiandynamics.png';
    backgroundImage = 'https://kingstrackimages.s3.amazonaws.com/loginimages/track_thegoldenelement_com_background.jpg';
    if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
      // applicationPackNames[appName] = "com.apm.mvtkt";
      ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;
    case 'umar test':
      urlValue = "http://" + "95.177.179.99" + "/fleettracking"; http://95.177.179.99/mvt
      cacheURL = "http://" + "95.177.179.99" + "/Admin";
      webSocketUrl = "ws://" + "95.177.179.99";
      adminSocketUrl = "ws://" + "95.177.179.99";
      entryPoint = 'VTS';
      appName = "GE"
      loginImgUrl = 'assets/loginLogo/arabiandynamics.png';
      backgroundImage = 'https://kingstrackimages.s3.amazonaws.com/loginimages/track_thegoldenelement_com_background.jpg';
      if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
        // applicationPackNames[appName] = "com.apm.mvtkt";
        ticketUrl = 'https://apmkingstrack.freshdesk.com';
      break;
  case 'localhost':
    urlValue = "http://" + host.localhost + ":8080/fleettracking";
    cacheURL = "http://" + host.localhost + ":8081/Admin";
    webSocketUrl = "ws://" + host.localhost + ":8081";
    adminSocketUrl = "ws://" + host.localhost + ":8081";
    entryPoint = 'VTS';
    appName = "GE"
    loginImgUrl = 'assets/loginLogo/ge.png';
    backgroundImage = 'https://kingstrackimages.s3.amazonaws.com/loginimages/track_thegoldenelement_com_background.jpg';
    if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
      // applicationPackNames[appName] = "com.apm.mvtkt";
      ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;

  case 'SmartLine':
    urlValue = "http://" + host.PresionerTracker + "/fleettracking";
    cacheURL = "http://" + host.PresionerTracker + "/Admin";
    webSocketUrl = "wss://" + host.GE_QA;
    adminSocketUrl = "wss://" + host.GE_QA;
    entryPoint = 'VTS';
    appName = "GE"
    loginImgUrl = 'assets/loginLogo/smartLineTechnology.png';
    backgroundImage = 'https://kingstrackimages.s3.amazonaws.com/loginimages/ptrack_smart_line.jpg';
    break;

  case 'Peax Live':
    urlValue = "https://" + host.peax + "/fleettracking";
    cacheURL = "https://" + host.peax + "/Admin";
    webSocketUrl = "wss://" + host.peax;
    adminSocketUrl = "wss://" + "peax-spring.thegoldenelement.com";
    entryPoint = 'VTS';
    appName = "peax"
    loginImgUrl = 'assets/loginLogo/peax.png';
    backgroundImage = 'https://kingstrackimages.s3.amazonaws.com/loginimages/peax_thegoldenelement_com_background.jpg';
    if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
      // applicationPackNames[appName] = "com.apm.mvtkt";
      ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;

  case 'GE QA':
    urlValue = "https://" + host.GE_QA + "/fleettracking";
    cacheURL = "https://" + host.GE_QA + "/Admin";
    webSocketUrl = "wss://" + host.GE_QA;
    adminSocketUrl = "wss://" + host.GE_QA;
    entryPoint = 'VTS';
    appName = "GE"
    loginImgUrl = 'assets/loginLogo/ge.png';
    backgroundImage = 'https://kingstrackimages.s3.amazonaws.com/loginimages/track_thegoldenelement_com_background.jpg';
    if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
      applicationPackNames[appName] = "com.apm.mvtkt";
    ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;

  case 'TABUK WFT':
    urlValue = "https://" + host.tabukWFT + "/fleettracking";
    cacheURL = "https://" + host.tabukWFT + "/Admin";
    webSocketUrl = "wss://" + host.tabukWFT;
    adminSocketUrl = "wss://" + host.tabukWFT;
    entryPoint = 'VTS';
    appName = "GE"
    loginImgUrl = 'assets/loginLogo/ge.png';
    backgroundImage = 'https://kingstrackimages.s3.amazonaws.com/loginimages/track_thegoldenelement_com_background.jpg';
    if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
      applicationPackNames[appName] = "com.goldenelement.tabukwft";
    ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;

  case 'RAC Live':
    urlValue = "https://" + host.RACLive + "/fleettracking";
    cacheURL = "https://" + host.RACLive + "/Admin";
    webSocketUrl = "wss://" + "rac-spring.thegoldenelement.com";
    adminSocketUrl = "wss://" + "rac-spring.thegoldenelement.com";
    entryPoint = 'VTS';
    appName = "RAC"
    loginImgUrl = 'assets/loginLogo/RALogo.png';
    // backgroundImage = 'https://rac.thegoldenelement.com/img/RAC-LoginPage.jpg';
    backgroundImage = 'assets/backgroungImage/airport-LoginPage.jpeg';
    if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
      applicationPackNames[appName] = "com.rac.riyadairport";
    ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;

  case 'Tracalogic':
    urlValue = "http://" + host.lnt + ':8090/Web';
    cacheURL = "http://" + host.lnt + '/fleettrackingadmin';
    webSocketUrl = "ws://" + host.lnt + ":8090";
    adminSocketUrl = "ws://" + host.lnt;
    entryPoint = 'VTS';
    loginImgUrl = 'assets/loginLogo/tracalogic.png';
    ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;

  case 'Move and track':
    urlValue = "http://" + host.apmKT + ":8090/Web";
    cacheURL = "http://" + host.apmKT + ":8090/Admin";
    webSocketUrl = "ws://" + host.apmKT + ":8090";
    adminSocketUrl = "ws://" + host.apmKT + ":8090";
    entryPoint = 'VTS';
    loginImgUrl = 'assets/loginLogo/moveandtrack.png';
    if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
      applicationPackNames[appName] = "com.apm.mvtkt";
    ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;

  case 'MnT test':
    urlValue = "http://" + host.windowServer + ":8081/fleettracking";
    cacheURL = "http://" + host.windowServer + ":8091/Admin";
    webSocketUrl = "ws://" + host.windowServer + ":8091";
    adminSocketUrl = "ws://" + host.windowServer + ":8091";
    entryPoint = 'VTS';
    appName = "Move and track"
    loginImgUrl = 'assets/loginLogo/moveandtrack.png';
    if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
      applicationPackNames[appName] = "com.apm.mvtkt";
    ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;

  case 'MnT Live':
    urlValue = "http://" + host.apmKT + ":8081/fleettracking";
    cacheURL = "http://" + host.apmKT + ":8091/Admin";
    webSocketUrl = "ws://" + host.apmKT + ":8091";
    adminSocketUrl = "ws://" + host.apmKT + ":8091";
    entryPoint = 'VTS';
    appName = "Move and track"
    loginImgUrl = 'assets/loginLogo/moveandtrack.png';
    if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
      applicationPackNames[appName] = "com.apm.mvtkt";
    ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;

  case 'MnT Web':
    urlValue = "https://mvt.apmkingstrack.com/fleettracking";
    cacheURL = "https://mvt.apmkingstrack.com/Admin";
    webSocketUrl = "wss://mvt.apmkingstrack.com";
    adminSocketUrl = "wss://mvt.apmkingstrack.com";
    entryPoint = 'VTS';
    appName = "Move and track"
    loginImgUrl = 'assets/loginLogo/moveandtrack.png';
    if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
      applicationPackNames[appName] = "com.apm.mvtkt";
    ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;

  case 'Window Server':
    urlValue = "http://" + host.windowServer + ":8081/fleettracking";
    cacheURL = "http://" + host.windowServer + ":8091/Admin";
    webSocketUrl = "ws://" + host.windowServer + ":8091";
    adminSocketUrl = "ws://" + host.windowServer + ":8091";
    entryPoint = 'unknown';
    appName = "Move and track"
    loginImgUrl = 'assets/loginLogo/moveandtrack.png';
    if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
      applicationPackNames[appName] = "com.apm.mvtkt";
    ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;

  case 'Armoron':
    urlValue = "http://" + host.Armoron + ":8081/fleettracking";
    cacheURL = "http://" + host.Armoron + ":8091/Admin";
    webSocketUrl = "ws://" + host.Armoron + ":8091";
    adminSocketUrl = "ws://" + host.Armoron + ":8091";
    entryPoint = 'VTS';
    loginImgUrl = 'assets/loginLogo/moveandtrack.png';
    if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
      applicationPackNames[appName] = "com.armoron10.apmkingstrack";
    ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;

  case 'ParentApp':
    urlValue = "http://" + host.windowServer + ":8081/fleettracking";
    cacheURL = "http://" + host.windowServer + ":8090/Admin";
    webSocketUrl = "ws://" + host.windowServer + ":8091";
    adminSocketUrl = "ws://" + host.windowServer + ":8090";
    entryPoint = 'VTS';
    loginImgUrl = 'assets/loginLogo/moveandtrack.png';
    if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
      applicationPackNames[appName] = "com.armoron10.apmkingstrack";
    ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;

  case 'Remoncloud':
    urlValue = "http://" + host.apmKT + ":8090/Web";
    cacheURL = "http://" + host.apmKT + ":8090/Admin";
    webSocketUrl = "ws://" + host.apmKT + ":8090";
    adminSocketUrl = "ws://" + host.apmKT + ":8090";
    entryPoint = 'unknown';
    loginImgUrl = 'assets/loginLogo/remoncloud.png';
    ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;

  case "web":
    appName = "Move and track"
    urlValue = "https://mvt.apmkingstrack.com:8443/Web";
    cacheURL = "https://mvt.apmkingstrack.com:8443/Admin";
    webSocketUrl = "wss://" + "mvt.apmkingstrack.com:8443/";
    adminSocketUrl = "wss://" + "mvt.apmkingstrack.com:8443/";
    entryPoint = 'VTS';
    loginImgUrl = 'assets/loginLogo/moveandtrack.png';
    ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;

  case "Remon web":
    urlValue = "http://" + "track.remon.in" + "/Web";
    cacheURL = "http://" + "track.remon.in" + ":/Admin";
    webSocketUrl = "ws://" + "track.remon.in" + "";
    adminSocketUrl = "ws://" + "track.remon.in" + "";
    entryPoint = 'unknown';
    loginImgUrl = 'assets/loginLogo/remoncloud.png';
    ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;

  case 'Upcot-mvt':
    urlValue = "http://" + host.apmKT + ":8090/Web";
    cacheURL = "http://" + host.apmKT + ":8090/Admin";
    webSocketUrl = "ws://" + host.apmKT + ":8090";
    adminSocketUrl = "ws://" + host.apmKT + ":8090";
    entryPoint = 'VTS';
    loginImgUrl = 'assets/loginLogo/upcot.png';
    ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;

  case 'GE':
    urlValue = "http://" + host.apmKT + ":8090/Web";
    cacheURL = "http://" + host.apmKT + ":8090/Admin";
    webSocketUrl = "ws://" + host.apmKT + ":8090";
    adminSocketUrl = "ws://" + host.apmKT + ":8090";
    entryPoint = 'VTS';
    loginImgUrl = 'assets/loginLogo/ge.png';
    break;

  case 'Local Host':
    urlValue = "http://" + host.localIp + ":8090/Web";
    cacheURL = "http://" + host.localIp + ":8090/Admin";
    webSocketUrl = 'ws://' + host.localIp + ":8090";
    adminSocketUrl = 'ws://' + host.localIp + ":8090";;
    entryPoint = 'VTS';
    loginImgUrl = 'assets/loginLogo/moveandtrack.png';
    ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;


  case 'Static Ip':
    urlValue = "http://" + host.staticIp + ":8090/Web";
    cacheURL = "http://" + host.staticIp + "/fleettrackingadmin";
    webSocketUrl = "ws://" + host.staticIp + ":8090";
    adminSocketUrl = "ws://" + host.staticIp + ":8090";
    entryPoint = 'VTS';
    loginImgUrl = 'assets/loginLogo/moveandtrack.png';
    ticketUrl = 'https://apmkingstrack.freshdesk.com';
    break;
  case 'WFT':
    entryPoint = 'WFT';
    break;

  default:
    // urlValue = "https://" + host.GeLive + "/fleettracking";
    // cacheURL = "https://" + host.GeLive + "/Admin";
    // webSocketUrl = "wss://" + host.GeLive;
    // adminSocketUrl = "wss://" + "spring.thegoldenelement.com";
    // entryPoint = 'VTS';
    // appName = "GE"
    // loginImgUrl = 'assets/loginLogo/ge.png';
    // // backgroundImage = 'https://kingstrackimages.s3.amazonaws.com/loginimages/track_thegoldenelement_com_background.jpg';
    // backgroundImage = 'https://kingstrackimages.s3.ap-southeast-1.amazonaws.com/loginimages/GE-Background-2030.jpg';
    // if (/(iPhone|iPad|iPod)/i.test(navigator.userAgent))
    //   // applicationPackNames[appName] = "com.apm.mvtkt";
    //   ticketUrl = 'https://apmkingstrack.freshdesk.com';
}



export let serverUrl = {
  web: urlValue,
  Admin: cacheURL,
  websocket: webSocketUrl,
  adminSocket: adminSocketUrl,
  ticketUrl: ticketUrl
}
export const app = {
  "package": applicationPackNames[appName],
  "entryPoint": entryPoint,
  "loginImgUrl": loginImgUrl,
  "appName": appName,
  "appVersion": appVersion,
  "bgImage": backgroundImage,
  "authentication": ""
}

/*
* For easier debugging in development mode, you can import the following file
* to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
*
* This import should be commented out in production mode because it will have a negative impact
* on performance if an error is thrown.
*/
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
