import { Component, Inject, NgZone, Renderer2 } from '@angular/core';
import { Platform, AlertController, MenuController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { CommonService } from './services/common.service';
import { Router } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Market } from '@ionic-native/market/ngx';
import { app, companyReportData, languageInitializer, serverUrl, storageVariable } from 'src/environments/environment';
import { AjaxService } from './services/ajax.service';
import { Network } from '@ionic-native/network/ngx';
import { Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { WebsocketService } from './services/websocket.service';
import { TranslateConfigService } from './services/translate-config.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {
  isModalOpen = false;
  loadingRefresh = false;
  fullURLData = "";
  // restrictedCommandImei = ["357544376950214", "864431049839964"];
  appIcon = {
    
    "Dashboard": "home",
    "Grid view": 'md-browsers',
    "Device Debug": 'cog',
    "Map view": 'map',
    "Multi Track": "navigate",
    "Track history": "pin",
    // "Geo fence": 'navigate',

    "Alerts": "warning",
    "Ticket": "card",
    "Settings": "settings",

    // "Camera": "camera",
    "Manage": "folder",
    "Maintenance": "build",
    "newreport": "stats",
    
    "New Maintenance":"folder",
    "Flutter":"folder",

    // "Users": 'people',
    "Maintanence": 'construct',
    // "Expense":'md-browsers',
    // "Operators": 'nuclear',
    // "Diagnosis": "globe",
    // "User diagnosis": "planet",
    // "Tickets": 'filing',
    "Reports": "stats",
    "Trip": "settings",
    "Excell Uploader": "cloud-upload",
    "Student details": "md-man",
    "Class details": "ios-people",
    "Parent-details": "ios-contacts",
    "Tag details": "ios-ribbon",
    "Route n Trip": "ios-bus",
    "Gate details": 'ios-card',
    "Student attendance": "ios-clipboard",
    "School E&D": "business",
    "Excel validation": 'warning',
    "Broadcast sms": "ios-mail",
    "Change number": "cloud-upload",
    // "Profile": 'person',
    "Log out": "log-out",

    "Home": "home",
    "Company": 'person',
    "Vehicle creation": 'stats',
    "Dealer creation": "add-circle-outline",
    "Report": "stats",
    "Device activation": "md-browsers",
    "Command": 'mail-open',
    "Stock uploader": "cloud-upload",
    "Stocks": "logo-buffer",
    "Company creation": "business",
    "Subscription": "bookmarks",
    "Stocks report": "logo-euro",
    "checkImei": "ios-mail",
    "Log-out": "log-out",

    "About": "md-browsers",
    "Log-Out": "log-out",


    "poc-geolocation": "pin"
  }

  appUrl = {
    "Dashboard": '/tabs/members/dashboard',
    "Grid view": '/tabs/gridview/All',
    "Map view": '/tabs/mapview/All',
    "Multi Track":"/multitracking",
    "Track history": "/trackhistory",
    "Device Debug": '/device',
    // "PDF": '/home',
    // "Geo fence": '/geofence/All/null',

    "Alerts": "/tabs/alerts/All",
    "Ticket": "ticket-management",
    "Settings": "settings/All",
    // "Camera": "/camera",
    "Manage": "/generalform",
    "New Maintenance":"/new-maintenance",
    "Flutter":"/flutter",
    "Maintenance":"/maintenance",
    "newreport":"/newreport",
    // "Profile": 'profile',
    // "Operators": 'manage-fleet/Operator',
    // "Users": 'manage-fleet/Users',
    // "Tickets": 'ticket',
    "Maintanence": 'maintanence',
    // "Expense":'expense-maintenance',
    // "Diagnosis": "/diagnosis",
    // "User diagnosis": "diagnosis-user",
    "Reports": "/reports/null",
    "Trip": "trip-summary",
    "Log out": "tabs-login/members/login",
    "Home": "tabs-login/new-dashboard",
    "Company": 'tabs-login/dashboard',
    "Vehicle creation": 'tabs-login/vehicle-creation',
    "Dealer creation": 'tabs-login/add-delar',
    "Report": 'tabs-login/delar-report',
    "Device activation": 'tabs-login/device-activation',
    "Command": "tabs-login/device-commands",
    "Stock uploader": "tabs-login/stock-uploader",
    "Stocks": "tabs-login/stocks",
    "Company creation": "tabs-login/dashboard/add-company",
    "Subscription": "tabs-login/subscription",
    "checkImei": "tabs-login/check-imei",
    "Sales report": "tabs-login/sales-report",
    "Asset Search": "/tabs-login/asset-search",
    "Change number": "/change-number",
    "Excell Uploader": "/skt-excell",

    "Student details": "/student-details",
    "Class details": "/class-table",
    "Parent-details": "/parent-table",
    "Tag details": "/tag-table",
    "Route n Trip": "/route-trip",
    "Gate details": '/gate-table',
    "Student attendance": "/student-attendence",
    "School E&D": "/school-enable",
    "Excel validation": '/excel-validation',
    "Broadcast sms": "/broadcast-sms",
    "Log-out": "tabs-login/members/login",

    "About": "/about",

    // "Log-Out": "tabs-login/members/login",
    "Log-Out": "tabs-login/members/login",
    "poc-geolocation": "/poc-geolocation",
  }
  public appPages = [
    {
      title: 'Dashboard',
      url: '/tabs/members/dashboard',
      icon: 'home'
    },
    {
      title: 'Grid view',
      url: '/tabs/gridview/All',
      icon: 'md-browsers'
    },
    {
      title: 'Device Debug',
      url: '/device',
      icon: 'cog',
    },
    {
      title: 'Map view',
      url: '/tabs/mapview/All',
      icon: 'map'
    },
    {
      title: 'Geo fence',
      url: '/geofence/All/null',
      icon: 'navigate'
    },
    {
      title: 'Reports',
      url: '/reports/null',
      icon: 'stats'
    },
    {
      title: 'Alerts',
      url: '/tabs/alerts/All',
      icon: 'warning'
    },
    {
      title: 'Ticket',
      url: 'ticket-management',
      icon: 'card'
    },
    {
      title: 'Settings',
      url: 'settings/All',
      icon: 'settings'
    },
    {
      title: 'Manage',
      url: '/generalform',
      icon: 'globe'
    },
    {
      title: 'New Maintenance',
      url: '/new-maintenance',
      icon: 'globe'
    },
    {
      title: 'Flutter',
      url: '/flutter',
      icon: 'globe'
    },
    {
      title: 'Diagnosis',
      url: '/diagnosis',
      icon: 'globe'
    },
    {
      title: 'Operator',
      url: 'manage-fleet/Operator',
      icon: 'warning'
    },
    {
      title: 'Maintenance',
      url: '/maintenance',
      icon: 'build'
    },
    {
      title: 'newreport',
      url: '/newreport',
      icon: 'stats'
    },
    // {
    //   title: 'Profile',
    //   url: 'manage-fleet/Profile',
    //   icon: 'person'
    // },
    {
      title: 'Ticket',
      url: 'ticket',
      icon: 'md-browsers'
    },
    {
      title: 'maintanence',
      url: 'maintanence',
      icon: 'md-browsers'
    },
    {
      title: 'Users',
      url: 'manage-fleet/Users',
      icon: 'people'
    },
    {
      title: 'Log out',
      url: '/login',
      icon: 'log-out'
    }
  ];
  myPlatform = 'desktop';
  selectedMenu = "Log out";
  isDealer: boolean = false;
  directory: string = "ltl";
  Language: string;
  alertCount: any = 0;
  logOut: boolean = false;
  titleCard = "";
  constructor(
    private zone: NgZone,
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private commonService: CommonService,
    private router: Router,
    private alertController: AlertController,
    private authenticationService: AuthenticationService,
    private menuController: MenuController,
    private ajaxService: AjaxService,
    private title: Title,
    private websocketService: WebsocketService,
    private appVersion: AppVersion,
    private market: Market,
    public translate: TranslateConfigService,
    public trans: TranslateService,
    private renderer: Renderer2, 
    @Inject(DOCUMENT) private document: Document
  ) {
    this.translate.setInitialAppLanguage();
    this.updateLang();
    this.initializeApp();
  }


  menuObject = [];
  app = {
    logo: 'logo.jpg',
    company: 'company'
  };

  appSideMenu = "listMenu";
  menuOver = false;


  ionViewDidEnter() {
    if (sessionStorage.seprateLogin == 'true') {
      this.menuController.enable(false);
    }
  }
  updateLang() {
    this.commonService.updateLanguageDir
      .subscribe(res => {
        this.directory = res == "" ? this.directory : res
        this.trans.use(res)
      })

    var lng = languageInitializer.selectedLang;
    if (lng == "Arabic" || lng == "ar") {
      this.directory = "rtl"
      languageInitializer.directory = "rtl"
      this.Language = "ar";
    } else {
      this.Language = "en";
      languageInitializer.directory = "ltr"
      this.directory = "ltr"
    }
  }

  selectLanguage(event) {
    this.translate.setLanguage(event)
    languageInitializer.directory = event == "en" ? "ltr" : "rtl"
    languageInitializer.selectedLang = event
    if (!(this.trans.store.langs).includes("en"))
      this.trans.store.langs.push("en")
    this.trans.use(event)
    this.commonService.updateLanguageDir.next(languageInitializer.directory)
  }

  closeWebsocket() {
    let vin = JSON.parse(localStorage.selectedVin).vin;
    let duration = JSON.parse(localStorage.appSettings).liveTrackingDisconnect
    const credentials = {
      dashboardVin: JSON.parse(localStorage.selectedVin).vin,
      defaultInterval: duration,
      make: storageVariable.upDatedJsonData.liveDatas[vin].make,
      model: storageVariable.upDatedJsonData.liveDatas[vin].model,
      delay: duration,
      ImeiNo: storageVariable.upDatedJsonData.liveDatas[vin].imeiNo,
      entryPoint: app.entryPoint,
      Check: false,
      emailId: localStorage.userName,
      branchID: localStorage.corpId,
      companyID: localStorage.corpId,
      mode: 'liveTrackDisConnect'
    };
    // if (!this.restrictedCommandImei.includes(storageVariable.upDatedJsonData.liveDatas[vin].imeiNo.imeiNo))
    this.websocketService.reSendRequest(credentials);
    if (this.websocketService.isAlive('livetrack')) {
      this.websocketService.disConnectSocket('livetrack');
    }
  }

  async appUpdate() {
    if (/(android|iPhone|iPad|iPod)/i.test(navigator.userAgent)) {
      if (localStorage.appSettings) {
        let currentVersion = app.appVersion;
        let appSettingsVersion = JSON.parse(localStorage.appSettings)['appComVersion'][app.appName];
        if (appSettingsVersion > currentVersion) {
          const alert = await this.alertController.create({
            header: this.trans.instant('Update Alert'),
            backdropDismiss: false,
            message: this.trans.instant("A new update is now available. Please update from the appstore or playstore."),
            buttons: [{
              text: this.trans.instant('Cancel'),
              role: 'cancel',
              handler: data => {
              }
            },
            {
              text: this.trans.instant('update'),
              handler: data => {
                console.log("update that")
                this.authenticationService.logout();
                this.router.navigateByUrl('login')
                this.market.open(app.package);
              }
            }]
          });

          await alert.present();

        }
      }
    }

  }

  tabChanged() {

    if (sessionStorage.seprateLogin == 'true') {
      this.selectedMenu = "Track history";
    } else {
      this.selectedMenu = "Dashboard"
    }
  }
  septApi() {
    let a: any = new Promise((resolve, reject) => {
      console.log("this.fullURLData", this.fullURLData);
      let url2 = serverUrl.web + "/device/live/json/" + this.fullURLData.split("/")[8] + /assets/ + this.fullURLData.split("/")[5]
      this.ajaxService.ajaxGet(url2)
        .subscribe(res => {
          console.log("ajaxGet ", res);
          localStorage.setItem("seperateLiveData", JSON.stringify(res));
          resolve(res);
        })
    })
    return a;
  }
  async sepUrl() {
    const x = await this.septApi();
    localStorage.selectedVin = JSON.stringify(x);
    this.router.navigateByUrl("/trackhistory");
    localStorage.inItPage = "track";
    this.selectedMenu = "Track history";

  }
  receiveUrl(arg) {
    return this.zone.run(() => {
      // hi umar
      let bro = new Promise((resolve, reject) => {
        let url3 = serverUrl.web + "/device/trackGetFullApi/" + arg
        this.ajaxService.ajaxGet(url3)
          .subscribe(res => {
            console.log("receivefullurl", res);
            resolve(res);
          })
      })
      return bro;
    });

  }
  async url(arg) {
    const y: any = await this.receiveUrl(arg);
    if (y['fullURL'] == "Error") {
      const DIV = document.createElement("div");
      DIV.setAttribute("id", 'showID');
      DIV.setAttribute("class", 'nodata-wrapper');

      const alert = await this.alertController.create({
        header: 'Warning!',
        backdropDismiss: false,
        cssClass: 'invalid-url',
        message: "Invalid URL..."

      });
      await alert.present();

      return null;
    }
    this.fullURLData = y['fullURL'];

    if (this.fullURLData) {
      storageVariable['authentication'] = this.fullURLData.split("/")[9];

      let b = this.fullURLData.split("/")[6]
      localStorage.setItem("trackDate", b);
      sessionStorage.setItem("fromDate", this.fullURLData.split("/")[6].split("#")[0])
      sessionStorage.setItem("toDate", this.fullURLData.split("/")[6].split("#")[1])
      sessionStorage.setItem("color", this.fullURLData.split("/")[10])
      this.sepUrl();
      this.menuController.enable(false);
      // let mainMenu = ["Track history"];
      // for (var i = 0; i < Object.keys(this.appIcon).length; i++) {
      //   if (mainMenu.indexOf(Object.keys(this.appIcon)[i]) != -1)
      //     this.menuObject.push(Object.keys(this.appIcon)[i])
      // }
    }
  }
  initializeApp() {
    window.onload = () => {
       const isInRange =
           window.innerWidth >= 1366 &&
           window.innerWidth <= 3000 &&
           window.innerHeight >= 768 &&
           window.innerHeight <= 1500;
     
         if (isInRange) {
           console.warn('Viewport size is within the specified range');
           this.commonService.myPlatform = 'desktop';
         }else{
          this.commonService.myPlatform = this.platform.platforms()[0];
          if (this.commonService.myPlatform == 'tablet') {
            this.commonService.myPlatform = 'desktop';
          }
         }
     
     };
    this.platform.ready().then(() => {
      window.addEventListener('offline', () => {
        //Do task when no internet connection
        this.commonService.networkChecker()
      });
      window.addEventListener('online', () => {
        this.commonService.alertController.dismiss();
      });
      this.platform.pause.subscribe(e => {
        this.closeWebsocket();
      });
      window.addEventListener('beforeunload', () => {
        this.closeWebsocket();
      });
      let seperateLogin: boolean = false;
      this.commonService.companyInfo
        .subscribe(
          res => {
            // this.appUpdate();
            console.table(res);
            if (res["logo"] == "hideLogo") {

            }
            if (window.location.origin == "https://gps.primeresources.com")
              this.app["logo"] = "assets/loginLogo/PrimeResourcesCompanyblacklogo.png";
            else
              this.app["logo"] = res["logo"];
            this.app["company"] = res["companyId"];
            let LH = window.location.origin.includes("localhost")
            if (window.location.origin.includes("$tracking$") || document.location.href.includes("$tracking$")) {
              sessionStorage.setItem("seprateLogin", 'true');
              this.menuController.enable(false);
              let x: any = document.location.href.split("/$tracking$/")[1];
              this.url(x);

              seperateLogin = true;
              console.log("this.fullURLData>>>", this.fullURLData, "<<<<this.fullURLData");

            }
            else if (window.location.origin == "https://track.remon.in") {
              app.entryPoint = res == "" ? "TTS" : res['entryPoint'];
              app.appName = "Remoncloud";
              serverUrl.Admin = "https://track.remon.in/Admin";
              serverUrl.web = "https://track.remon.in/fleettracking";
              serverUrl.adminSocket = "wss://track.remon.in";
              serverUrl.websocket = "wss://track.remon.in";
              app.loginImgUrl = 'assets/loginLogo/remoncloud.png';
              this.title.setTitle("REMON TRACKING");
              this.document.getElementById('appFavicon').setAttribute('href', 'assets/icon/remon.png');
              app.bgImage = "https://kingstrackimages.s3.amazonaws.com/loginimages/track_remon_in_background.jpg"
            } else if (window.location.origin == "http://lnt-track.sanjhusecuresystems.in/") {
              app.appName = "LNT";
              serverUrl.Admin = "http://lnt-track.sanjhusecuresystems.in/Admin";
              serverUrl.web = "http://lnt-track.sanjhusecuresystems.in//fleettracking";
              serverUrl.adminSocket = "ws://http://lnt-track.sanjhusecuresystems.in";
              serverUrl.websocket = "ws://lnt-track.sanjhusecuresystems.in";
              app.loginImgUrl = 'assets/loginLogo/remoncloud.png';
              this.title.setTitle("LNT TRACKING");
              this.document.getElementById('appFavicon').setAttribute('href', '');
              app.bgImage = "https://kingstrackimages.s3.amazonaws.com/loginimages/track_remon_in_background.jpg"
            } else if (window.location.origin == "http://122.165.187.106:8090") {
              app.entryPoint = app.entryPoint == "unknown" ? "unknown" : app.entryPoint;
              this.title.setTitle("MVT Tracking");
              this.document.getElementById('appFavicon').setAttribute('href', 'assets/icon/mvt.png');
              serverUrl.Admin = "http://122.165.187.106:8090/Admin";
              serverUrl.web = "http://122.165.187.106:8090/fleettracking";
              serverUrl.adminSocket = "ws://122.165.187.106";
              serverUrl.websocket = "ws://122.165.187.106";
            } else if (window.location.origin == "http://192.168.1.13:8082") {
              app.entryPoint = app.entryPoint == "unknown" ? "unknown" : app.entryPoint;
              this.title.setTitle("MVT Tracking");
              this.document.getElementById('appFavicon').setAttribute('href', 'assets/icon/mvt.png');
              serverUrl.Admin = "http://192.168.1.13:8082/Admin";
              serverUrl.web = "http://192.168.1.13:8082/fleettracking";
              serverUrl.adminSocket = "ws://192.168.1.13";
              serverUrl.websocket = "ws://192.168.1.13";
            } else if (window.location.origin == "http://192.168.1.10:8081") {
              app.entryPoint = app.entryPoint == "unknown" ? "unknown" : app.entryPoint;
              this.title.setTitle("MVT Tracking");
              this.document.getElementById('appFavicon').setAttribute('href', 'assets/icon/mvt.png');
              serverUrl.Admin = "http://192.168.1.10:8081/Admin";
              serverUrl.web = "http://192.168.1.13:8080/fleettracking";
              serverUrl.adminSocket = "ws://192.168.1.13:8081";
              serverUrl.websocket = "ws://192.168.1.13:8081";
            } else if (window.location.origin == "https://mvtqa.thegoldenelement.com") {
              app.entryPoint = app.entryPoint == "unknown" ? "unknown" : app.entryPoint;
              this.title.setTitle("Asset Tracking");
              this.document.getElementById('appFavicon').setAttribute('href', 'assets/icon/ge.png');
              serverUrl.Admin = "https://mvtqa.thegoldenelement.com/Admin";
              serverUrl.web = "https://mvtqa.thegoldenelement.com/fleettracking";
              serverUrl.adminSocket = "wss://mvtqa.thegoldenelement.com";
              serverUrl.websocket = "wss://mvtqa.thegoldenelement.com";
              app.appName = "GE"
              app.loginImgUrl = 'assets/loginLogo/Bank_albilad.png';
              app.bgImage = 'https://kingstrackimages.s3.amazonaws.com/loginimages/track_thegoldenelement_com_background.jpg';
              app.bgImage = "assets/backgroungImage/atmbg2.jpg"
            } else if (window.location.origin == "https://track.thegoldenelement.com") {
              app.entryPoint = app.entryPoint == "unknown" ? "unknown" : app.entryPoint;
              this.title.setTitle("Asset Tracking");
              this.document.getElementById('appFavicon').setAttribute('href', 'assets/icon/ge.png');
              serverUrl.Admin = "https://track.thegoldenelement.com/Admin";
              serverUrl.web = "https://track.thegoldenelement.com/fleettracking";
              serverUrl.adminSocket = "wss://spring.thegoldenelement.com"
              serverUrl.websocket = "wss://spring.thegoldenelement.com"
              app.appName = "GE"
              app.loginImgUrl = 'assets/loginLogo/ge.png';
              app.bgImage = 'https://kingstrackimages.s3.amazonaws.com/loginimages/track_thegoldenelement_com_background.jpg';
            }
            else if (window.location.origin == "https://tabukwfts.thegoldenelement.com") {
              app.entryPoint = app.entryPoint == "unknown" ? "unknown" : app.entryPoint;
              this.title.setTitle("Asset Tracking");
              this.document.getElementById('appFavicon').setAttribute('href', 'assets/icon/ge.png');
              serverUrl.Admin = "https://tabukwfts.thegoldenelement.com/Admin";
              serverUrl.web = "https://tabukwfts.thegoldenelement.com/fleettracking";
              serverUrl.adminSocket = "wss://tabukwfts.thegoldenelement.com";
              serverUrl.websocket = "wss://tabukwfts.thegoldenelement.com";
              app.appName = "GE"
              app.loginImgUrl = 'assets/loginLogo/ge.png';
              app.bgImage = "assets/backgroungImage/WFT.jpg"
            }
            else if (window.location.origin == "https://arabiantracking.net" ) {
              app.entryPoint = app.entryPoint == "unknown" ? "unknown" : app.entryPoint;
              this.title.setTitle("Arabian Tracking");
              this.document.getElementById('appFavicon').setAttribute('href', '');
              serverUrl.Admin = "https://arabiantracking.net/Admin";
              serverUrl.web = "https://arabiantracking.net/fleettracking";
              serverUrl.adminSocket = "wss://arabiantracking.net";
              serverUrl.websocket = "wss://arabiantracking.net";
              app.appName = "GE"
              app.loginImgUrl = 'assets/loginLogo/arabiandynamics.png';
              app.bgImage = 'https://kingstrackimages.s3.ap-southeast-1.amazonaws.com/loginimages/GE-Background-2030.jpg'
            } else if (window.location.origin == "https://riyadwft.thegoldenelement.com" || window.location.origin == "http://riyadwft.thegoldenelement.com") {
              app.entryPoint = app.entryPoint == "unknown" ? "unknown" : app.entryPoint;
              this.title.setTitle("Asset Tracking");
              this.document.getElementById('appFavicon').setAttribute('href', 'assets/icon/ge.png');
              serverUrl.Admin = "https://mvtqa.thegoldenelement.com/Admin";
              serverUrl.web = "https://mvtqa.thegoldenelement.com/fleettracking";
              serverUrl.adminSocket = "wss://mvtqa.thegoldenelement.com";
              serverUrl.websocket = "wss://mvtqa.thegoldenelement.com";
              app.appName = "GE"
              app.loginImgUrl = 'assets/loginLogo/ge.png';
              app.bgImage = "assets/backgroungImage/riyadWFT.jpg"
            }
            else if (window.location.origin == "https://albilad.thegoldenelement.com"  || window.location.origin == "http://albilad.thegoldenelement.com"  ) {
              this.commonService.loginLogo = false;
              app.entryPoint = app.entryPoint == "unknown" ? "unknown" : app.entryPoint;
              this.title.setTitle("Asset Tracking");
              this.document.getElementById('appFavicon').setAttribute('href', 'assets/icon/ge.png');
              serverUrl.Admin = "https://albilad.thegoldenelement.com/Admin";
              serverUrl.web = "https://albilad.thegoldenelement.com/fleettracking";
              serverUrl.adminSocket = "wss://albilad.thegoldenelement.com";
              serverUrl.websocket = "wss://albilad.thegoldenelement.com";
              app.appName = "GE"
              // app.loginImgUrl = 'assets/loginLogo/Bank_albilad.png';
              app.bgImage = "assets/backgroungImage/albilad_bg_image.jpeg"
            }else if (window.location.origin.includes("95.177.183.45")   ) { //for atm postgres test
              this.commonService.loginLogo = false;
              app.entryPoint = app.entryPoint == "unknown" ? "unknown" : app.entryPoint;
              this.title.setTitle("Asset Tracking");
              this.document.getElementById('appFavicon').setAttribute('href', 'assets/icon/ge.png');
              serverUrl.web = "http://95.177.179.99:8085/fleettracking";
              serverUrl.Admin = "http://95.177.179.99:8081/Admin";
              serverUrl.websocket = "wss://95.177.179.99:8085";
              serverUrl.adminSocket = "ws://95.177.179.99:8081";
              app.appName = "GE"
              // app.loginImgUrl = 'assets/loginLogo/Bank_albilad.png';
            } else if ((window.location.origin == "https://qa-one.thegoldenelement.com"   ||  LH   ) && !(seperateLogin)) {
              serverUrl.web = "https://" + "qa-one.thegoldenelement.com" + "/fleettracking"; //hi
              serverUrl.Admin = "https://" + "qa-one.thegoldenelement.com" + "/Admin";
              serverUrl.websocket = "wss://" + "qa-spring.thegoldenelement.com";
              serverUrl.adminSocket = "wss://" + "qa-spring.thegoldenelement.com";
              app.appName = "GE"
              app.loginImgUrl = 'assets/loginLogo/ge.png';
              app.bgImage = 'https://kingstrackimages.s3.ap-southeast-1.amazonaws.com/loginimages/GE-Background-2030.jpg';
            }
            else if (((window.location.origin == "http://95.177.179.99") ) && !(seperateLogin)) {
              serverUrl.web = "http://" + "95.177.179.99:8085" + "/fleettracking"; //hi
              serverUrl.Admin = "http://" + "95.177.179.99:8081" + "/Admin";
              serverUrl.websocket = "ws://" + "95.177.179.99:8085";
              serverUrl.adminSocket = "ws://" + "95.177.179.99:8081";
              app.appName = "GE"
              app.loginImgUrl = 'assets/loginLogo/ge.png';
              app.bgImage = "assets/backgroungImage/albilad_bg_image.jpeg"
             }
            // umar new added start  
            else if (window.location.origin == "https://mvt.thegoldenelement.com"     ) {
              serverUrl.web = "https://" + "mvt.thegoldenelement.com" + "/fleettracking";
              serverUrl.Admin = "https://" + "mvt.thegoldenelement.com" + "/Admin";
              serverUrl.websocket = "wss://" + "spring.thegoldenelement.com";
              serverUrl.adminSocket = "wss://" + "spring.thegoldenelement.com";
              app.appName = "GE"
              app.loginImgUrl = 'assets/loginLogo/ge.png';
              app.bgImage = 'https://kingstrackimages.s3.ap-southeast-1.amazonaws.com/loginimages/GE-Background-2030.jpg';
            } else if (window.location.origin == "https://gps.primeresources.com" ) {
              serverUrl.web = "https://" + "gps.primeresources.com" + "/fleettracking";
              serverUrl.Admin = "https://" + "gps.primeresources.com" + "/Admin";
              serverUrl.websocket = "wss://" + "spring.thegoldenelement.com";
              serverUrl.adminSocket = "wss://" + "spring.thegoldenelement.com";
              app.appName = "GE"
              app.loginImgUrl = 'assets/loginLogo/PrimeResourcesCompanyblacklogo.png';
              app.bgImage = 'assets/loginLogo/primeresourceslogin.png';
            } else if (window.location.origin == "https://huawei.thegoldenelement.com"   ) {
              serverUrl.web = "https://" + "mvt.thegoldenelement.com" + "/fleettracking";
              serverUrl.Admin = "https://" + "mvt.thegoldenelement.com" + "/Admin";
              serverUrl.websocket = "wss://" + "spring.thegoldenelement.com";
              serverUrl.adminSocket = "wss://" + "spring.thegoldenelement.com";
              app.appName = "GE"
              app.loginImgUrl = 'assets/loginLogo/ge.png';
              app.bgImage = 'assets/loginLogo/FuelMonitroingLoginPage.jpg';
            } else if (window.location.origin == "https://mrac.thegoldenelement.com" ) {
              serverUrl.web = "https://" + "mrac.thegoldenelement.com" + "/fleettracking";
              serverUrl.Admin = "https://" + "mrac.thegoldenelement.com" + "/Admin";
              serverUrl.websocket = "wss://" + "rac-spring.thegoldenelement.com";
              serverUrl.adminSocket = "wss://" + "rac-spring.thegoldenelement.com";
              app.appName = "RAC"
              app.loginImgUrl = 'assets/loginLogo/RALogo.png';
              app.bgImage = 'https://rac.thegoldenelement.com/img/RAC-LoginPage.jpg';
            }else if (window.location.origin == "https://fms.riyadhairports.com" ) {
              serverUrl.web = "https://" + "fms.riyadhairports.com" + "/fleettracking";
              serverUrl.Admin = "https://" + "fms.riyadhairports.com" + "/Admin";
              serverUrl.websocket = "wss://" + "fms.riyadhairports.com";
              serverUrl.adminSocket = "wss://" + "fms.riyadhairports.com";
              app.appName = "RAC"
              // app.loginImgUrl = 'assets/loginLogo/RALogo.png';
              app.bgImage = 'assets/backgroungImage/airport-LoginPage.jpeg';
            } 
            
            else if (window.location.origin == "https://peaxmvt.thegoldenelement.com") {
              serverUrl.web = "https://" + "peaxmvt.thegoldenelement.com" + "/fleettracking";
              serverUrl.Admin = "https://" + "peaxmvt.thegoldenelement.com" + "/Admin";
              serverUrl.websocket = "wss://" + "peaxmvt.thegoldenelement.com";
              serverUrl.adminSocket = "wss://" + "peax-spring.thegoldenelement.com";
              app.appName = "peax"
              app.loginImgUrl = 'assets/loginLogo/peax.png';
              app.bgImage = 'https://kingstrackimages.s3.amazonaws.com/loginimages/peax_thegoldenelement_com_background.jpg';
            } else if (window.location.origin == "https://cloud.remon.in") {
              this.title.setTitle("IoT, AI & ML Solutions");
              this.document.getElementById('appFavicon').setAttribute('href', 'assets/icon/elint.ico');
              serverUrl.web = "https://" + "cloud.remon.in" + "/fleettracking";
              serverUrl.Admin = "https://" + "cloud.remon.in" + "/Admin";
              serverUrl.websocket = "wss://" + "cloud.remon.in";
              serverUrl.adminSocket = "wss://" + "spring.thegoldenelement.com";
              app.appName = "GE"
              app.loginImgUrl = 'assets/loginLogo/elint-cloud-logo.png';
              app.bgImage = 'https://kingstrackimages.s3.amazonaws.com/loginimages/remon_cloud_background.png';
            } else if (window.location.origin == "https://gps.remon.in") {
              this.title.setTitle("IoT, AI & ML Solutions");
              this.document.getElementById('appFavicon').setAttribute('href', 'assets/icon/elint.ico');
              serverUrl.web = "https://" + "gps.remon.in" + "/fleettracking";
              serverUrl.Admin = "https://" + "gps.remon.in" + "/Admin";
              serverUrl.websocket = "wss://" + "gps.remon.in";
              serverUrl.adminSocket = "wss://" + "spring.thegoldenelement.com";
              app.appName = "GE"
              app.loginImgUrl = 'assets/loginLogo/elint-cloud-logo.png';
              app.bgImage = 'https://kingstrackimages.s3.amazonaws.com/loginimages/remon_cloud_background.png';
            } else if (window.location.origin == "https://gps.msoftech.com") {
              this.title.setTitle("Asset Tracking");
              app.bgImage = 'https://kingstrackimages.s3.amazonaws.com/loginimages/remon_cloud_background.png';
              app.loginImgUrl = 'assets/loginLogo/MarkSoftTech.png';
              serverUrl.web = "https://" + "gps.msoftech.com" + "/fleettracking";
              serverUrl.Admin = "https://" + "gps.msoftech.com" + "/Admin";
              serverUrl.websocket = "wss://" + "gps.msoftech.com";
              serverUrl.adminSocket = "wss://" + "spring.thegoldenelement.com";
              app.appName = "GE";
            } else if (  (window.location.origin.includes("192.168.0.112"))) {
              serverUrl.web = "http://" + "192.168.0.121:8080" + "/fleettracking";
              serverUrl.Admin = "http://" + "192.168.0.121:8081" + "/Admin";
              serverUrl.websocket = "ws://" + "192.168.0.121:8081";
              serverUrl.adminSocket = "ws://" + "192.168.0.121:8081";
              app.appName = "GE"
              app.loginImgUrl = 'assets/loginLogo/ge.png';
              app.bgImage = 'https://kingstrackimages.s3.ap-southeast-1.amazonaws.com/loginimages/GE-Background-2030.jpg';
            }
            // umar new added end
            // else {
            //   if (this.platform.platforms()[0] == "desktop" || this.platform.platforms()[0] == "tablet") {
            //     // app.entryPoint = "VTS";
            //     this.title.setTitle("Asset Tracking");
            //     this.document.getElementById('appFavicon').setAttribute('href', 'assets/icon/mvt.png');
            //   }
            // }

            if (this.commonService.myPlatform == "desktop" || this.commonService.myPlatform == "tablet" && seperateLogin == false) { //
              setTimeout(() => {
                if (localStorage.userName) {
                  this.menuObject = []
                  // this.menuObject.push("Multi Track")
                  let mainMenuCheck = []
                  if (localStorage.loginData) {
                    this.isDealer = false
                    let mainMenu = JSON.parse(JSON.parse(localStorage.getItem('loginData'))[1].mainmenu);
                    mainMenuCheck = mainMenu;
                    

                    // this.menuObject = ["Dashboard", "Grid view", "Map view", "Geo fence", "Reports", "Alerts", "Settings", "Log out"];
                    for (var i = 0; i < Object.keys(this.appIcon).length; i++) {
                      if (mainMenu.indexOf(Object.keys(this.appIcon)[i]) != -1)
                        this.menuObject.push(Object.keys(this.appIcon)[i])
                    }
                  } else if (localStorage.mainMenu) {
                    this.isDealer = true
                    let mainMenu = JSON.parse(localStorage.getItem('mainMenu'));


                    // this.menuObject = ["Dashboard", "Grid view", "Map view", "Geo fence", "Reports", "Alerts", "Settings", "Log out"];
                    for (var i = 0; i < Object.keys(this.appIcon).length; i++) {
                      if (mainMenu.indexOf(Object.keys(this.appIcon)[i]) != -1)
                        this.menuObject.push(Object.keys(this.appIcon)[i])
                    }
                  }
                  // this.menuObject.push("Maintanence");
                  const url = serverUrl.web + "/user/branch/InitialPageuser";
                  const data = { 'userId': localStorage.userName, 'companyId': localStorage.corpId }
                  this.ajaxService.ajaxPostWithString(url, data)
                    .subscribe(res => {
                      localStorage.inItPage = res;
                      if (this.isDealer) {
                        this.selectedMenu = "Home"
                      } else {
                        if (res == 'dashboard' || res == undefined || res == null) {
                          this.selectedMenu = 'Dashboard';
                        } else if (res == 'gridView') {
                          this.selectedMenu = 'Grid view';
                        } else {
                          this.selectedMenu = res;
                        }
                        if (mainMenuCheck.includes("Device Debug")) {
                          this.selectedMenu = 'Device Debug';
                          this.router.navigateByUrl('/device');
                        }
                      }
                      this.commonService.dismissLoader();
                    });
                }
              }, 2000);
            } else if (seperateLogin == false) { //
              setTimeout(() => {
                this.menuObject = []
                if (localStorage.loginData) {
                  let mainMenu = JSON.parse(JSON.parse(localStorage.getItem('loginData'))[1].mainmenu);
                  for (var i = 0; i < Object.keys(this.appIcon).length; i++) {
                    if (mainMenu.indexOf(Object.keys(this.appIcon)[i]) != -1)
                      this.menuObject.push(Object.keys(this.appIcon)[i])
                  }
                } else if (localStorage.mainMenu) {
                  let mainMenu = JSON.parse(localStorage.getItem('mainMenu'));
                  for (var i = 0; i < Object.keys(this.appIcon).length; i++) {
                    if (mainMenu.indexOf(Object.keys(this.appIcon)[i]) != -1)
                      this.menuObject.push(Object.keys(this.appIcon)[i])
                  }
                }
              }, 2000);
            }

            if (seperateLogin == false) {
              if (res != "") {
                if (Notification.permission != "granted")
                  Notification.requestPermission((result) => {
                    if (result === 'granted') {
                      this.notificationPushServices()
                      if (Notification.permission === "granted") {
                        this.notificationPushServices()
                      } else if (Notification.permission !== "denied") {
                        this.commonService.presentToast("Your are denied the notification, So the alert notification now came");
                      }
                    }
                  });
                else
                  this.notificationPushServices()
              }
            }
          }
        );
      if (localStorage.companyLogo) {
        this.app["logo"] = localStorage.companyLogo;
      }
      if (localStorage.corpId) {
        this.app["company"] = localStorage.corpId;
        this.titleCard = localStorage.corpId
      }
      this.statusBar.styleLightContent();
      this.splashScreen.hide();

      if (seperateLogin == false) {
        this.authenticationService.authenticationState.subscribe(state => {

          if (state == true && sessionStorage.login == 'true') {
            if ((localStorage.inItPage == 'dashboard' || localStorage.inItPage == undefined) && app.appName != 'Armoron') {
              this.router.navigateByUrl('tabs/members/dashboard');
              this.selectedMenu = 'Dashboard';
            } else if (localStorage.inItPage == 'gridView' || app.appName == 'Armoron') {
              this.router.navigateByUrl('tabs/gridview/All');
              this.selectedMenu = 'Grid view';
            }

          } else if (state == false && (sessionStorage.login == 'false' || sessionStorage.login == undefined || sessionStorage.login == 'undefined') && (localStorage.login == 'false' || localStorage.login == undefined || localStorage.login == 'undefined')) {
            if (!this.logOut && localStorage.getItem('corpId') != null) {
              this.ajaxService.fleetloginTime();
            }

            this.router.navigate(['tabs-login/members/login'], { replaceUrl: true, skipLocationChange: true });
            if ((localStorage.inItPage == 'dashboard' || localStorage.inItPage == undefined) && app.appName != 'Armoron') {
              this.selectedMenu = 'Dashboard';
            } else if (localStorage.inItPage == 'gridView' || app.appName == 'Armoron') {
              this.selectedMenu = 'Grid view';
            }
          } else {
            if ((localStorage.inItPage == 'dashboard' || localStorage.inItPage == undefined) && app.appName != 'Armoron') {
              this.selectedMenu = 'Dashboard';
              this.router.navigateByUrl('tabs/members/dashboard');
            } else if (localStorage.inItPage == 'gridView' || app.appName == 'Armoron') {
              this.selectedMenu = 'Grid view';
              this.router.navigateByUrl('tabs/gridview/All');
            }
            setTimeout(() => {
              this.loadingRefresh = true;
            }, 10000)

          }
        });
      }
    });

    
 
    
    console.warn("!!! MyPlatform  ( ( (",this.commonService.myPlatform, ") ) ) !!!")
    this.titleCard = localStorage.corpId
  }

  async logOutFunction(router) {
    localStorage.removeItem('modalFilterData')
    localStorage.setItem("pageSelector", router);
    let logout = this.trans.instant("Are you sure you want to logout from user login")
    if (router == "Log out" || router == "Log-out") {
      const alert = await this.alertController.create({
        header: this.trans.instant('Log out'),
        backdropDismiss: false,
        message: logout.replace("user", localStorage.getItem('corpId')),
        buttons: [{
          text: this.trans.instant('Cancel'),
          role: 'cancel',
          handler: data => {
          }
        },
        {
          text: this.trans.instant('Ok'),
          handler: data => {
            storageVariable.upDatedJsonData = undefined;
            storageVariable.dashboardData = undefined
            this.logOut = true;
            this.commonService.vehicle1 = false;
            this.commonService.ticketOpener = true;
            this.commonService.filterbutton = true
            this.ajaxService.fleetloginTime();
            if (localStorage.getItem('pushStatus') == "persisted") {
              let pushDetails = {
                "imeiNo": localStorage.getItem("imeiNo"),
                "appName": app.appName, // "Arabian Dynamics",    
                "deviceToken": localStorage.getItem("deviceToken"),
                "companyID": localStorage.getItem("corpId"),
                "userId": localStorage.getItem("userName"),
                "os": "ionic4",
                "pushStatus": "false"
              };
              const url = serverUrl.web + "/alert/pushnotification";
              this.ajaxService.ajaxPostWithBody(url, pushDetails)
                .subscribe(res => {
                  console.log('push presisted');
                  // this.restartApp();
                })
            }
            if (this.websocketService.isAlive('livetrack')) {
              this.websocketService.disConnectSocket('livetrack');
            }
            this.menuController.enable(false);
            localStorage.clear();
            sessionStorage.clear();
            sessionStorage.setItem('login', 'false')
            localStorage.setItem('login', 'false')
            // this.router.navigateByUrl('login');
            this.authenticationService.logout();
            localStorage.clear();
            sessionStorage.clear();
            companyReportData.reportData = undefined;
            companyReportData.recurrenceData = undefined;
            storageVariable.upDatedJsonData = undefined;
            storageVariable.dashboardData = undefined;

            // document.location.href = 'index.html';
            // if(app.appName == 'ParentApp'){
            //   document.location.href = 'index.html';
            // }


          }
        }]
      });

      await alert.present();
    } else {
      this.selectedMenu = router;
      if (router == 'Map view') {
        localStorage.setItem('statusChanger', "All")
      }
      this.router.navigateByUrl(this.appUrl[router])
    }
  }

  menuSelection() {

    if (this.appSideMenu == "listMenu") {
      console.log('i am iconMenu')
      this.appSideMenu = "iconMenu";
    }
    else {
      console.log('i am listMenu')
      this.appSideMenu = "listMenu";

    }
  }

  notificationPushServices() {
    const interData = setInterval(() => {
      if (localStorage.userName && localStorage.corpId) {
        let date = new Date();
        let data = {
          "address": "false",
          "vin": "All",
          "companyId": localStorage.corpId,
          "userId": localStorage.userName,
          "fromDate": date.toJSON().split("T")[0] + " 00:00:00",
          "toDate": date.toJSON().split("T")[0] + " 23:59:59",
        };
        if (date.toJSON().split("T")[0], date.toJSON().split("T")[0]) {
          let url = serverUrl.web + "/alert/mobileAlert";
          this.ajaxService.ajaxPostWithBody(url, data)
            .subscribe(res => {
              let alert = res.length == 0 || this.alertCount == res.length ? false : true;
              if (alert) {
                let showDesktop = res.length - this.alertCount;
                if (this.alertCount != 0)
                  for (let i = 0; i < showDesktop; i++)
                    this.commonService.desktopNotification(res[i]);
                this.alertCount = res.length;
              }
            });
        }
      }
      else {
        clearInterval(interData)
      }
    }, 30000)
  }

  setMenuOver(status) {
    this.menuOver = status;
  }
}


