import { Component, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { languageInitializer } from 'src/environments/environment';

@Component({
  selector: 'app-generator-alarm',
  templateUrl: './generator-alarm.component.html',
  styleUrls: ['./generator-alarm.component.scss'],
})
export class GeneratorAlarmComponent implements OnInit {

  source: { localdata: any; };
  dataAdapter: any;
  renderer: (row: number, column: any, value: string) => string;
  columns: ({ text: string; datafield: string; cellsrenderer: (row: number, column: any, value: string) => string; width: number } | { text: string; datafield: string; cellsrenderer?: undefined; width: number })[];
  tableData = [];
  @ViewChild('myGrid', { static: false }) myGrid: jqxGridComponent;
  directory = languageInitializer.directory

  constructor(
    private trans: TranslateService
  ) { }


  gridview() {
    this.source = { localdata: "" };
    this.dataAdapter = new jqx.dataAdapter(this.source);

    this.columns = [
      { text: this.trans.instant('ALARM'), datafield: 'alarm', cellsrenderer: this.renderer, width: 250 },
      { text: this.trans.instant('SEVERITY'), datafield: 'serverity', cellsrenderer: this.renderer, width: 150 },
      { text: this.trans.instant('TIME'), datafield: 'time', cellsrenderer: this.renderer, width: 143 },
      { text: this.trans.instant('AGING(HH:MM)'), datafield: 'aging', cellsrenderer: this.renderer, width: 150 }
    ]
  }

  ngOnInit() {
    this.gridview();
    this.tableData = [
      {
        'alarm': 'STABILIZER MANUAL',
        'serverity': 'MAJOR',
        'time': '31-03-2022 15:45',
        'aging': '00:00'
      },
      {
        'alarm': 'STABILIZER OFF',
        'serverity': 'MAJOR',
        'time': '31-03-2022 15:45',
        'aging': '00:00'
      },
      {
        'alarm': 'R I/P LOW',
        'serverity': 'MINOR',
        'time': '31-03-2022 15:45',
        'aging': '00:00'
      }
    ]
    this.source = { localdata: this.tableData };
    this.dataAdapter = new jqx.dataAdapter(this.source);

    this.columns = [
      { text: this.trans.instant('ALARM'), datafield: 'alarm', cellsrenderer: this.renderer, width: 250 },
      { text: this.trans.instant('SEVERITY'), datafield: 'serverity', cellsrenderer: this.renderer, width: 150 },
      { text: this.trans.instant('TIME'), datafield: 'time', cellsrenderer: this.renderer, width: 143 },
      { text: this.trans.instant('AGING(HH:MM)'), datafield: 'aging', cellsrenderer: this.renderer, width: 150 }
    ]
    //this.myGrid.updatebounddata();
    //this.myGrid.unselectrow;
  }

}
